var imagesLoaded = require('imagesloaded');
import $ from "jquery";
imagesLoaded.makeJQueryPlugin( $ );
import React, { Fragment, memo, useCallback, useMemo, useState, useRef, useEffect } from 'react';
import ReactDOM from 'react-dom';
import { withRouter } from 'react-router';
import styled from 'styled-components';
import { branch, compose, withProps } from 'recompose';
import classNames from 'classnames';
import Measure from 'react-measure';
import SplitPane from 'react-split-pane';
import update from 'immutability-helper';
import each from 'lodash/each';
import {
	Progress,
} from 'reactstrap';
import { cssUnitsInt, replaceEmptyArray } from '../../common/helpers';
import {
	chatReplyPanelMinimizedHeight,
	chatReplyTopPadding,
	errandReplyPadding,
	errandReplyTopPadding,
	screenMdMin
} from '../../styles/_variables';
import Assist, {
	AssistBarLabel
	, AssistBarItems
	, AssistBarOneItem
	, AssistColumn
	, KnowledgeBaseLabel
	, PreviewAssist
	, PreviewAssistHideText
	, ProtectAssist
	, SalutationAssist
	, SignatureAssist
	, TemplateAssist
	, SuggestionAssist
	, withIdAndTitle
	, QuickReplyAssist
} from '../../reactcomponents/Assist';
import {
	composeWithDisplayName,
	withUnmountWhenHidden
} from '../../reactcomponents/hocs';
import Anchor from '../../reactcomponents/Anchor';
import {
	MoreDisplayIDs,
	withAssociatedErrandItems
} from '../../reactcomponents/AssociatedErrandDisplayIDs';
import EllipsisText from '../../reactcomponents/EllipsisText';
import MessageGroup from '../../reactcomponents/MessageGroup';
import { ModalPopup } from '../../reactcomponents/Modal';
import OneReplyNav from '../../reactcomponents/OneReplyNav';
import QueueChat, { QueueChatBase } from '../../reactcomponents/QueueChat';
import Wrappable, { withWrapTag } from '../../reactcomponents/Wrappable';
import ToolbarButtonIcon from '../../reactcomponents/ToolbarButtonIcon';
import Ckeditor from '../../components/v5/Ckeditor';
import EnchancedMoment from '../../components/v5/EnchancedMoment';
import withSystemMessage from '../../components/v5/SystemMessageWrapper';
import {
	EditorFootnotes,
	FooterCheckboxes,
	ReplyButtons
} from '../../components/v5/Footers';
import { Message, PanelSingleMessage } from '../../components/v5/Message';
import { ToggleReplyBar, SocialMediaActions } from '../../components/v5/ReplyToolbar';
import WorkflowActions, {
	ForwardToAgentDropdown
	, IconAndChevron
	, IconMemberAndChevron
} from '../../components/v5/WorkflowActions';
import {
	AreaDropdown,
	DropdownWithFavourite,
	ActionDropdown
} from '../../reactcomponents/Dropdown';
import { HotkeyLabel } from '../../reactcomponents/Hotkeys';
import {
	AIAnswerRightSidepanel,
	CollaborationRightSidepanel,
	CollaborationTriggerButton,
	CRMIntegrationRightSidepanel,
	CRMIntegrationTriggerButton,
	InternalCommentTriggerButton,
	RightSidepanel,
	KBLibraryRightSidepanel,
	AgentAssistTriggerButton
} from '../../reactcomponents/Sidepanel';
import {
	ChatAreaDropdownCtnr,
	ChatAgentDropdownCtnr,
} from '../../containers/chat.js';
import { CollabContentsCtnr, CRMContentsCtnr } from '../../containers/collaborate';
import {
	WorkflowBreadcrumbsCtnr,
	WorkflowInnerContainerCtnr
} from './workflowCtnr';
import ClientSatisfactionMeter from '../../reactcomponents/ClientSatisfactionMeter';
import { CtrlKnowledgeBasePopup } from '../../components/v5/KnowledgeBasePopup';
import TagPicker from '../../components/v5/TagPicker';
import {
	ActionWithDisabled,
	BackToList,
	SwitchView,
	DropdownAction,
	HeaderActions,
	HeaderActionItem,
	HeaderActionItemIcons,
	HeaderActionSelectItem,
	Seperator,
	StandardAction,
	BackToSearch,
	HideableHeaderActions,
	ToggleMoreHeaderActions,
	StandardTooltip,
	ButtonIcon
} from '../../reactcomponents/HeaderActions';
import {
	withAcquireErrands,
	withAssociatedErrands
} from '../../containers/oce';
import { ReplyToolbarCtnr, withReplyToolbar } from '../../containers/toolbar';
import { ButtonsGroup } from '../../reactcomponents/Button';
import {
	messages,
	ContentBorder,
	ErrandHeaderCtnr,
	ErrandOpenedCtnr,
	ReplyFormCtnr,
	ReplyNavCtnr,
	ReplyHeaderCtnr,
	ErrandBoxHeaderCtnr,
	CountdownTimerCtnr,
	ScrollToBottomCtnr,
	InternalCommentContentsCtnr,
	LaunchpadCntr,
	RewriteAnswerBoxContentCtnr
} from './errandCtnrs';
import {
	ACQ_ERD_SELECTED_TOP,
	ACQ_ERD_SELECTED_BOTTOM,
	CTX_REVIEW,
	DEFAULT_CKEDITOR_HEIGHT,
	FWD_TO_AGENT_STR,
	AT_COL_ANS,
	AT_SAVED,
	OPT_CHANNELS,
	PF_HISTORY_CREATED,
	PF_DEFAULT,
	PF_TIMESTAMP,
	RC_EMAIL,
	RC_SMS,
	RC_TWITTER,
	RC_SLACK,
	RC_JIRA,
	RC_VOICE,
	RPLY_ERRAND,
	RPLY_QUESTION,
	RPLY_COMMENT,
	RPLY_COLLABORATE,
	RPLY_EXT_FWD,
	RPLY_GRP_CHAT_COL,
	RPLY_TAB_RECIPIENTS,
	RPLY_TAB_ASSIST,
	RPLY_TAB_ATTACHMENT,
	RPLY_TAB_NO_SELECT,
	ICST_UNKNOWN,
	ICST_NO_DATA,
	MT_NOTE,
	UNKNOWN_DATE,
	MESSAGE_INVALID_EMAIL,
	PREF_CONVERSATION_VIEW,
	CHAT_crInvited,
	COL_MASK_LIGHT_ON,
	COL_MASK_READ_ANSWER,
	COL_MASK_DELIVERY_ERR,
	ST_ACQUIRED,
	ST_ACQUIRING,
	ST_ANSWERED,
	ST_JUST_LOGIN,
	ST_UNSELECT,
	CHAT_crGuest,
	SEARCH_ERRANDS,
	emptyArray,
	emptyObject,
	CLOSE_BROWSER_MANUALLY,
	CLEARIT,
	PUZZEL,
	VIEW_REPLY_PANEL,
	ERRAND_HOTKEYS,
	TOGGLE_REPLY,
	TOGGLE_QUESTION,
	TOGGLE_COMMENT,
	TOGGLE_COLLAB,
	TOGGLE_FRWD_XTRNL,
	TOGGLE_IN_CHAT_COL,
	TWITTERTWEETLIMIT,
	RECIPIENT_TO,
	RECIPIENT_CC,
	RECIPIENT_BCC,
	RECIPIENT_FORWARD,
	RECIPIENT_INTERNAL_COLLABORATE,
	MEDIA_ACTION_URL as MEDIA_ACTION,
	MT_COLLABORATION,
	MY_ERRANDS,
	MT_ERRAND,
	SHOW_MORE_TAGS_WIDTH,
	USED_FOR_WHATSAPP_TEMPLATE,
	RC_WHATSAPP,
	RC_FACEBOOK,
	CKEDITOR_DARK_BG,
	CKEDITOR_DARK_TXT_COLOR,
	CKEDITOR_DARK_BG_COLLAB,
	CKEDITOR_DARK_TXT_COLLAB,
	CKEDITOR_DARK_BG_COMMENT,
	CKEDITOR_DARK_TXT_COMMENT,
	CKEDITOR_BG_COLLAB,
	CKEDITOR_BG_COMMENT,
	CHAT_CKEDITOR_ID,
	RPLY_CHAT_SUMMARY,
	TOGGLE_CHAT_SUMMARY
} from '../../common/v5/constants';
import {
	SIP_CALL_CONNECTED
	, SIP_OUTGOING_CALL_CONNECTED
	, SIP_CALL_ON_HOLD
} from '../../common/v5/callConstants';
import {
	TXT_ERRAND_NOT_REVIEW
	, TXT_ERRAND_PENDING_REVIEW
} from '../../common/v5/reviewConstants';
import { I, pathPrefix } from '../../common/v5/config.js';
import {
	chatHasExternalCollaboration,
	closeStatusString,
	errandHotkeyChar,
	getChevronIcon,
	isChatAndCollaborateInternalChat,
	isChatAndCollaboration,
	isGroupChatCollaborate,
	isValidEmail,
	isExternalOpen,
	notChatOrChatInCollborate,
	sanitizeHtml,
	getEmailOutParentheses,
	stripHTML,
	removeHTMLTags,
	removePM,
	getAppendAnswer,
	getExtQueueType,
	langFromAutoClassify,
	getHotkeyChar,
	getNotificationIcon,
} from '../../common/v5/helpers';
import { addNewPopupNotification } from "../../redux/actions/hmf.js";
import { useDispatch } from "react-redux";
import { triggerCustomNotification } from "../../redux/actions/websocket.js";
import {
	ChatErrandCtnr,
	ChatInputCtnr,
	AddAgentCtnr,
	ChatOptionsCtnr,
} from '../../containers/chat';
import {
	withAnsweredStates,
	withLinkedErrandsStates,
	withDisplayIdAndChannelId,
	withTotalQueueChats,
	withErrandMessages
} from '../../containers/hocs';
import {
	ActionModalPrintCtnr
} from '../../containers/print';
import { sendMessage } from '../../common/v5/chat';
import AcquireErrands from '../../components/v5/AcquireErrands';
import {
	ChatAttachment
} from '../../components/v5/ChatInput';
import ChatToolbar from '../../components/v5/ChatToolbar';
import 'lightbox2/dist/css/lightbox.min.css';
import {
	ChuckNorrisFacts
} from '../../components/v5/ChuckNorrisFacts';
import { IsContextSearch } from '../../common/v5/utils';
import withHistoriesOrOthers, {
	HistoryDataInProgress,
	RepliedNote,
	LinkedErrandsNote,
	withCurrentAndAddMoreOthers
} from '../../reactcomponents/HistoriesAndOthers';
import { OneTab, OneTabWithTooltip } from '../../components/v5/ManualErrand';
import Spellchecker from '../../components/v5/Spellchecker';
import ErrandTranslation from '../../components/v5/ErrandTranslation';
import { Spinner } from '../../reactcomponents/Spinner';
import { startScreenShare, stopScreenShare, initCoBrowse } from '../../common/v5/webRtcCall';
import ErrandQuestion, {
	Answer as ErrandAnswer,
	ReadOnlyQuestion,
	ReadOnlyAnswer
} from '../../components/v5/QuestionAnswer';
import { HideableReplySearch } from '../../components/v5/ReplySearch';
import { CtrlRewriteAnswerPopup } from '../../components/v5/rewriteAnswerBase';
import { ContactCardModalCntr } from './errandCtnrs';
import KnowledgeBaseCtnr from './knowledgebase/knowledgeBaseCtnr';
import { KBErrandsModal } from './knowledgebase/knowledgeBase';

const closeTxt = I('Close');
const keyAreas = {key: "Areas"}
	, idName = {id: "Id", name: "Name"}
	, alwaysHideCloseViewButton = true
	;
const OpenExternalSystemAction = props => <StandardAction {...props} data-qa-id="openExternalSystem" text={I("Open external system")} icon="icon-open-ext" />;

const PostponeDoneDateAction = props => <StandardAction {...props} data-qa-id="postoponeErrand" text={I("Postpone errand")} icon="icon-calendar-plus" />;

const PinToTopAction = props => <StandardAction {...props} data-qa-id="pinToTop" text={I("Pin to top")} icon="icon-pin" />;

const LockToMeAction = props => <StandardAction {...props} data-qa-id="lockToMe" text={I("Lock to me")} icon="icon-lock" />;

const PriorityAction = props => <StandardAction {...props} data-qa-id="setPriority" icon="icon-caution" />;

const DeleteAction = ({...props}) => {
	let text = <HotkeyLabel id={"delErrandAction"} text={I("Delete errand")} hotkey={"D"} />;
	return <StandardAction {...props} data-qa-id="deleteErrand" text={text} icon="icon-trash" />;
};

const CloseAction = ({...props}) => {
	let text = <HotkeyLabel id={"closeErrandAction"} text={I("Close errand")} hotkey={"O"} />;
	return <StandardAction {...props} data-qa-id="closeErrand" text={text} icon="icon-times" />;
};

const CloseCreateAction = ({...props}) => {
	let text = <HotkeyLabel id={"closeCreateAction"} text={I("Close and create new errand")} hotkey={"O"} />;
	return <StandardAction {...props} data-qa-id="closeCreateErrand" text={text} icon="icon-duplicate" />;
};

const CloseWithSummaryAction = ({...props}) => {
	let text = <HotkeyLabel id={"closeErrandWithSummaryAction"} text={I("Close with summary")} hotkey={""} />;
	return <StandardAction {...props} data-qa-id="closeErrandWithSummary" text={text} icon="icon-comment" />;
}

const ReturnAction = ({...props}) => {
	let text = <HotkeyLabel id={"returnErrandAction"} text={I("Return errand")} hotkey={"I"} />;
	return <StandardAction {...props} data-qa-id="returnErrand" text={text} icon="icon-return" />;
};

const QueueToMeAction = props => <StandardAction {...props} data-qa-id="queueToMe" text={I("Queue to me")} icon="icon-return" />;

const CloseViewAction = props => <StandardAction {...props} data-qa-id="closeErrandView" icon="fa fa-times" overflow={12} />;

const ReopenAction = props => <StandardAction {...props} data-qa-id="reopenErrand" className="blue" text={I("Re-open errand")} icon="icon-arrow-up" />;

const IconRightAndChevron = ({ up }) => <IconAndChevron iconClassName="icon-arrow-right" up={up} />;

const SaveAsEmlAction = props => <ActionWithDisabled data-qa-id="save-as-eml" icon="icon-download" text={I("Save as eml")} {...props} />;

const AcquireFromOtherAgentErrand = props => <StandardAction {...props} data-qa-id="acquireErrand" text={I("Acquire errand")} icon="fas fa-plus" />;

const TotalQueueChat = withTotalQueueChats(QueueChat);

const HeaderActionItemContainerBase = ({ items }) => <div className="actions-container">{items}</div>

const HeaderActionItemContainer = withUnmountWhenHidden(HeaderActionItemContainerBase)

class errandHeader extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			moreNavActions: false,
			alreadyOpenExternal: false,
			moreHeaderActions: false,
			videoCallActive: false,
			coBrowseRequesting: false,
		};
		this.handleCloseView = this.handleCloseView.bind(this);
		this.handleSaveAsEml = this.handleSaveAsEml.bind(this);
		this.toggleMoreActions = this.toggleMoreActions.bind(this);
		this.toggleHeaderActions = this.toggleHeaderActions.bind(this);
		this.handleClickOutside = this.handleClickOutside.bind(this);
		this.handleTogglePostponeErrand = this.handleTogglePostponeErrand.bind(this);
		this.handleSideBarToggle = this.handleSideBarToggle.bind(this);
		this.handleSideBarCollapse = this.handleSideBarCollapse.bind(this);
		this.handleCloseChatWithSummary = this.handleCloseChatWithSummary.bind(this);
	}
	componentDidUpdate(prevProps, prevState) {
		if(prevProps.errandId !== this.props.errandId){
			this.setState({alreadyOpenExternal: false});
		}
	}
	handleCloseView() {
		this.props.onCloseView(this.props.errandId);
	}
	handleSaveAsEml() {
		this.props.onSaveAsEml(this.props.currentReply);
	}
	expandErrandMobileHeader = () => {
		const m = this.props.mobile;
		if(m) {
			this.props.onToggleMobileErrandHeader(!m.viewErrandHeader);
		}
	}
	toggleMoreActions(){
		this.setState({moreNavActions: !this.state.moreNavActions});
	}
	toggleHeaderActions(){
		this.setState({moreHeaderActions: !this.state.moreHeaderActions});
	}
	handleClickOutside() {
		if(this.setState.moreHeaderActions) {
			this.setState({moreHeaderActions: false});
		}
	}
	handleTogglePostponeErrand() {
		this.props.showPostponeErrand(true);
	}
	handleLockToMe = (e) =>{
		this.props.handleLockToMe(this.errandId(), this.props.cipherKey, !this.props.update_lock);
	}
	handlePriority = (e) =>{
		this.props.handleErrandPriority(this.errandId(), this.props.cipherKey, !this.props.update_priority);
	}
	handleActionToggling = (id, action) =>{
		this.props.handleActionShowForwardTo(id, !action);
	}
	onSelectFolder = (fid) =>{
		this.props.handleMoveToFolder(this.errandId(), fid);
	}
	onSelectArea = (aid) =>{
		this.props.handleForwardToArea(this.errandId(), aid);
	}
	onSelectAgent = (aid) =>{
		this.props.handleForwardToAgent(this.errandId(), aid);
	}
	handleStartCamera = () => {
		const p = this.props;
		if(p.onAgentStartWebCam){
			p.onAgentStartWebCam(p.chat.sessionId, p.chat.fromId, p.chat.agentId)
			this.setState({videoCallActive: true});
		}
	}
	handleStopCamera = () => {
		const p = this.props;
		if(p.onAgentStopWebCam){
			p.onAgentStopWebCam(p.chat.sessionId, p.chat.fromId, p.chat.agentId)
			this.setState({videoCallActive: false});
		}
	}
	handleScreenSharing = () => {
		startScreenShare(this.props.chat.sessionId);
	}
	handleStopScreenSharing = () => {
		stopScreenShare(this.props.chat.sessionId, false);
	}
	handleCoBrowsing = () => {
		initCoBrowse(this.props.chat.sessionId);
		this.setState({coBrowseRequesting: true});
	}
	handlePrint = () =>{
		this.props.handleActionPrint(true, this.errandId(), true);
	}
	handlePutBackToInbox = () => {
		this.props.handleReturnBackToInbox(this.errandId());
		if (IsContextSearch(this.props.currentContext)) {
			this.props.history.push(`${pathPrefix}v5/search`);
		}
	}
	errandId =()=>{
		return this.props.errandId;
	}
	handleDeleteErrand = () =>{
		this.props.deleteErrand(this.errandId());
	}
	handleCloseErrand = () =>{
		this.props.closeErrand(this.errandId());
	}
	handleCloseCreateErrand = () =>{
		this.props.closeErrand(this.errandId(), true);
	}
	handleCloseChatWithSummary = () =>{
		this.props.handleCloseChatWithSummary();
	}
	handleReopen = () => {
		let cipherKey = this.props.cipherKey;
		let channel;
		const {errand, chat, data} = this.props;
		// Reopen expired chat, change the channel to email channel
		if (errand.isChat) {
			if(chat && chat.errand && chat.errand.data){
				cipherKey = chat.errand.data.cipherKey;
				channel = Workflow.Errand.SERVICE_EMAIL;
			}
		// On any closed errand, if it is on chat channel then change it to email channel
		} else if (data && data.my && data.my.errand &&
			data.my.errand.service == Workflow.Errand.SERVICE_CHAT) {
			channel = Workflow.Errand.SERVICE_EMAIL;
		}
		this.props.handleReopen(this.errandId(), cipherKey, channel);
	}
	handleQueueToMe = () => {
		var eList = [this.errandId()];
		this.props.handleQueueToMe(eList);
	}
	handleOpenExternal = ( extSys, email ) =>{
		let externalSystemUrls = extSys.systemUrl;
		for(let i = 0; i < externalSystemUrls.length; i++){
			let url = externalSystemUrls[i].replace('[EMAIL]', email);
			if( url.length > 0 ){
				if(url.toLowerCase().indexOf("http://") == -1 && url.toLowerCase().indexOf("https://")  == -1) {
					url = 'http://' + url
				}
				let external = (extSys.howOpenExSysByAgent == 0 ?
					window.open(url, '_blank' + i) :
					window.open(url, 'external-system' + i, 'scrollbars=yes,menubar=yes,toolbar=yes,width=1024,height=768'));
				if(external) { // some time browser block the popup which will cause null value on external
					external.focus();
				} else {
					console.log("can not create window - browser blocked popup?");
				}
			}
		}
	}
	getExternalSystem = (currentArea) =>{
		let hasExt = false, urls = [];
		if( currentArea != null ){
			if( currentArea.external_system_url_array != null
				&& currentArea.external_system_url_array.length > 0 ){
				hasExt = true;
				urls = currentArea.external_system_url_array;
			}
		}
		return {
			hasExSys: hasExt,
			systemUrl: urls
		}
	}
	handlePintop = () =>{
		this.props.handlePintopErrand(this.errandId(), this.props.cipherKey, !this.props.update_pintop);
	}
	handleAcquireFromOtherAgentErrand = () =>{
		const errandId = this.props.errandId
			, isAcquireFromOtherAgentErrand = true
			, isErrandView = true;
		this.props.handleAcquireFromOtherAgentErrand(
			errandId
			, isAcquireFromOtherAgentErrand
			, isErrandView
		)
	}
	handleSideBarToggle = (e) => {
		this.props.onToggleSideBar(!this.props.sideBarShown);
	}
	handleSideBarCollapse = (e) => {
		this.props.onCollapseSideBar(!this.props.collapsedSideBar);
	}
	handleFavouriteArea = (e, id) => {
		e.stopPropagation();
		this.props.onToggleForwardAreaFavourites(id)
	}
	render() {
		const {
			hidden,
			hideActionButtons,
			errand,
			errandActionWip,
			errandAreaData,
			errandId,
			currentSipErrandId,
			currentSipCallStatus,
			mobile,
			chatWindowActivated,
			notMine,
			fullView,
			wfSettings,
			forwardToArea,
			forwardToAgent,
			listType,
			saveAsEmlDisabled,
			showBackToList,
			showSaveAsEml,
			state,
			data, //current errand
			currentContext,
			mobileView,
			chat,
			acquireErrand,
			collapsedSideBar,
			agentWorking,
			totalQueueChats
		} = this.props
			;
		let openExternalConfig = {}
			, readOnly
			, ges = this.getExternalSystem(errandAreaData)
			, disableMainActions
			, disableForwardToAgent
			;
		openExternalConfig.hasExSys = ges.hasExSys;
		openExternalConfig.systemUrl = ges.systemUrl;
		openExternalConfig.howOpenExSysByAgent = wfSettings.howToOpenExternalSystemForAgent;
		let fromEmail = "", fromName="";
		let disabledBySip = false;
		if(currentSipErrandId != 0 && currentSipErrandId == errandId &&
			(currentSipCallStatus == SIP_CALL_CONNECTED ||
			currentSipCallStatus == SIP_OUTGOING_CALL_CONNECTED ||
				currentSipCallStatus == SIP_CALL_ON_HOLD)){
			disabledBySip = true;
		}

		let showCloseButton = true;
		if(data != null){
			fromEmail = data.my.errand.fromAddress;
			if(errand.isChat){
				if(chat && chat.errand){
					fromName = chat.errand.data.fromName;
				}
			} else {
				fromName = data.my.errand.fromName;
			}
			if(this.props.allowCloseWithoutAnswer == false){
				if(data.my.errand.service == Workflow.Errand.SERVICE_EMAIL||
					data.my.errand.service == Workflow.Errand.SERVICE_MANUAL){
					showCloseButton = false;
				}
			}
		}
		let isAcquireErrand = false;
		if(acquireErrand.data.acquire){
			isAcquireErrand = acquireErrand.data.acquire.acquired;
		}
		let customStyledAppHeader = { borderRadius: "initial"}

		if(!errand.isChat && state !== ST_ACQUIRED && state !== ST_ANSWERED) {
			if(state !== ST_ACQUIRING) {
				if(collapsedSideBar){
					return <div className="app-header with-breadcrumbs" style={customStyledAppHeader}><WorkflowBreadcrumbsCtnr /></div>;
				}
			}
			return <div className="app-header" style={customStyledAppHeader}>&nbsp;</div>;
		} else if(state === ST_ANSWERED) {
			readOnly = true;
		}
		let mobileClass = '';
		if(mobile && mobile.viewErrandHeader) {
			mobileClass = 'open';
		}
		let hideAppButton = false;
		if(errandViewOnly == true){
			hideAppButton = true;
		}
		let pintopCss = (this.props.update_pintop ? 'grey active-blue' : 'grey');
		let errandLockCss = (this.props.update_lock ? 'grey active-blue' : 'grey');
		let priorityCss = (this.props.update_priority ? 'grey active-red': 'grey');
		let featureTurnOffForwardErrandToArea = wfSettings['agent.turn-off-forward-to-area-from-inbox'];
		let featureAgentCanForwardOnlyInOrganization = wfSettings['agent.only-forward-to-area-in-organisation'];
		let featureCanDeleteErrands = wfSettings['agent-delete-errands.admin-edit'];
		let agentCanPutBackErrands = wfSettings['agentCanPutBackErrands'];
		let featureCanMoveErrandToFolder = wfSettings['folders.move-from-new-errands'];
		let featureAllowAgentVidCall = features["chat.agent-video-call"];
		let featureAllowScreenSharing = features["chat.agent-video-screen-sharing"];
		let featureAllowCoBrowsing = features["chat.agent-video-co-browse"];
		let ftaTempl = "";
		let ddShowArea = this.props.showForwardToArea;
		let postponeCss = "grey";
		let aquireErrandCss = "grey";
		if(data.my.errand && data.my.errand.postponedate > 0){
			postponeCss = "grey active-yellow";
		}
		const favouriteFTA = this.props.agentFavouriteForwardToArea;

		if (errand.isChat) {
			if (features["chat.forward-agent"]) {
					ftaTempl = (
						<ChatAreaDropdownCtnr
							id="forwardArea"
							enableArrow={false}
							title={<IconRightAndChevron up={ddShowArea} />}
							show={ddShowArea}
							isHeader={false}
							listTitle={I("Forward to area")}
							onToggle={this.handleActionToggling}
							withFavourite={true}
							favouriteList={this.props.agentFavouriteForwardToArea}
							onClickFavourite={this.handleFavouriteArea}
							onToggleFavAccordian={this.props.onToggleFavouriteFTADropdown}
							onToggleRegAccordian={this.props.onToggleFTADropdown}
							favAccordianOpen = {this.props.toggleForwardToAreaFavourites}
							regAccordianOpen = {this.props.toggleForwardToArea}
						/>
					);
			}
		} else if (!featureTurnOffForwardErrandToArea) {
			let nested;
			if (!featureAgentCanForwardOnlyInOrganization) {
				nested = keyAreas;
			}
				let favouriteFTADropdownList = [];

				if(favouriteFTA && favouriteFTA.length > 0) {
					//filter areas that has favourites
					favouriteFTADropdownList = forwardToArea
						.map(obj => ({
							...obj,
							Areas: obj.Areas ? obj.Areas.filter(area => favouriteFTA.includes(area.Id)) : []
						}))
						.filter(obj => obj.Areas.length > 0) // Filter out objects with empty Areas
				}
				ftaTempl = (
					<DropdownWithFavourite
						title={<i className="icon-arrow-right"/>}
						titleRegular={I("Forward to area")}
						titleFavourite={I("Favourites")}
						tooltip={(hide)=>
							<StandardTooltip hide={hide} text={I("Forward to area")} />
						}
						dataQAId={"forwardArea"}
						enableToggleArrow={true}
						onToggle={this.handleActionToggling}
						onToggleFavAccordian={this.props.onToggleFavouriteFTADropdown}
						onToggleRegAccordian={this.props.onToggleFTADropdown}
						favAccordianOpen = {this.props.toggleForwardToAreaFavourites}
						regAccordianOpen = {this.props.toggleForwardToArea}
						onSelect={this.onSelectArea}
						favouriteList={favouriteFTADropdownList}
						favouriteDD={(selectFavItemHandler) =>
							<AreaDropdown
								enableArrow={false}
								id="favForwardArea"
								show={true}
								data={favouriteFTADropdownList}
								isHeader={true}
								nested={nested}
								idFields={idName}
								onToggle={()=>{}}
								onSelect={selectFavItemHandler}
								disabled={disabledBySip}
								withFavourite={true}
								favouriteList={this.props.agentFavouriteForwardToArea}
								onClickFavourite={this.handleFavouriteArea}
							/>
						}
						// regularDD={
						regularDD={(selectItemHandler) =>
							<AreaDropdown
								enableArrow={false}
								id="forwardArea"
								show={true}
								data={forwardToArea}
								isHeader={true}
								nested={nested}
								idFields={idName}
								onToggle={()=>{}}
								onSelect={selectItemHandler}
								disabled={disabledBySip}
								withFavourite={true}
								favouriteList={this.props.agentFavouriteForwardToArea}
								onClickFavourite={this.handleFavouriteArea}
							/>
						}
					/>
				)
		}
		let hideBackToList = false
			, isClosed = false
			, enableForwardToAgent = false
			, canCloseCreate = false
			, k = 0
			, navButtons = []
			, pendingReview
			, viewControlButtons = []
			, videoActions = []
			, statusActions = [], dropdownActions = [], errandActions = [];
			;
		if (!isExternalOpen() && (!showBackToList || hideAppButton)){
			hideBackToList = true;
		}
		if (mobile) { //<show BackToList Arrow btn for device < 767px in conversation view
			if(listType == PREF_CONVERSATION_VIEW){
				hideBackToList = false;
			}
		}
		if (data) {
			if (data.my.errand.closed) {
				isClosed = true;
			}
			if (data.my.errand.pendingReview) {
				pendingReview = true;
				isClosed = true;
				enableForwardToAgent = true
			}
			//only for voice errand in open status and  xxx-1 errands
			//feature must be enabled
			if (typeof wfSettings['enable-close-create-button']
				!== "undefined" &&
				wfSettings['enable-close-create-button'] == true &&
				data.my.errand.service == Workflow.Errand.SERVICE_VOICE &&
				isClosed == false &&
				data.my.errand.displayId.endsWith("-1")) {
				canCloseCreate = true;
			}
		}
		disableMainActions = isClosed || errandActionWip || agentWorking ||
			disabledBySip;
		disableForwardToAgent = (isClosed || errandActionWip || agentWorking || disabledBySip) && !enableForwardToAgent;
		if (!hideActionButtons){
			if (currentContext != SEARCH_ERRANDS) {
				viewControlButtons.push(
					<BackToList
						key={k++}
						className="pull-left transparent"
						hide={hideBackToList}
						onClick={this.props.onBackToListView}
					/>
				);
			} else {
				viewControlButtons.push(
					<BackToSearch
						key={k++}
						className="pull-left transparent"
						hide={hideBackToList}
						linkTo={`${pathPrefix}v5/search`}
					/>
				);
			}
			if (!mobile) {
				viewControlButtons.push(
					<SwitchView
						key={k++}
						className="pull-left transparent"
						hide={fullView ? false : true}
						onClick={this.props.onSwitchToListView}
					/>
				)
			}
			if (mobile) {
				viewControlButtons.push(
					<ToggleMoreHeaderActions
						key={k++}
						className="pull-right transparent"
						hide={hideBackToList}
						onClick={this.toggleHeaderActions}
					/>
				);
			}
		}

		const hide = readOnly || hideAppButton, hideOrNotMine = hide || notMine;
		let isExternal = (typeof externalqueue !== "undefined" &&
			externalqueue.isExternal == true);

		let showVideoCallOpt = false;
		if(this.props.isVideoCallClientReady && this.props.chat && !this.props.chat.dead) {
			showVideoCallOpt = true;
		}
		if (featureAllowAgentVidCall && showVideoCallOpt) {
			let startVidCallAction = <StandardAction
					key={k++}
					id="doVideoCall"
					icon="icon-video-call"
					text={I("Video call")}
					onClick={this.handleStartCamera}
					hide={this.props.isVideoCallInProgress ? true : false}
					className={(this.props.isVideoCallInProgress || this.props.isOnVideoCall)? "disabled" : ""}
				/>
			let stopVidCallAction = <StandardAction
					key={k++}
					id="stopVideoCall"
					icon="icon-video-call-disable"
					text={I("Stop Video Call")}
					onClick={this.handleStopCamera}
					hide={this.props.isVideoCallInProgress ? false : true}
					className={"active"}
				/>
			videoActions.push(startVidCallAction);
			videoActions.push(stopVidCallAction);
		}
		if(showVideoCallOpt) {
			let screenShareAction = <StandardAction
					key={k++}
					id="screenShareAction"
					icon="icon-sharescreen"
					text={I("Start Screen sharing")}
					onClick={this.handleScreenSharing}
					className={this.props.isAgentScreenSharing ? "disabled" : ""}
					hide={featureAllowScreenSharing && !this.props.isAgentScreenSharing ? false : true}
				/>
			let stopScreenShareAction = <StandardAction
					key={k++}
					id="screenShareAction"
					icon="icon-sharescreen"
					text={I("Stop Screen Sharing")}
					onClick={this.handleStopScreenSharing}
					className={"active"}
					hide={this.props.isAgentScreenSharing ? false : true}
				/>
			let coBrowseAction = <StandardAction
					key={k++}
					id="coBrowseAction"
					icon="icon-cobrowse"
					text={I("Request Co-browsing")}
					onClick={this.handleCoBrowsing}
					className={classNames(
						{disabled: (this.props.isOnCoBrowseMode) },
						//{progress: this.state.coBrowseRequesting}, //is requesting
						{"red": this.props.isOnCoBrowseMode}
						)
					}
					hide={featureAllowCoBrowsing ? false : true}
				/>
			videoActions.push(screenShareAction);
			videoActions.push(stopScreenShareAction);
			videoActions.push(coBrowseAction);
		}
		//Group for status actions
		if (errand.isChat) {
			navButtons.push(<TotalQueueChat key={k++} />);
			if(totalQueueChats){
				statusActions.push(<QueueChatBase data={totalQueueChats} key={k++} />);
			}
		} else {
			wfSettings.postponeDoneDate && navButtons.push(
				<PostponeDoneDateAction
					key={k++}
					className={postponeCss}
					disabled={disableMainActions}
					onClick={this.handleTogglePostponeErrand}
					hide={readOnly}
				/>
			) && statusActions.push(
				<PostponeDoneDateAction
					key={k++}
					className={postponeCss}
					disabled={disableMainActions}
					onClick={this.handleTogglePostponeErrand}
					hide={readOnly}
				/>
			);
			if(isExternal == false){
				navButtons.push(
					<PinToTopAction
						key={k++}
						className={pintopCss}
						disabled={disableMainActions}
						onClick={this.handlePintop}
						hide={hideOrNotMine && readOnly}
					/>
				)
				statusActions.push(
					<PinToTopAction
						key={k++}
						className={pintopCss}
						disabled={disableMainActions}
						onClick={this.handlePintop}
						hide={hideOrNotMine && readOnly}
					/>
				);
			}
			wfSettings.lockToMe && navButtons.push(
				<LockToMeAction
					key={k++}
					className={errandLockCss}
					disabled={disableMainActions}
					onClick={this.handleLockToMe}
					hide={readOnly}
				/>
			) && statusActions.push(
				<LockToMeAction
					key={k++}
					className={errandLockCss}
					disabled={disableMainActions}
					onClick={this.handleLockToMe}
					hide={readOnly}
				/>
			);
			wfSettings.highPriority && navButtons.push(
				<PriorityAction
					key={k++}
					className={priorityCss}
					disabled={disableMainActions}
					onClick={this.handlePriority}
					text={this.props.update_priority ?
						I("Set to normal priority") :
						I("Set to high priority")}
					hide={hideOrNotMine}
				/>
			) && statusActions.push(
				<PriorityAction
					key={k++}
					className={priorityCss}
					disabled={disableMainActions}
					onClick={this.handlePriority}
					text={this.props.update_priority ?
						I("Set to normal priority") :
						I("Set to high priority")}
					hide={hideOrNotMine}
				/>
			);
			if(wfSettings['group-leader.personal-errands'] && currentContext == MY_ERRANDS && data.my.errand.agentId != wfSettings.activeUserId){
				navButtons.push(
					<AcquireFromOtherAgentErrand
						key={k++}
						className={aquireErrandCss}
						disabled={disableMainActions || isAcquireErrand}
						onClick={this.handleAcquireFromOtherAgentErrand}
						hide={hideOrNotMine && readOnly}
					/>
				);
				statusActions.push(
					<AcquireFromOtherAgentErrand
						key={k++}
						className={aquireErrandCss}
						disabled={disableMainActions || isAcquireErrand}
						onClick={this.handleAcquireFromOtherAgentErrand}
						hide={hideOrNotMine && readOnly}
					/>
				);
			}
			navButtons.push(<Seperator key={k++} hide={readOnly} />);
			//Group for dropdown actions
			featureCanMoveErrandToFolder && navButtons.push(
				<DropdownAction
					key={k++}
					className="grey"
					disabled={disableMainActions}
					text={I("Move to folder")}
					noTooltip={!!this.props.showMoveToFolder}
					hide={readOnly}
				>
					<ActionDropdown id="moveFolder"
						enableArrow
						show={this.props.showMoveToFolder}
						isHeader={true}
						headerText={I("Move to folder")}
						data={wfSettings.userFolders}
						onToggle={this.handleActionToggling}
						onSelectItem={this.onSelectFolder}
					>
						<i className="icon-folder" />
						<i className={getChevronIcon(this.props.showMoveToFolder)} />
					</ActionDropdown>
				</DropdownAction>
			) && dropdownActions.push(
				<DropdownAction
					key={k++}
					className="grey"
					disabled={disableMainActions}
					text={I("Move to folder")}
					noTooltip={!!this.props.showMoveToFolder}
					hide={readOnly}
				>
					<ActionDropdown id="moveFolder"
						enableArrow
						show={this.props.showMoveToFolder}
						isHeader={true}
						headerText={I("Move to folder")}
						data={wfSettings.userFolders}
						onToggle={this.handleActionToggling}
						onSelectItem={this.onSelectFolder}
					>
						<i className="icon-folder" />
						<i className={getChevronIcon(this.props.showMoveToFolder)} />
					</ActionDropdown>
				</DropdownAction>
			);
		}
		if (!this.props.chat || !this.props.chat.dead) {
			if (ftaTempl &&
				(!cflag.IsActive("2024-01-31.CEN-2169.rework.postponed.errand") || !this.props.isPostponed)) {
				navButtons.push(
					<DropdownAction
						key={k++}
						className="grey"
						text={I("Forward to area")}
						noTooltip={!!ddShowArea}
						hide={readOnly}
						disabled={this.props.isVideoCallInProgress|| disableMainActions}
					>
						{ftaTempl}
					</DropdownAction>
				);
				dropdownActions.push(
					<DropdownAction
						key={k++}
						className="grey"
						text={I("Forward to area")}
						noTooltip={true}
						hide={readOnly}
						disabled={this.props.isVideoCallInProgress || disableMainActions}
					>
						{ftaTempl}
					</DropdownAction>
				);
			}
			let dd;
			if (this.props.chat) {
				if (features["chat.forward-agent"]) {
					dd = <ChatAgentDropdownCtnr
						enableArrow
						id="forwardAgent"
						title={<IconMemberAndChevron up={this.props.showForwardToAgent} />}
						show={this.props.showForwardToAgent}
						isHeader={true}
						headerText={L(FWD_TO_AGENT_STR)}
						onToggle={this.handleActionToggling}
					/>;
				}
			} else {
				dd = (
					<ForwardToAgentDropdown
						allowAgentForwardToAgent={wfSettings.agentForwardToAgent}
						data={forwardToAgent}
						enableArrow
						iconOnly={true}
						id="forwardAgent"
						show={this.props.showForwardToAgent}
						onSelectItem={this.onSelectAgent}
						onToggle={this.handleActionToggling}
						disabled={disableForwardToAgent}
					/>
				);
			}
			if (dd) {
				navButtons.push(
					<DropdownAction
						key={k++}
						className="grey"
						text={L(FWD_TO_AGENT_STR)}
						noTooltip={!!this.props.showForwardToAgent}
						hide={readOnly}
						disabled={this.props.isVideoCallInProgress || disableForwardToAgent}
					>
						{dd}
					</DropdownAction>
				) && dropdownActions.push(
					<DropdownAction
						key={k++}
						className="grey"
						text={L(FWD_TO_AGENT_STR)}
						noTooltip={!!this.props.showForwardToAgent}
						hide={readOnly}
						disabled={this.props.isVideoCallInProgress || disableForwardToAgent}
					>
						{dd}
					</DropdownAction>
				);
			}
			navButtons.push(<Seperator key={k++} hide={readOnly} />);
		}
		//Group for errand action
		if(wfSettings['open-external-system']){
			if(openExternalConfig.hasExSys && openExternalConfig.systemUrl.length > 0){
				navButtons.push(
					<OpenExternalSystem
						key={"exSystem"+k++}
						hide={readOnly}
						handleOpenExternal={this.handleOpenExternal}
						disabled={disableMainActions}
						externalConfig={openExternalConfig}
						fromEmail={fromEmail}
					/>
				)
				errandActions.push(
					<OpenExternalSystem
						key={"exSystem"+k++}
						hide={readOnly}
						handleOpenExternal={this.handleOpenExternal}
						disabled={disableMainActions}
						externalConfig={openExternalConfig}
						fromEmail={fromEmail}
					/>
				);
			}
		}
		if (!this.props.chat ||(this.props.chat && this.props.chat.dead)) {
			navButtons.push(
				<StandardAction
					key={k++}
					id="printAnErrand"
					icon="icon-printer"
					text={I("Print errand")}
					onClick={this.handlePrint}
					hide={readOnly}
				/>
			);
			errandActions.push(
				<StandardAction
					key={k++}
					id="printAnErrand"
					icon="icon-printer"
					text={I("Print errand")}
					onClick={this.handlePrint}
					hide={readOnly}
				/>
			);
		}
		if (showSaveAsEml) {
			navButtons.push(
				<SaveAsEmlAction
					key={k++}
					disabled={saveAsEmlDisabled}
					onClick={this.handleSaveAsEml}
				/>
			);
			errandActions.push(
				<SaveAsEmlAction
					key={k++}
					disabled={saveAsEmlDisabled}
					onClick={this.handleSaveAsEml}
				/>
			);
		}
		if (!errand.isChat && featureCanDeleteErrands) {
			navButtons.push(
				<DeleteAction
					key={k++}
					onClick={this.handleDeleteErrand}
					disabled={disableMainActions}
					hide={readOnly}
				/>
			);
			errandActions.push(
				<DeleteAction
					key={k++}
					onClick={this.handleDeleteErrand}
					disabled={disableMainActions}
					hide={readOnly}
				/>
			);
		}
		if(showCloseButton == true){
			navButtons.push(
				<CloseAction
					key={k++}
					onClick={this.handleCloseErrand}
					disabled={disableMainActions || this.props.isVideoCallInProgress || this.props.isVideoRecording}
					hide={readOnly}
				/>
			);
			errandActions.push(
				<CloseAction
					key={k++}
					onClick={this.handleCloseErrand}
					disabled={disableMainActions || this.props.isVideoCallInProgress || this.props.isVideoRecording}
					hide={readOnly}
				/>
			);
		}
		if (canCloseCreate) {
			errandActions.push(
				<CloseCreateAction
					key={k++}
					onClick={this.handleCloseCreateErrand}
					disabled={disableMainActions}
					hide={readOnly}
				/>
			);
		}
		let showCloseWithSummary = false;
		if(this.props.chat && this.props.chat.summary == ""){
			showCloseWithSummary = true;
		}

		if (cflag.IsActive("2024-04-19.CEN-92.chat-summary-for-history") && showCloseWithSummary) {
			navButtons.push(
				<CloseWithSummaryAction
					key={k++}
					className={"errand-action-closed-summary"}
					onClick={this.handleCloseChatWithSummary}
					disabled={disableMainActions || this.props.isVideoCallInProgress || this.props.isVideoRecording}
					hide={readOnly}
				/>
			);
			errandActions.push(
				<CloseWithSummaryAction
					key={k++}
					className={"errand-action-closed-summary"}
					onClick={this.handleCloseChatWithSummary}
					disabled={disableMainActions || this.props.isVideoCallInProgress || this.props.isVideoRecording}
					hide={readOnly}
				/>
			);
		}
		if(typeof externalqueue !== "undefined" &&
			externalqueue.isExternal == false &&
			externalqueue.solidus == true){
			navButtons.push(
				<QueueToMeAction
					key={k++}
					onClick={this.handleQueueToMe}
					disabled={disableMainActions}
					hide={hide}
				/>
			);
			errandActions.push(
				<QueueToMeAction
					key={k++}
					onClick={this.handleQueueToMe}
					disabled={disableMainActions}
					hide={hide}
				/>
			);
		}
		!errand.isChat && agentCanPutBackErrands &&
			navButtons.push(
				<ReturnAction
					key={k++}
					onClick={this.handlePutBackToInbox}
					className="red"
					disabled={disableMainActions}
					hide={readOnly}
				/>
			) && errandActions.push(
				<ReturnAction
					key={k++}
					onClick={this.handlePutBackToInbox}
					className="red"
					disabled={disableMainActions}
					hide={readOnly}
				/>
			);
		if (!pendingReview
			&& (isClosed || (errand.isChat && chat && chat.expired))) {
			navButtons.push(
				<ReopenAction
					key={k++}
					onClick={this.handleReopen}
				/>
			);
			errandActions.push(
				<ReopenAction
					key={k++}
					onClick={this.handleReopen}
				/>
			);
		}
		const notFullOrErrandOnly = !alwaysHideCloseViewButton && (!fullView || errandViewOnly);
		if(notFullOrErrandOnly && ((!errand.isChat && !chatWindowActivated) || errand.isChat)){
			navButtons.push(
				<CloseViewAction
					key={k++}
					onClick={this.handleCloseView}
					text={closeTxt}
				/>
			);
			errandActions.push(
				<CloseViewAction
					key={k++}
					onClick={this.handleCloseView}
					text={closeTxt}
				/>
			);
		}
		const { moreNavActions, moreHeaderActions } = this.state;
		if(chatWindowActivated && !chatWindowActivated && !errand.isChat) {
			navButtons.push(
				<HeaderActionItem
					key={k++}
					id="seeOtherNavButtons"
					className="nav-more"
					hide={hide}
				>
					<HeaderActionItemIcons
						onClick={this.toggleMoreActions}
						title={I("More actions")}
					>
						<i
							className={classNames(
								getChevronIcon(moreNavActions)
								, "big"
							)}
						/>
					</HeaderActionItemIcons>
					<HeaderActions
						className={classNames(
							"dropdown-menu"
							, {show: moreNavActions}
							, "overflow")
						}
						hide={!moreNavActions}
					>
						<HeaderActionSelectItem
							id="returnErrand"
							className="red"
							disabled={disableMainActions}
							textClass="red"
							text={I("Return errand")}
							icon="icon-return"
							onClick={this.handlePutBackToInbox}
							hide={hide}
						/>
						<HeaderActionSelectItem
							id="closeErrandView"
							text={closeTxt}
							icon="fa fa-times"
							onClick={this.handleCloseView}
							hide={!notFullOrErrandOnly}
						/>
					</HeaderActions>
				</HeaderActionItem>
			);
			errandActions.push(
				<HeaderActionItem
					key={k++}
					id="seeOtherNavButtons"
					className="nav-more"
					hide={hide}
				>
					<HeaderActionItemIcons
						onClick={this.toggleMoreActions}
						title={I("More actions")}
					>
						<i
							className={classNames(
								getChevronIcon(moreNavActions)
								, "big"
							)}
						/>
					</HeaderActionItemIcons>
					<HeaderActions
						className={classNames(
							"dropdown-menu"
							, {show: moreNavActions}
							, "overflow")
						}
						hide={!moreNavActions}
					>
						<HeaderActionSelectItem
							id="returnErrand"
							className="red"
							disabled={disableMainActions}
							textClass="red"
							text={I("Return errand")}
							icon="icon-return"
							onClick={this.handlePutBackToInbox}
							hide={hide}
						/>
						<HeaderActionSelectItem
							id="closeErrandView"
							text={closeTxt}
							icon="fa fa-times"
							onClick={this.handleCloseView}
							hide={!notFullOrErrandOnly}
						/>
					</HeaderActions>
				</HeaderActionItem>
			);
		}
		if(hideBackToList == true && getExtQueueType() == PUZZEL){
			customStyledAppHeader["justifyContent"] = 'flex-end';
		}
		return (
			<div className={"app-header"}
				hidden={hidden} style={customStyledAppHeader}>
				<HeaderActions hide={hideBackToList}>
					{viewControlButtons}
				</HeaderActions>
				<div
					id="mobile-errand-nav"
					onClick={this.expandErrandMobileHeader}
					hidden={!mobileView}
				>
					<h3 className={mobileClass}>{fromName}</h3>
				</div>
				{
					collapsedSideBar &&
					<div className="breadcrumb-wrapper-left">
						<WorkflowBreadcrumbsCtnr />
					</div>
				}
				<HideableHeaderActions
					onClickOutside={this.handleClickOutside}
					outsideClickIgnoreClass="ignore-header-actions-onclickoutside"
					className={classNames(
						"v5 errand-nav"
						, {"more-open": moreNavActions}
						, {"mobile": mobile}
						, {"show-true": moreHeaderActions}
					)}
					hide={hideActionButtons}
					childd={navButtons}
				>
					<SocialMediaActions dropdownMode={false} {...this.props}/>
					<HeaderActionItemContainer hidden={!videoActions.length} items={videoActions}/>
					<HeaderActionItemContainer hidden={!statusActions.length} items={statusActions}/>
					<HeaderActionItemContainer hidden={!dropdownActions.length} items={dropdownActions}/>
					<HeaderActionItemContainer hidden={!errandActions.length} items={errandActions}/>
				</HideableHeaderActions>
			</div>
		);
	}
}

export const ErrandHeader = composeWithDisplayName(
	'ErrandHeader',
	withRouter,
	withReplyToolbar,
)(errandHeader)

class OpenExternalSystem extends React.PureComponent {
	handleClick = () => {
		let p = this.props;
		p.handleOpenExternal(p.externalConfig, p.fromEmail);
	}
	render() {
		const {
				className
				, externalConfig
				, fromEmail
				, handleOpenExternal
				, hide
				, ...props
			} = this.props
			;
		return (
			<OpenExternalSystemAction
				onClick={this.handleClick}
				className={className}
				hide={hide}
				{...props}
			/>
		);
	}
}

const attachmentPanelHideDelay = 1500;

class ExternalForwarded extends React.PureComponent {
	constructor(props) {
		super(props);
		this.state = {show: false};
		this.handleShow = this.handleShow.bind(this);
	}
	handleShow() {
		this.setState({show: !this.state.show});
	}
	render() {
		let myClass = 'msg errand-message-group forward my';
		if(this.state.show) {
			myClass += ' collapsed';
		}
		return <MessageGroup myClass={myClass} title={I('Forward To External')}
				ready={true} show={this.state.show}
				onToggleShow={this.handleShow}>
				<ErrandAnswer {...this.props} />
			</MessageGroup>;
	}
}

export const ICWrapper = props => <MessageGroup title={I('INTERNAL COMMENT')} {...props} />;

export class MessageInternalComment extends React.PureComponent {
	constructor(props) {
		super(props);
		this.handleToggleShow = this.handleToggleShow.bind(this);
		this.handleActionClick = this.handleActionClick.bind(this);
	}
	handleToggleShow() {
		const p = this.props;
		p.onToggleShow(p.eid, !p.show);
	}
	handleActionClick(action, note, errand) {
		const p = this.props;
		switch(action) {
			case 'edit':
				p.onEditNoteClick(note, errand);
				break;
			case 'delete':
				p.onDeleteNoteClick(note, errand);
				break;
		}
	}
	render() {
		const p = this.props;
		if(p.state === ICST_NO_DATA) {
			return null;
		}
		if(p.filterPopup.allMessages || p.filterPopup.byInternalMessages){
			const isAgent = p.isAgent;
			let commentClass = 'msg errand-message-group internal my';
			if(!isAgent) {
				commentClass = 'msg errand-message-group internal';
			}
			if(p.state === ICST_UNKNOWN) {
				return <ICWrapper myClass={commentClass} innerRef={p.innerRef} />;
			}
			if(p.show) {
				commentClass += ' collapsed';
			}
			let m = [];
			$.each(p.noteList, (i,v) => {
				m.push(<Message notErrand={true} key={'note'+p.eid+v.id} eid={p.eid}
					me={v.id} type={MT_NOTE}
					photo={v.avatar} name={v.username} status={I('Written')} time={v.writtenDate}
					showSize={0} isAgent={isAgent}
					edit={this.props.lockEditErrandNote?false:true}
					delete={this.props.lockEditErrandNote?false:true}
					html={v.note}
					attachmentType={AT_SAVED} attachments={p.attachments[v.id]}
					onActionClick={this.handleActionClick}
					showExactDayAndTime={p.showExactDayAndTime} />);
			});
			return <ICWrapper myClass={commentClass} ready={true} show={p.show}
				onToggleShow={this.handleToggleShow} innerRef={p.innerRef}>
					{m}
				</ICWrapper>;
		}else{
			return null;
		}
	}
}

export class InternalCommentContents extends React.PureComponent {
	constructor(props) {
		super(props);
		this.handleActionClick = this.handleActionClick.bind(this);
	}

	handleActionClick(action, note, errand) {
		const p = this.props;
		switch(action) {
			case 'edit':
				p.onEditNoteClick(note, errand);
				break;
			case 'delete':
				p.onDeleteNoteClick(note, errand);
				break;
		}
	}
	render() {
		const p = this.props;
		if(p.state === ICST_NO_DATA) {
			return null;
		}
		if(p.filterPopup.allMessages || p.filterPopup.byInternalMessages){
			if(p.state === ICST_UNKNOWN) {
				return 	<Spinner/>;
			}
			let m = [];
			$.each(p.noteList, (i,v) => {
				m.push(<PanelSingleMessage
					key={'note'+p.eid+v.id}
					eid={p.eid}
					me={v.id}
					notErrand={true}
					type={MT_NOTE}
					photo={v.avatar}
					name={v.username}
					time={v.writtenDate}
					showSize={0}
					isAgent={p.isAgent}
					currentEditNote={p.currentEditNote}
					edit={this.props.lockEditErrandNote?false:true}
					delete={this.props.lockEditErrandNote?false:true}
					html={v.note}
					lockEditErrandNote={p.lockEditErrandNote}
					onEditNoteClick={p.onEditNoteClick}
					onDeleteNoteClick={p.onDeleteNoteClick}
					attachmentType={AT_SAVED}
					attachments={p.attachments[v.id]}
					onActionClick={this.handleActionClick}
					/>
				);
			});

			return (
				<div className="ic-container">{m}</div>
			);
		}else{
			return null;
		}
	}
}

export class CountdownTimer extends React.Component {
	constructor(props) {
		super(props);
		this.timerId = null;
		this.startTimer= this.startTimer.bind(this);
		this.stopTimer= this.stopTimer.bind(this);
		this.countDown= this.countDown.bind(this);
		this.closeErrand= this.closeErrand.bind(this);
		this.handleMouseEnter= this.handleMouseEnter.bind(this);
		this.handleMouseLeave= this.handleMouseLeave.bind(this);
		this.state = {
			secondsRemaining:0
			, timeText: ""
		};
	}
	componentWillUnmount() {
		this.stopTimer();
	}
	startTimer() {
		if(!this.timerId){
			this.timerId = setInterval(this.countDown, 1000);
		}
	}
	stopTimer() {
		clearInterval(this.timerId);
	}
	countDown(){
		const p = this.props
		let timeRemaining = (p.e.basic.data.data.endTimer*1000) -
			(new Date()).getTime();
		let secondsRemaining = Math.ceil(timeRemaining/1000);
		if(secondsRemaining <= 0){
			this.stopTimer();
			this.closeErrand();
		}
		this.setState({secondsRemaining: secondsRemaining})
	}
	closeErrand(){
		const e = this.props.e;
		if(e.basic.data != null && e.basic.data.data != null){
			this.props.handleAutoClose(e.basic.data.id);
		}
	}
	handleMouseEnter() {
		let timerString = I("This errand will close in {MIN} minute(s)").replace('{MIN}',
			Math.ceil(this.state.secondsRemaining/60));
		this.setState({timeText: timerString});
	}
	handleMouseLeave() {
		this.setState({timeText: ""});
	}
	render() {
		const e = this.props.e;
		if((!this.props.aventaEnabled && !this.props.sipEnabled) ||
			e.basic.data == null ||
			e.basic.data.data == null){
			return <div/>;
		}
		let endTimer = e.basic.data.data.endTimer;
		if(endTimer <= 0 || ((endTimer*1000) - (new Date()).getTime()) < 0){
			return <div/>;
		}
		if(!this.timerId && endTimer > 0){
			this.startTimer();
		}
		let timerString = I("{MIN} min").replace('{MIN}',
			Math.ceil(this.state.secondsRemaining/60));
		if(this.state.timeText != ""){
			timerString = this.state.timeText;
		}
		return <div className="msg errand-box-close-timer"
				onMouseEnter={this.handleMouseEnter}
				onMouseLeave={this.handleMouseLeave}>
			<i className="icon-stopwatch"/>
			<span>
				{timerString}
			</span>
		</div>;
	}
}

const ErrandDataInProgress = () =>
	<h3>{I('Fetching current errand data ...')}</h3>;

export class ScrollToBottom extends React.Component {
	constructor(props) {
		super(props);
		this.triggerScroll= this.triggerScroll.bind(this);
	}
	triggerScroll() {
		var endMessage = this.refs.endMessage;
		let endMessageNode = ReactDOM.findDOMNode(endMessage);
		if(!this.props.atTop) {
			let messageNode = endMessageNode.closest('.errand-box-messages');
			if(messageNode){
				let firstChild = messageNode.firstChild;
				if(firstChild){
					firstChild.scrollIntoView();
				}
			}
		} else {
			endMessageNode.scrollIntoView({
				block: 'end',
			});
		}
	}
	render() {
		const {
			atTop,
			preferredAnswerBoxHeight,
			showReply,
			verticalView
		} = this.props;
		let iconHeight = '0px';
		let marginBottom = '0px';
		if (!verticalView) {
			if (showReply) {
				if (preferredAnswerBoxHeight >= 50) {
					iconHeight = (preferredAnswerBoxHeight - 100) + 'px';
					marginBottom = (preferredAnswerBoxHeight + 20) + 'px';
					if (navigator.userAgent.toLowerCase().indexOf('firefox') > -1) {
						// Firefox has a different calculation for pixel value
						let minus = preferredAnswerBoxHeight - 49;
						let increment = minus * 0.5;
						marginBottom = increment + 70 + 'px';
					};
				};
			} else {
				if (navigator.userAgent.toLowerCase().indexOf('firefox') > -1) {
					marginBottom = 40 + 'px';
				};
			};
		};
		let iconContainer = {
			position: 'absolute',
			right: '27px',
			zIndex: '98'
		};
		let iconStyle = {
			bottom: iconHeight.toString()
		};
		let lastDivStyle = {
			marginBottom: marginBottom.toString()
		};
		return (
			<div>
				<div className={"scroll-to-bottom-container"} onClick={this.triggerScroll}
					style={iconContainer}
					//hidden={!atTop}
				>
					<span
						className={"scroll-to-top"}
						style={iconStyle}>
						<i className={atTop ? "icon-chevron-down" : "icon-chevron-up"}></i>
					</span>
				</div>
				<div ref="endMessage" style={lastDivStyle}/>
			</div>
		)
	}
}

const ErrandMessageBlock = ({
	children
	, className
	, innerRef
	, onClick
	, onScroll
	, atTop
}) => (
		<div
			ref={innerRef} className={className} onScroll={onScroll} onClick={onClick}>
			{children}
			<ScrollToBottomCtnr atTop={atTop}>
				<ScrollToBottom />
			</ScrollToBottomCtnr>
		</div>
);

function withScrollIntoQuestionCollabInternal(Component) {
	return class extends React.PureComponent {
		constructor(props) {
			super(props);
			this.innerRef = this.innerRef.bind(this);
			this.questionRef = this.questionRef.bind(this);
			this.answerRef = this.answerRef.bind(this);
			this.collabRef = this.collabRef.bind(this);
			this.internalRef = this.internalRef.bind(this);
			this.handleScroll = this.handleScroll.bind(this);
		}
		executeAutoScroll(type) {
			let self = this;
			$('.'+this.props.className).imagesLoaded().always( function( instance ) {
				if(self.cRef != null){
					switch(type) {
						case MT_ERRAND:
							self.cRef.scrollTop = self.aref.offsetTop;
							break;
						case MT_COLLABORATION:
							self.cRef.scrollTop = self.collref.offsetTop;
							break;
						case MT_NOTE:
							self.cRef.scrollTop = self.intref.offsetTop;
							break;
						default:
							self.cRef.scrollTop = self.qref.offsetTop;
					}
				}
			});
		}
		checkIfNeedScroll() {
			let wasCloned = this.props.data.errand.wasCloned;
			if (this.props.scrollToQuestion && this.props.onAutoScrollCollabInternal && this.cRef) {
				if (this.intref && this.props.noteList)  {
					this.executeAutoScroll(MT_NOTE);
					return;
				} else if (this.collref && this.props.data.errand.collaboration) {
					this.executeAutoScroll(MT_COLLABORATION);
					return;
				}
			}
			if(this.props.scrollToQuestion && this.cRef){
				if(wasCloned) {
					if (this.aref && (this.cRef.scrollTop !== this.aref.offsetTop)) {
						this.executeAutoScroll(MT_ERRAND);
					}
				}else{
					if (this.qref && (this.cRef.scrollTop !== this.qref.offsetTop)) {
						this.executeAutoScroll();
					}
				}
			}
		}
		componentDidMount() {
			this.checkIfNeedScroll();
		}
		componentDidUpdate(prevProps, prevState) {
			this.checkIfNeedScroll();
		}
		innerRef(ref) {
			this.cRef = ref;
		}
		questionRef(ref) {
			this.qref = ref;
		}
		answerRef(ref) {
			this.aref = ref;
		}
		collabRef(ref) {
			this.collref = ref;
		}
		internalRef(ref) {
			this.intref = ref;
		}
		handleScroll(e) {
			const {
					scrollToQuestion
					, onCancelScrollToQuestion
					, onScroll
					, ...props
				} = this.props
				;
			if (scrollToQuestion) {
				onCancelScrollToQuestion(scrollToQuestion);
			}
			if (typeof onScroll === "function") {
				onScroll(e);
			}
		}
		render() {
			const {
					scrollToQuestion
					, onCancelScrollToQuestion
					, onScroll
					, ...props
				} = this.props
				;
			return (
				<Component {...props}
					innerRef={this.innerRef}
					onScroll={this.handleScroll}
					questionRef={this.questionRef}
					answerRef={this.answerRef}
					collabRef={this.collabRef}
					internalRef={this.internalRef}
				/>
			);
		}
	}
}

const checkToShowViewErrandQuestion = (errandViewOnly, question) => (
	!errandViewOnly || question.length > 0
)

const checkFilterAndCloneToShowQuestion = (
	{ allMessages, byAgents, byCustomers },
	cloned
) => (allMessages || byCustomers) && !byAgents && !cloned

const AnsweredSystemMessage = composeWithDisplayName(
	'AnsweredSystemMessage',
	withUnmountWhenHidden,
	memo,
	withAnsweredStates,
	withSystemMessage
)(RepliedNote)

const LinkedErrandMessage = composeWithDisplayName(
	'LinkedErrandMessage',
	withUnmountWhenHidden,
	memo,
	withLinkedErrandsStates,
	withSystemMessage
)(LinkedErrandsNote)

const AnswerOrExternalForwardBase = ({
	channelId,
	displayId,
	parseFormat,
	showAnsweredSystemMessage,
	isClosedNoAnswer,
	parseFormatLinkedDate,
	showLinkedErrandMessage,
	tag: Tag,
	timestamp,
	writer,
	errandLinkDate,
	automatedAnswer,
	currentSuggestedAnswerUsed,
	...props
}) => (
	<Fragment>
		<Tag
			channelId={channelId}
			displayId={displayId}
			parseFormat={parseFormat}
			timestamp={timestamp}
			writer={writer}
			automatedAnswer={automatedAnswer}
			currentSuggestedAnswerUsed={currentSuggestedAnswerUsed}
			{...props}
		/>
		<AnsweredSystemMessage
			channelId={channelId}
			isClosedNoAnswer={isClosedNoAnswer}
			displayId={displayId}
			parseFormat={parseFormat}
			timestamp={timestamp}
			writer={writer}
			automatedAnswer={automatedAnswer}
			hidden={!showAnsweredSystemMessage}
		/>
		{features['link-errand'] && <LinkedErrandMessage
			channelId={channelId}
			displayId={displayId}
			parseFormat={parseFormatLinkedDate}
			timestamp={errandLinkDate}
			writer={writer}
			automatedAnswer={automatedAnswer}
			hidden={!showLinkedErrandMessage}
		/>
		}
	</Fragment>
)

const AnswerOrExternalForward = withProps(
	({ wasForwardedToExternal }) => ({
		tag: wasForwardedToExternal ? ExternalForwarded : ErrandAnswer
	})
)(AnswerOrExternalForwardBase)

//style copied from SystemMessage component
const ErrandSysMsg = styled.div`
	font-size: 12px;
	display: inline-block;
	padding: 5px 16px 3px 14px;
	margin-left: 15px;
	background-color: #f5eef8;
	color: #333333;
	max-width: calc(100% - 81px);
`;

function withCurrentQuestionAnswer(Component) {
	return ({
		agentCanDeleteFeature
		, associatedErrands
		, canTranslate
		, children
		, constants
		, data
		, eid
		, forward
		, forwardSelection
		, hasHistory
		, includeAttachment
		, interfaceLang
		, myErrandReady
		, onActionClick
		, onSelectForward
		, onTranslation
		, questionRef
		, answerRef
		, filterPopup
		, agentCanEditQuestionFeature
		, truncateErrandMessageByDefault
		, onSetErrandMessageTruncatePref
		, onClickAgentAssistTrigger
		, onSend
		, onSocialMediaUIAction
		, currentSuggestedAnswerUsed
		, ...props
	}) => {
		let m
			, hasAnswer
			;
		if (myErrandReady) {
			const {
					client: writer
					, agent
					, lastAction
					, lastSaved
					, errand: myE
					, history
				} = data
				, { actions, automatedAnswer } = history
				, selected = forwardSelection[myE.id]
				;
			let { answer, question } = data;
			let answerTime
			let parseFormat
			let actionType
			let currentHistory
			let replied
			m = [];

			if(myE && myE.service === Workflow.Errand.SERVICE_MANUAL_VOICE) {
				if(myE.scheduleCallback != "") {
					let scheduleMsgTxt = I(" send a call back request at ");
					let scheduleMsgTxtFrmt = writer.name + scheduleMsgTxt + myE.scheduleCallback;
					m.push(<ErrandSysMsg key={'q-callback-schedule-msg'+myE.id}> {scheduleMsgTxtFrmt} </ErrandSysMsg>);
				}
			}

			let isLinked

			if ( myE.errandLinkDate ) {
				isLinked = true
			}
			if (myE.answered && myE.answeredDate !== constants.NotAnswered) {
				replied = true
				answerTime = myE.answeredDate;
				if (lastAction) {
					actionType = lastAction.actionType;
				}
			} else if (lastSaved) {
				answerTime = lastSaved.dateTime;
				parseFormat = PF_TIMESTAMP;
			} else if (answer) {
				// need to handle case where cloned errand that has message or
				// the action for saving errand is not there.
				answerTime = myE.date;
			}
			const isHisExists = hasHistory || myE.collaboration;
			if (answerTime) {
				hasAnswer = true;
			}
			if (answerTime || myE.errandLinkDate) {
				let attachments
				let emoticons = myE.answerEmotions
				let breakLine = false
				if (includeAttachment) {
					attachments = history.outgoing;
				}
				if(myE.service === Workflow.Errand.SERVICE_MANUAL_FACEBOOK){
					emoticons = null;
					question = answer;
					answer = "";
				}
				// Show line break when question being suppressed by filter or cloned.
				if (isHisExists &&
					!(checkToShowViewErrandQuestion(errandViewOnly, question) &&
					checkFilterAndCloneToShowQuestion(filterPopup, myE.wasCloned))) {
					breakLine = true;
				}
				m.push(<AnswerOrExternalForward
					key={'myA'+myE.id}
					myErrandId={myE.id}
					area={data?.errand?.area}
					innerRef={answerRef}
					writer={agent}
					breakLine={breakLine}
					attachments={attachments}
					associatedErrands={associatedErrands}
					html={answer}
					onClickAgentAssistTrigger={onClickAgentAssistTrigger}
					onSend={onSend}
					delete={false}
					print={false}
					edit={true}
					isClosedNoAnswer={props.isClosedNoAnswer}
					parseFormat={parseFormat}
					timestamp={answerTime}
					answered={myE.answered}
					tags={myE.tagsList}
					actionsHistory={replaceEmptyArray(actions)}
					onActionClick={onActionClick}
					actionType={actionType}
					truncateErrandMessageByDefault={truncateErrandMessageByDefault}
					onSetErrandMessageTruncatePref={onSetErrandMessageTruncatePref}
					channelId={myE.service}
					showErrandNumber={myE.wasCloned}
					displayId={myE.displayId}
					socialEmoticon={emoticons}
					onSocialMediaUIAction={onSocialMediaUIAction}
					showAnsweredSystemMessage={replied}
					showLinkedErrandMessage={isLinked}
					errandLinkDate={myE.errandLinkDate}
					parseFormatLinkedDate={PF_DEFAULT}
					showExactDayAndTime={props.showExactDayAndTime}
					wasForwardedToExternal={myE.wasForwardedToExternal}
					automatedAnswer={automatedAnswer}
					currentSuggestedAnswerUsed={currentSuggestedAnswerUsed}
				/>);
			}
			if (checkToShowViewErrandQuestion(errandViewOnly, question)) {
				let errandActions
				let attachments
				if (!hasAnswer) {
					errandActions = actions;
				}
				if (includeAttachment) {
					attachments = history.incoming;
				}
				if (checkFilterAndCloneToShowQuestion(filterPopup, myE.wasCloned)) {
					m.unshift(<ErrandQuestion
						key={'myQ'+myE.id}
						writer={writer}
						breakLine={isHisExists}
						attachments={attachments}
						from={myE.frm}
						html={question}
						timestamp={myE.date}
						forward={forward}
						onSelectForward={onSelectForward}
						innerRef={questionRef}
						selected={selected}
						channel={myE.serviceName}
						channelId={myE.service}
						myErrandId={myE.id}
						area={data?.errand?.area}
						eid={myE.id}
						delete={agentCanDeleteFeature ? true : false}
						interfaceLang={interfaceLang}
						canTranslate={canTranslate}
						canEditQuestion={agentCanEditQuestionFeature}
						onActionClick={onActionClick}
						actionsHistory={replaceEmptyArray(errandActions)}
						hasAnswer={!!hasAnswer}
						onTranslation={onTranslation}
						truncateErrandMessageByDefault={truncateErrandMessageByDefault}
						onSetErrandMessageTruncatePref={onSetErrandMessageTruncatePref}
						onClickAgentAssistTrigger={onClickAgentAssistTrigger}
						onSend={onSend}
						showErrandNumber={true}
						displayId={myE.displayId}
						socialEmoticon={myE.socialEmotions}
						onSocialMediaUIAction={onSocialMediaUIAction}
						showExactDayAndTime={props.showExactDayAndTime}
					/>);
				}
			}
		} else {
			m = <ErrandDataInProgress />;
		}
		return (
			<Component {...props}>
				{children}{m}
			</Component>
		);
	};
}

function withHistoriesBase(Component, QuestionComponent, AnswerComponent) {
	return ({
		historyReady
		, showInternalComment
		, showCollaboration
		, ...props
	}) => {
		const {
				canTranslate
				, truncateErrandMessageByDefault
				, data
				, eid
				, forward
				, forwardSelection
				, interfaceLang
				, onActionClick
				, onSelectForward
				, onTranslation
				, filterPopup
				, onSetErrandMessageTruncatePref
				, onClickAgentAssistTrigger
				, onSend
				, onSocialMediaUIAction
			} = props
			;
		let m = []
			, hasAnswer
			;
		if (historyReady) {
			const { histories, my, avatars } = data
				;
			let answerTime
				, actionType
				, errandActions
				, { client } = my
				;
			if(my.errand && my.errand.service === Workflow.Errand.SERVICE_CHAT) {
				let chatSM = my.errand.chatSatisfactionMeter;
				if(typeof chatSM !== 'undefined' && chatSM.rating !== "") {
					m.push(<ClientSatisfactionMeter key={"ClientSatisfactionMeter"}
					                                clientChatSatisfaction={true} csmData={chatSM} />);
				}
			}
			$.each(histories, (i, v) => {	
				const selected = forwardSelection[v.eid];
				const isCurrentOpen = eid == v.eid;
				let answerEmots = v.answerEmoticons;
				let msg
					, answer
					, from = v.from
					;
				if (v.htmlmessage) {
					msg = v.htmlmessage;
				} else {
					msg = v.message;
				}
				if (v.htmlanswer) {
					answer = v.htmlanswer;
				} else {
					answer = v.answer;
				}
				if(v.channelId === Workflow.Errand.SERVICE_MANUAL_FACEBOOK){
					msg = answer;
					answer = "";
					answerEmots = null;
				}
				if(typeof avatars !== "undefined") {
					let ob = avatars[v.fromId];
					if (ob && ob.avatar) {
						client = {photo: ob.avatar.url, name: ob.fromAddress};
					}
				}
				if((filterPopup.allMessages || filterPopup.byCustomers) && !filterPopup.byAgents && !v.wasCloned){
					m.push(<QuestionComponent
						key={'q'+v.eid}
						writer={client}
						attachments={v.incoming}
						from={from}
						forward={forward}
						html={msg}
						parseFormat={PF_HISTORY_CREATED}
						timestamp={v.created}
						channel={v.channel}
						channelId={v.channelId}
						onSelectForward={onSelectForward}
						myErrandId={v.eid}
						area={data?.errand?.area}
						eid={eid}
						selected={selected}
						interfaceLang={interfaceLang}
						canTranslate={canTranslate}
						truncateErrandMessageByDefault={truncateErrandMessageByDefault}
						onSetErrandMessageTruncatePref={onSetErrandMessageTruncatePref}
						onClickAgentAssistTrigger={onClickAgentAssistTrigger}
						onSend={onSend}
						showErrandNumber={true}
						hid={v.displayId}
						actionsHistory={v.actions}
						delete={false} //history can't be deleted
						onActionClick={onActionClick}
						onTranslation={onTranslation}
						socialEmoticon={v.socialEmotions}
						onSocialMediaUIAction={onSocialMediaUIAction}
						isCurrentOpen={isCurrentOpen}
					/>);
				}else{
					m.push(<div key={'q'+v.eid}></div>)
				}

				// answer part
				let answered
					, answerTime
					, parseFormat
					, actionType
					;
				if (v.lastAction) {
					answered = true;
					answerTime = v.lastAction.dateTime;
					parseFormat = PF_TIMESTAMP;
					actionType = v.lastAction.actionType;
				} else if (v.lastSaved) {
					answerTime = v.lastSaved.dateTime;
					parseFormat = PF_TIMESTAMP;
				} else if (answer) {
					answerTime = v.created;
					parseFormat = PF_HISTORY_CREATED;
				} else {
					answerTime = UNKNOWN_DATE;
				}
				if((filterPopup.allMessages || filterPopup.byAgents) && !filterPopup.byCustomers ){
					m.push(<AnswerComponent
						key={'a'+v.eid}
						writer={v.errandOwner}
						attachments={v.outgoing}
						showErrandNumber={v.wasCloned}
						hid={v.displayId}
						answered={answered}
						html={answer}
						parseFormat={parseFormat}
						timestamp={answerTime}
						actionType={actionType}
						forward={forward}
						tags={v.tagsList}
						actionsHistory={v.actions}
						delete={false} //history can't be deleted
						edit={false}
						print={false}
						onSelectForward={onSelectForward}
						myErrandId={v.eid}
						area={data?.errand?.area}
						eid={eid}
						selected={selected}
						channelId={v.channelId}
						socialEmoticon={answerEmots}
						onSocialMediaUIAction={onSocialMediaUIAction}
						onActionClick={onActionClick}
						isCurrentOpen={isCurrentOpen}
					/>);
				}else{
					m.push(<div key={'a'+v.eid}></div>)
				}
			});
		} else {
			m.push(<HistoryDataInProgress key="fetching-history" />);
		}
		return <Component showInternalComment={showInternalComment} showCollaboration={showCollaboration} {...props}>{m}</Component>;
	};
}

function withHistories(Component) {
	return withHistoriesOrOthers(Component, ErrandQuestion, ErrandAnswer)
}

const hasHistory = ({ histories, historiesAndOthers }) => {
	return historiesAndOthers.allIds.length > 0
}

function currentErrandDataProp(Component) {
	return ({ data, ...props }) => (
		<Component {...props} data={data.my} hasHistory={hasHistory(data)} />
	);
}

function withMessageCollabInternal(Component) {
	return ({
		...props
	}) => {
		const {children} = props
		;
		let m = []
		return <Component {...props}>{children}{m}</Component>;
	};
}

function withCountdownTimer(Component) {
	return ({
		...props
	}) => {
		const {
			children
		} = props
		;
		return <Component {...props}>{children}
			<CountdownTimerCtnr />
		</Component>;
	};
}

const ErrandBoxMessagesBase = compose(
	withHistories
	, currentErrandDataProp
	, withCountdownTimer
	, withScrollIntoQuestionCollabInternal
	, withMessageCollabInternal
	, withCurrentQuestionAnswer
)(ErrandMessageBlock);

function withErrandMessageHandlers(Component) {
	return class extends React.PureComponent {
		constructor(props) {
			super(props);
			this.handleClick = this.handleClick.bind(this);
			this.handleMessageAction = this.handleMessageAction.bind(this);
			this.handleScroll = this.handleScroll.bind(this);
			this.state = {
				atTop: false
			}
		}
		handleClick(e) {
			this.props.onToggleAttachmentPanel(false, true);
			if (this.props.isClosed) {
				this.props.onActivateView();
			}
		}
		handleMessageAction(act, id, val, isAgent) {
			if (this.props.onActionClick) {
				this.props.onActionClick(act, id, val, isAgent);
			}
		}
		handleScroll(e) {
			let scrollDown, scrollTop = e.target.scrollTop;
			if (this.previousScroll) {
				scrollDown = scrollTop > this.previousScroll;
			} else {
				scrollDown = true;
			}
			this.previousScroll = scrollTop;
			// simple change 'scrollDown' below to 'scrollTop > 0' if only want
			// scroll to top to show attachment panel.
			if (scrollTop > 0) {
				this.props.onToggleAttachmentPanel(false, true);
			} else {
				this.props.onToggleAttachmentPanel(true);
			}
			if(scrollTop) {
				let maxHeight = (e.target.scrollHeight-e.target.clientHeight-40)
				if(scrollTop <= maxHeight) {
					this.setState({atTop: true})
				}
				if(scrollTop >= maxHeight) {
					this.setState({atTop: false})
				}
			}
		}
		handleSocialMediaUIAction = (emotErrandId, statusId) => {
			this.props.socialMediaUI(
				this.props.showReactionPopup,
				'reactions',
				emotErrandId,
				MEDIA_ACTION.FB_EMOTION_HISTORY,
				"",
				closeStatusString(statusId)
			);
		}
		render() {
			const {
					isClosed
					, extraClass
					, onActionClick
					, onActivateView
					, socialMediaUIAction
					, onToggleAttachmentPanel
					, ...props
				} = this.props
				;
			return (
				<Component {...props}
					className={classNames("errand-box-messages", extraClass)}
					onActionClick={this.handleMessageAction}
					onSocialMediaUIAction={this.handleSocialMediaUIAction}
					onScroll={this.handleScroll}
					onClick={this.handleClick}
					atTop={this.state.atTop}
				/>
			);
		}
	}
}

const ErrandBoxOptionalMessages = withErrandMessageHandlers(ErrandBoxMessagesBase);

const ErrandBoxMessages = props => (
	<ErrandBoxOptionalMessages {...props}
		showInternalComment={true}
		showCollaboration={true}
	/>
);

function withReadOnlyHistories(Component) {
	return withHistoriesBase(Component, ReadOnlyQuestion, ReadOnlyAnswer);
}

const defFilterPopup = {allMessages: true};

function withoutFilter(Component) {
	return props => <Component {...props} filterPopup={defFilterPopup} />;
}

export const ErrandHistoriesMessages = compose(
	withErrandMessageHandlers
	, withoutFilter
	, withReadOnlyHistories
)(ErrandMessageBlock);

const SimpleSubjectBar = ({...p}) => {
	return (
		<div className="subject-wrapper" hidden={!p.showSubject}>
			<div className="label" hidden={p.verticalView ? true : false}>{I('Subject:')}</div>
			<div className="subject-input">
				<input type="text" onChange={p.onSubjectChange} data-qa-id={"QA_subjectReply"}
					placeholder={I('Subject')} value={p.subject}  disabled = {(!p.editSubject)? "disabled" : ""}/>
			</div>
		</div>
	);
}

export const WrapIcon = ({className, children, hidden, icon, onClick}) => <div hidden={hidden} className={className} onClick={onClick}><i className={icon}></i>{children}</div>

class Subjectbar extends React.Component {
	render() {
		const p = this.props;
		return (
			<div className="subjectbar">
				<div className="left" hidden={p.hide}>
					{p.children}
					<div className="subject-group">
						<div className="subject" hidden={!p.showSubject}>
							<div className="label" hidden={p.verticalView ? true : false}>{I('Subject:')}</div>
							<div className="subject-input">
								<input type="text" onChange={p.onSubjectChange} data-qa-id={"QA_subjectReply"}
									placeholder={I('Subject')} value={p.subject} />
							</div>
						</div>
						{p.showToolbar &&
							<div className="top-reply-toolbar">
								<ReplyToolbarCtnr
									show={p.showToolbar} //For both channel selection and "Show/hide toggle"
									editorId={"ckeditorv5"}
									className="toolbar"
									enableAction={p.enableAction}
									options={p.channelOpts}
									collabOpts={p.collabOpts}
									onSelectAction={p.onSelectAction}
									showChannelOpt={p.replyAs === RPLY_EXT_FWD? false : true}
									showSpellcheck={false}
									showHideOption={true}
									availableReplyPanel={p.availableReplyPanel}
									selectedReplyPanel={p.selectedReplyPanel}
									onSelectedViewPanel={p.onSelectedViewPanel}
								/>
							</div>
						}
					</div>
				</div>
			</div>
		);
	}
}

const InternalCollaborationBase = ({
	onClear,
	onClick,
	selectedAgents,
	verticalView
}) => (
	<div
		className={classNames(
			'internal-container collaborate',
			{ vertical: verticalView }
		)}
	>
		<div className="internal-collab-label">
			{I('Internal:')}
		</div>
		<div className="internal-collab-input">
			<Anchor
				data-qa-id="internal-collab-selector"
				className="title"
				onClick={onClick}
			>
				{selectedAgents}
			</Anchor>
			<Anchor className="remove" onClick={onClear}>
				<i className="icon-circled-times" />
			</Anchor>
		</div>
	</div>
)

const InternalCollaboration = composeWithDisplayName(
	'InternalCollaboration',
	memo,
	withUnmountWhenHidden
)(InternalCollaborationBase)

const AreaCollaborationBase = ({
	areas,
	selectedAreas,
	toggleArea,
	handleSelectArea,
	handleToggleArea,
	verticalView
}) => {

	const menuRef = useRef();
	const [isOpen, setIsOpen] = useState(false);
	const handleToggle = () => setIsOpen(!isOpen);

	// this is to close area dropdown when targeting ckeditor's iframe due to it having separate DOM's
	// basically add outside click listener to ckeditor's "textarea" iframe
	const handleClickOutside = (event) => {
		// click occurred within the component.
		const clickedOutsideTarget = !menuRef.current.contains(event.target);
		const clickedInTarget = menuRef.current.contains(event.target);

		// Get the iframe element with class 'c3-ckeditor'
		const iframe = document.querySelector('.c3-ckeditor iframe');

		// Access the iframe's content document
		const iframeDocument = iframe.contentDocument || iframe.contentWindow.document;

		const htmlIframe = iframeDocument.querySelector('html');

		const clickHandler = () => {
			setIsOpen(false)
		}
		// iframeDocument.removeEventListener('click', clickHandler);
		if(menuRef.current && clickedInTarget) {
			// Add click event listener to the element inside the iframe
			htmlIframe.addEventListener('click', clickHandler);
		}
		//if clickoutside when dropdown not opened, we remove back
		if (menuRef.current && clickedOutsideTarget && !isOpen) {
			htmlIframe.removeEventListener('click', clickHandler);
			document.removeEventListener('click', handleClickOutside);
		}
	};

	useEffect(() => {
		// Bind the event listener when the component mounts
		document.addEventListener('click', handleClickOutside);

		return () => {
			// Clean up the event listener when the component unmounts
			document.removeEventListener('click', handleClickOutside);
		};
	}, [isOpen]);

	return (
	<div
		className={classNames(
			'internal-container collaborate area-collab',
			{ vertical: verticalView }
		)}
	>
		<div className="internal-collab-label">
			{I('Area:')}
		</div>
		<div ref={menuRef} className="internal-collab-input">
			<AreaDropdown
				textNoItemSelected={I("No area selected")}
				id={"areas-recipient"}
				data={areas}
				idFields={{id: "Id", name: "Name"}}
				nested={keyAreas}
				multiSelect={true}
				selected={selectedAreas}
				onSelect={handleSelectArea}
				// onToggle={handleToggleArea}
				onToggle={handleToggle}
				selectAll={true}
				selectNone={true}
				overrideTitle={true}
				// show={toggleArea}
				show={isOpen}
			/>
		</div>
	</div>
)
}

const AreaCollaboration = composeWithDisplayName(
	'AreaCollaboration',
	memo,
	withUnmountWhenHidden
)(AreaCollaborationBase)

const optionsField = { id: "id", value: "value" }

class EmailRecipients extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			toggleArea: false,
			currentEmailValue: ""
		};
		this.handleRecipientsChange = this.handleRecipientsChange.bind(this);
		this.handleChangeEmail = this.handleChangeEmail.bind(this);
		this.handleContactForTo = this.handleContactForTo.bind(this);
		this.handleContactForCc = this.handleContactForCc.bind(this);
		this.handleContactForBcc = this.handleContactForBcc.bind(this);
		this.handleContactForInternalCollaborate =
			this.handleContactForInternalCollaborate.bind(this);
		this.handleClearSelection = this.handleClearSelection.bind(this);
		this.handleToggleArea = this.handleToggleArea.bind(this);
	}
	handleRecipientsChange(emails, name) {
		var mails = [];
		let skipEmailCheck = (this.props.currentChannel === RC_SLACK &&
			this.props.replyAs === RPLY_COLLABORATE);
		if(emails && emails.length > 0) {
			$.each(emails, function(k,v) {
				const email = getEmailOutParentheses(v);
				if(isValidEmail(email) || skipEmailCheck){
					mails.push({id: email, value: email});
				}else{
					window.alert(MESSAGE_INVALID_EMAIL);
				}
			}.bind(this));
		}
		this.props.onRecipientsChange(mails, name);
	}
	handleChangeEmail(t){
		let email = update(this.state.currentEmailValue,{$set: t});
		this.setState({currentEmailValue: email});
	}
	handleContactForTo(){
		const p = this.props;
		p.onToggleContactBook(true
			, (p.replyAs === RPLY_EXT_FWD ?
				RECIPIENT_FORWARD : RECIPIENT_TO));
	}
	handleContactForCc(){
		this.props.onToggleContactBook(true, RECIPIENT_CC);
	}
	handleContactForBcc(){
		this.props.onToggleContactBook(true, RECIPIENT_BCC);
	}
	handleContactForInternalCollaborate(){
		this.props.onToggleContactBook(true
			, RECIPIENT_INTERNAL_COLLABORATE);
	}
	handleClearSelection(){
		this.props.onClearCollaborators();
	}
	handleToggleArea() {
		this.setState({
			toggleArea: !this.state.toggleArea
		});
	}
	render() {
		const {
			hideInternalCollaborators,
			selectedAgents,
			verticalView,
			...p
		} = this.props;
		const emails = p.emailRecipients;
		const selectedCCEmail = emails.cc;
		const selectedBCCEmail = emails.bcc;
		let toTitle, toName, hide, selectedReplyToEmail;
		let toType = "email";
		let enableCcBcc = true;
		if (p.replyAs === RPLY_EXT_FWD) {
			toTitle = I('Forward To:');
			toName = "forward";
			selectedReplyToEmail = emails.fwdTo;
		} else {
			selectedReplyToEmail = emails.to;
			toName = "to";
			if (p.replyAs === RPLY_COLLABORATE) {
				toTitle = I('To:');
				toType = p.currentChannel;
				/*TODO: Since collaboration is mainly on email,
				does it make sense for all channels 'toType' input to be email as well?
				Rigth now for voice only, the input ToType changed to `email.
				*/
				if (p.currentChannel == RC_VOICE) {
					toType = "email";
				}
				if (p.currentChannel == RC_SLACK || p.currentChannel == RC_JIRA) {
					// TODO: directly hide the input should be more UI friendliness than
					// disable it.
					enableCcBcc = false;
				}
			} else {
				toTitle = I('Reply To:');
			}
		}
		if (!p.show) {
			hide = true;
		}
		var areaCollaboration = null;
		if (features["external-experts.between-organization-and-area"]) {
			areaCollaboration = <AreaCollaboration
				hidden={p.replyAs !== RPLY_COLLABORATE || hideInternalCollaborators}
				areas={p.areas}
				selectedAreas={p.selectedAreas}
				handleSelectArea={p.onSetAreaRecipient}
				handleToggleArea={this.handleToggleArea}
				toggleArea={this.state.toggleArea}
				verticalView={verticalView}
			/>
		}
		return (
			<div className='recipients' hidden={hide}>
				{verticalView && !p.mobileView ? null : <div className="to-label">{toTitle}</div>}
				<div className="to-input" >
					<TagPicker
						id={toName}
						type={toType}
						required={true}
						className={"to-input"}
						name={toName}
						maxInput={-1} //-1 for unlimited
						options={p.recipientsOptions}
						fields={optionsField}
						selected={selectedReplyToEmail}
						onChange={this.handleRecipientsChange}
						onChangeTag={this.handleChangeEmail}
						latestVal={this.state.currentValue}
						onShowContactBook={this.handleContactForTo}
						verticalView={verticalView}
					/>
				</div>
				{verticalView && !p.mobileView? null: <div className="cc-label">{I('CC:')}</div>}
				<div className="cc-input">
					<TagPicker
						id={"cc"}
						type={"email"}
						required={false}
						className={"cc-input"}
						name={"cc"}
						disabled={!enableCcBcc}
						options={p.recipientsOptions}
						fields={optionsField}
						selected={selectedCCEmail}
						onChange={this.handleRecipientsChange}
						onChangeTag={this.handleChangeEmail}
						latestVal={this.state.currentValue}
						onShowContactBook={this.handleContactForCc}
						verticalView={verticalView}
					/>
				</div>
				{verticalView && !p.mobileView? null : <div className="bcc-label">{I('BCC:')}</div>}
				<div className="bcc-input" >
					<TagPicker
						id={"bcc"}
						type={"email"}
						required={false}
						className={"cc-input"}
						name={"bcc"}
						disabled={!enableCcBcc}
						options={p.recipientsOptions}
						fields={optionsField}
						selected={selectedBCCEmail}
						onChange={this.handleRecipientsChange}
						onChangeTag={this.handleChangeEmail}
						latestVal={this.state.currentValue}
						onShowContactBook={this.handleContactForBcc}
						verticalView={verticalView}
					/>
				</div>
				<div className="internal-collaborator">
					<InternalCollaboration
						hidden={p.replyAs !== RPLY_COLLABORATE || hideInternalCollaborators}
						onClear={this.handleClearSelection}
						onClick={this.handleContactForInternalCollaborate}
						selectedAgents={selectedAgents}
						verticalView={verticalView}
					/>
					{areaCollaboration}
            </div>
			</div>
		);
	}
}

class GeneralSubjectbar extends React.Component {
	render() {
		const { hideInternalCollaborators, ...p } = this.props;
		let currentChannel = p.currentChannel;
		return <Subjectbar
				subject={p.subject}
				channelOpts={p.channelOpts}
				currentChannel = {p.currentChannel}
				collabOpts={p.collabOpts}
				enableAction={p.enableAction}
				showToolbar={p.showToolbar}
				onSelectAction={p.onSelectAction}
				onSubjectChange={p.onSubjectChange}
				hide={p.hideSubject}
				verticalView={p.verticalView}
				availableReplyPanel={p.availableReplyPanel}
				selectedReplyPanel={p.selectedReplyPanel}
				onSelectedViewPanel={p.onSelectedViewPanel}
				showSubject={p.showSubject}
				replyAs={p.replyAs}
				>
				<EmailRecipients
					//agents={p.agents}
					hideInternalCollaborators={hideInternalCollaborators}
					replyAs={p.replyAs}
					onRecipientsChange={p.onRecipientsChange}
					currentChannel={currentChannel}
					show={p.showRecipients}
					selectedAgents={p.selectedAgents}
					onToggleContactBook={p.onToggleContactBook}
					onClearCollaborators={p.onClearCollaborators}
					emailRecipients={p.emailRecipients}
					recipientsOptions={p.recipientsOptions}
					verticalView={p.verticalView}
					mobileView={p.mobileView}
				/>
			</Subjectbar>;
	}
}

const RewriteAnswerAssist = withIdAndTitle(CtrlRewriteAnswerPopup, "errandRewriteAnswerBase", I("Agent assist"));
const RewriteAnswerAssistHideText = withIdAndTitle(CtrlRewriteAnswerPopup, "errandRewriteAnswerBase", "");
// TODO: move to Assist once CtrlKnowledgeBasePopup converted to reactcomponents
const KnowledgeBaseAssist = withIdAndTitle(CtrlKnowledgeBasePopup, "errandKnowledgeBase", I("Open Knowledge base"));
// Hide title when resolution is smaller
const KnowledgeBaseAssistHideText = withIdAndTitle(CtrlKnowledgeBasePopup, "errandKnowledgeBase", "");

export class ErrandReplyHeader extends React.Component {
	constructor(props) {
		super(props);
		this.handlePreviewClick = this.handlePreviewClick.bind(this);
		this.handleToggleKnowledgeBase = this.handleToggleKnowledgeBase.bind(this);
		this.onSelectSignature = this.onSelectSignature.bind(this);
		this.onSelectSalutation = this.onSelectSalutation.bind(this);
		this.onAppendTemplate = this.onAppendTemplate.bind(this);
		this.onAppendSuggestedAnswer = this.onAppendSuggestedAnswer.bind(this);
	}
	handlePreviewClick() {
		this.props.onClickPreview(this.props.currentSelected);
	}
	handleToggleKnowledgeBase(openState) {
		this.props.onToggleKnowledgeBase(!this.props.openKnowledgeBase);
	}
	onSelectSignature(id) {
		this.props.setSignature(id, this.props.currentSelected);
	}
	onSelectSalutation(id) {
		this.props.setSalutation(id, this.props.currentSelected);
	}
	onAppendTemplate(id, ob) {
		let tmplName = '['+ ob.Name +']'
		if(this.props.currentChannel == RC_TWITTER) {
			tmplName = $('' + ob.Content).text()
		}
		if(this.props.currentChannel === RC_WHATSAPP) {
			if( ob.UsedFor === USED_FOR_WHATSAPP_TEMPLATE ){
				this.props.onShowTemplateCustom(this.props.showWATemplatePopup, ob.Content, ob.Id);
			} else {
				this.props.appendTemplate(tmplName);
			}
		} else {
			this.props.appendTemplate(tmplName);
		}
	}
	onInsertQuickReply =(id, ob)=>{
		console.log("onInsertQuickReply >> ", id, ob);
		const editor = CKEDITOR.instances["chatEditor"];
		let qr = {"quickReplyId": ob.Id};
		let strCode = JSON.stringify(qr);
		if( editor && this.props.chat ){
			editor.setData(templCont);
			editor.setReadOnly(true);
			this.props.onInsertQuickReply(ob.Id, templCont, strCode);
		}
		if(this.props.currentChannel === RC_FACEBOOK) {
			let content = ob.Content;
			this.props.onInsertQuickReply(ob.Id, content, strCode);
		}
	}
	onAppendSuggestedAnswer(id, obj) {
		this.props.appendSuggestedAnswer(obj.answer, obj.attachments, this.props.currentSelected);
	}
	render() {
		const {
				chat
				, currentChannel
				, currentSelected
				, isCollaboration
				, onOpenInsertProtectedContentReply
				, openKnowledgeBase
				, openRewriteAnswerBase
				, previewDisabled
				, salutations
				, selectedSal
				, selectedSig
				, signatures
				, show
				, showPreview
				, templates
				, quickReplies
				, displayQuickReply
				, suggestedAnswers
				, onlyAllowAdminSetSignature
				, onlyAllowAdminSetSalutation
				, agentEnforceSignatureAnswerErrand
			} = this.props
			;
		return (
			<Assist hidden={!show}>
				{/* TODO: get the features from redux store instead of global variable */}
				<AssistColumn
					className="reply-links-column knowledgebase"
					hidden={!features["cention-library"]}
				>
					<KnowledgeBaseLabel />
					<AssistBarItems>
						<AssistBarOneItem>
							<KnowledgeBaseAssist
								open={openKnowledgeBase}
								onToggle={this.handleToggleKnowledgeBase}
							/>
						</AssistBarOneItem>
					</AssistBarItems>
				</AssistColumn>
				<AssistColumn
					hidden={!notChatOrChatInCollborate(chat, currentSelected)}
				>
					<AssistBarLabel />
					<AssistBarItems>
						<AssistBarOneItem>
							<TemplateAssist
								data={templates}
								onAppendItem={this.onAppendTemplate}
								direction="down"
							/>
						</AssistBarOneItem>
						<AssistBarOneItem hidden={isCollaboration}>
							<SalutationAssist
								data={salutations}
								selected={selectedSal}
								onSelectItem={this.onSelectSalutation}
								disabled={onlyAllowAdminSetSalutation}
								direction="down"
							/>
						</AssistBarOneItem>
						<AssistBarOneItem>
							<SignatureAssist
								data={signatures}
								selected={selectedSig}
								onSelectItem={this.onSelectSignature}
								disabled={onlyAllowAdminSetSignature}
								disallowReset={agentEnforceSignatureAnswerErrand}
								direction="down"
							/>
						</AssistBarOneItem>
						<AssistBarOneItem hidden={!showPreview}>
							<PreviewAssist
								disabled={previewDisabled}
								onClick={this.handlePreviewClick}
							/>
						</AssistBarOneItem>
						<AssistBarOneItem hidden={currentChannel !== RC_EMAIL}>
							<ProtectAssist
								onClick={onOpenInsertProtectedContentReply}
							/>
						</AssistBarOneItem>
						{features["facebook.quick.reply"] &&
							<AssistBarOneItem hidden={!displayQuickReply}>
								<QuickReplyAssist
									data={quickReplies}
									onAppendItem={this.onInsertQuickReply}
									direction="down"
								/>
							</AssistBarOneItem>
						}
					</AssistBarItems>
				</AssistColumn>
			</Assist>
		);
	}
}

const HideableDiv = withUnmountWhenHidden("div");

export const replyOptions = {
		[RPLY_ERRAND]: {
			id: RPLY_ERRAND,
			name: I('REPLY'),
			hotkey: errandHotkeyChar(TOGGLE_REPLY)
		},
		[RPLY_COMMENT]: {
			id: 'internal-comment',
			name: I('INTERNAL COMMENT'),
			hotkey: errandHotkeyChar(TOGGLE_COMMENT)
		},
		[RPLY_COLLABORATE]: {
			id: RPLY_COLLABORATE,
			name: I('COLLABORATE'),
			hotkey: errandHotkeyChar(TOGGLE_COLLAB)
		},
		[RPLY_EXT_FWD]: {
			id: 'forward-to-external',
			name: I('FORWARD TO EXTERNAL'),
			hotkey: errandHotkeyChar(TOGGLE_FRWD_XTRNL)
		},
		[RPLY_GRP_CHAT_COL]: {
			id: RPLY_GRP_CHAT_COL,
			name: I('COLLABORATE')
			// hotkey: errandHotkeyChar(TOGGLE_IN_CHAT_COL) // TODO: internal chat collaboration hot key
		},
		[RPLY_QUESTION]: {
			id: RPLY_QUESTION,
			name: I('QUESTION'),
			hotkey: errandHotkeyChar(TOGGLE_QUESTION)
		},
		[RPLY_CHAT_SUMMARY]: {
			id: 'chat-summary',
			name: I('CLOSE WITH SUMMARY'),
			hotkey: errandHotkeyChar(TOGGLE_CHAT_SUMMARY)
		},
	}, disabledAnsweredOptions = {
		[RPLY_ERRAND]: true,
		[RPLY_COMMENT]: false,
		[RPLY_COLLABORATE]: true,
		[RPLY_EXT_FWD]: true,
		[RPLY_GRP_CHAT_COL]: true // TODO: answer-ed chat should allow internal chat?
	}, deadChatReplyOptions = {
		[RPLY_ERRAND]: true,
		[RPLY_COMMENT]: false,
		[RPLY_COLLABORATE]: true,
		[RPLY_EXT_FWD]: true,
		[RPLY_GRP_CHAT_COL]: true // TODO: dead-chat should allow internal chat?
	};

const replyFormTabOptions = [
	{id: RPLY_TAB_RECIPIENTS, label: I("Recipients"), icon: "icon-recipients"},
	{id: RPLY_TAB_ASSIST, label: I("Options"), icon: "icon-assist"},
	{id: RPLY_TAB_ATTACHMENT, label: I("Attach"), icon: "icon-attachment"}
	// VIEW_REPLY_PANEL.Recipient,
	// VIEW_REPLY_PANEL.KnowledgeBase
	// Subject:		{id: 4, label: I("Subject")}
];

const replyFormTabOptionsHideLabel = [
	{id: RPLY_TAB_RECIPIENTS, label: "", icon: "icon-recipients", tooltiptext: I("Recipients")},
	{id: RPLY_TAB_ASSIST, label: "", icon: "icon-assist", tooltiptext: I("Assist")},
	{id: RPLY_TAB_ATTACHMENT, label: "", icon: "icon-attachment", tooltiptext: I("Attach")}
	// VIEW_REPLY_PANEL.Recipient,
	// VIEW_REPLY_PANEL.KnowledgeBase
	// Subject:		{id: 4, label: I("Subject")}
];

class ReplyFormOptions extends React.PureComponent {
	constructor(props){
		super(props);

		this.state = {
			hideNav: false,
			openKnowledgeBaseState: false
		};
	}

	componentDidMount() {
		window.addEventListener("resize", this.resize.bind(this));
		this.resize();
	}

	resize() {
		let currentHideNav = (window.innerWidth <= 1280);
		if (currentHideNav !== this.state.hideNav) {
			this.setState({hideNav: currentHideNav});
		}
	}

	componentWillUnmount() {
		window.removeEventListener("resize", this.resize.bind(this));
	}

	handleSelectedTab = (id) => {
		//if clicked tab that is already active, then we unselect them
		if(id === this.props.selectedTab) {
			return this.props.onSelectTab(RPLY_TAB_NO_SELECT);
		}
		if (id === RPLY_TAB_RECIPIENTS){
			this.props.onToggleRecipients(true);
			this.props.onSelectTab(id)
		}
		if (id === RPLY_TAB_ASSIST){
			this.props.onToggleReplyAssist(true);
			this.props.onSelectTab(id)
		}
		if (id === RPLY_TAB_ATTACHMENT){
			if(this.props.showUploadAttachmentBox === false){
				this.props.toggleUploadAttachment(true);
			}
			this.props.onSelectTab(id)
		}
	}
	handleBoxClose = () => {
		if(this.props.showUploadAttachmentBox === false){
			this.props.toggleUploadAttachment(true);
		}
	}
	handleActionToggling = () => {
		this.props.onShowArchiveAttach(this.props.showArchiveDD);
	}
	handlePreviewClick = ()=>  {
		this.props.onClickPreview(this.props.currentReply);
	}
	handleToggleKnowledgeBase = () => {
		if(cflag.IsActive("2024-02-20.CEN-1713.new-knowledgebase-gui") && cflag.IsActive("2024-05-23.CEN-2090.new-errand-knowledgebase-gui")){
			if(this.props.onToggleKBPanel){
				this.props.onToggleKBPanel(true);
			}
		} else {
			this.props.onToggleKnowledgeBase(!this.props.openKnowledgeBase);
		}
	}
	handleToggleRewriteAnswer = (openState) => {
		let { areaCustomAIInstructions, onToggleRewritePanelAnswer, answer, areaId, haveAIAnswerHistory, newAIContext, AISession } = this.props;
		//note: this is a hack to remove all the new lines and spaces from the answer
		//see how ppl talk about it.
		//answer = answer.replace((/  |\r\n|\n|\r/gm),"");

		let hvCustomIns = false;
		if(areaCustomAIInstructions.customAIInstructions != "" || areaCustomAIInstructions.customAIResponse != ""){
			hvCustomIns = true;
		}
		onToggleRewritePanelAnswer(true, answer, areaId, hvCustomIns, haveAIAnswerHistory, false, newAIContext, AISession, this.props.errandId);
	}
	handleSelectToLang = (lang) => {
		if (typeof this.props.onSelectedToLang === 'function') {
			this.props.onSelectedToLang(lang, this.props.currentRoot);
		}
	}
	handleContentRect = contentRect => {
		if(this.props.onResize) {
			this.props.onResize(contentRect.bounds.height);
		}
	}
	onAppendSuggestedAnswer(id, obj) {
		this.props.appendSuggestedAnswer(obj.answer, obj.attachments, this.props.currentSelected);
	}
	render () {
		const cflagAttachment = cflag.IsActive("2023-08-31.CEN-1440.new.errand.attachment.design");
		const {
			availableReplyPanel
			, className
			, hideInternalCollaborators
			, selectedTab
			, openKnowledgeBase
			, openRewriteAnswerBase
			, hideReplyPanelText
			, suggestedAnswers
			, ...p
		} = this.props;
		const {
			hideNav,
			openKnowledgeBaseState
		} = this.state;

		let noTabSelected = false;
		if (selectedTab === RPLY_TAB_NO_SELECT || (p.currentChannel === RC_SMS && selectedTab === RPLY_TAB_ATTACHMENT)) {
			noTabSelected = true;
		};
		let activator = (v) => this.props.selectedTab === v;
		let extendingOptions = (
			<div
				className={classNames(
					"tabs-option extending-option",
					{ "collapse": noTabSelected }
				)}
			>
				<EmailRecipients
					hideInternalCollaborators={hideInternalCollaborators}
					replyAs={p.currentReply}
					onRecipientsChange={p.onRecipientsChange}
					currentChannel={p.currentChannel}
					show={selectedTab === RPLY_TAB_RECIPIENTS}
					selectedAgents={p.selectedAgents}
					selectedAreas={p.selectedAreas}
					areas={p.areas}
					onToggleContactBook={p.onToggleContactBook}
					onClearCollaborators={p.onClearCollaborators}
					onSetAreaRecipient={p.onSetAreaRecipient}
					emailRecipients={p.emailRecipients}
					recipientsOptions={p.recipientsOptions}
					verticalView={p.verticalView}
					mobileView={p.mobileView}
					tags={p.tags}
				/>
				<ReplyHeaderCtnr
					show={selectedTab === RPLY_TAB_ASSIST}
					knowledgeBase={p.knowledgeBase}
				/>
				<HideableDiv
					className="extending-option-attachment"
					hidden={isChatAndCollaborateInternalChat(p.chat, p.currentReply) ||
						selectedTab !== RPLY_TAB_ATTACHMENT || p.currentChannel == RC_SMS
					}
				>
					<EditorFootnotes {...p} />
				</HideableDiv>
			</div>
		);
		let previewDisabledClass = p.previewDisabled ? "disabled" : "";
		let formTabs = <div className="tabs-option tab-buttons-row">
				<div className="tabs-button-wrapper">
				{availableReplyPanel.map((panel, i) => {
					if(p.currentChannel === RC_SMS){
						if(panel.id == RPLY_TAB_ATTACHMENT)
						 	return null
					}
					return (
						<OneTab
							key={i}
							active={!noTabSelected && activator(panel.id)}
							id={panel.id}
							onClick={this.handleSelectedTab}
							data-qa-id={"reply-form-option"+i}
							text={panel.label}
							icon={panel.icon}
						/>
					)
				})}
				<WrapIcon icon="icon-knowledgebase" className="toggle-tabs knowledgebase">
					<KnowledgeBaseAssist
						open={openKnowledgeBase}
						onToggle={this.handleToggleKnowledgeBase}
					/>
				</WrapIcon>
				<WrapIcon icon="icon-preview" className={"toggle-tabs preview "+previewDisabledClass} hidden={p.currentReply == RPLY_COLLABORATE}>
					<PreviewAssist
						className="tabs-preview"
						disabled={p.previewDisabled}
						onClick={this.handlePreviewClick}
					/>
				</WrapIcon>
				{
				(features["machine-learning.auto-answer"] ||
					(features["amazon-comprehend"] && features["machine-learning.suggest-answer"])) &&
					<WrapIcon icon="icon-chatbot-old" className="toggle-tabs suggestion errandTooltip">
						<SuggestionAssist
							data={suggestedAnswers}
							onAppendItem={this.onAppendSuggestedAnswer}
						/>
					</WrapIcon>
				}
				<WrapIcon icon="icon-spellchecker" className="toggle-tabs spellcheck">
					<Spellchecker
						id={"cke_"+p.editorId}
						preferredLang={p.preferredSpellcheckLang}
						langs={p.spellcheckLangs}
						editorId={p.editorId}
					/>
				</WrapIcon>
				{p.chat ? null : // TODO: related collaboration?
				<WrapIcon
					icon="icon-translate"
					className="toggle-tabs translate"
					hidden={!p.canTranslate}
				>
					<ErrandTranslation
						text={I("Translation options")}
						className="errand-message-translate"
						toLang={p.toLang}
						disabled={p.disabled}
						onSelectedToLang={this.handleSelectToLang}
					/>
				</WrapIcon>
				}
				</div>
			</div>

		let hideTabs = <div className="tabs-option tab-buttons-row">
				<div className="tabs-button-wrapper">
				{hideReplyPanelText.map((panel, i) => {
					if(p.currentChannel == RC_SMS){
						if(panel.id == RPLY_TAB_ATTACHMENT)
						 	return null
					}
					return (
						<OneTabWithTooltip
							key={i}
							active={!noTabSelected && activator(panel.id)}
							id={panel.id}
							onClick={this.handleSelectedTab}
							data-qa-id={"reply-form-option"+i}
							text={panel.label}
							icon={panel.icon}
							tooltiptext={panel.tooltiptext}
						/>
					)
				})}
				<WrapIcon icon="icon-knowledgebase" className="toggle-tabs knowledgebase errandTooltip" onClick={this.handleToggleKnowledgeBase}>
					<KnowledgeBaseAssistHideText
						open={openKnowledgeBase}
						onToggle={this.handleToggleKnowledgeBase}
					/>
					<span className="tooltiptext">{I("Knowledge base")}</span>
				</WrapIcon>
				<WrapIcon icon="icon-preview" className={"toggle-tabs preview "+previewDisabledClass+" errandTooltip"} onClick={this.handlePreviewClick} hidden={p.currentReply == RPLY_COLLABORATE}>
					<PreviewAssistHideText
						className="tabs-preview"
						disabled={p.previewDisabled}
						onClick={this.handlePreviewClick}
					/>
					<span className="tooltiptext">{I("Preview")}</span>
				</WrapIcon>
				{
				(features["machine-learning.auto-answer"] ||
					(features["amazon-comprehend"] && features["machine-learning.suggest-answer"])) &&
					<WrapIcon icon="icon-chatbot-old" className="toggle-tabs suggestion errandTooltip">
						<SuggestionAssist
							data={suggestedAnswers}
							onAppendItem={this.onAppendSuggestedAnswer}
						/>
						<span className="tooltiptext">{I("Suggestion")}</span>
					</WrapIcon>
				}
				<WrapIcon icon="icon-spellchecker" className="toggle-tabs spellcheck">
					<Spellchecker
						id={"cke_"+p.editorId}
						preferredLang={p.preferredSpellcheckLang}
						langs={p.spellcheckLangs}
						editorId={p.editorId}
					/>
				</WrapIcon>
				{p.chat ? null : // TODO: related collaboration?
				<WrapIcon
					icon="icon-translate"
					className="toggle-tabs translate"
					hidden={!p.canTranslate}
				>
					<ErrandTranslation
						text={I("Translation")}
						className="errand-message-translate"
						toLang={p.toLang}
						disabled={p.disabled}
						onSelectedToLang={this.handleSelectToLang}
					/>
				</WrapIcon>
				}
				</div>
			</div>


		if(p.currentReply == RPLY_COMMENT || p.currentReply == RPLY_QUESTION) {
			formTabs = null;
			extendingOptions = null;
			if(p.currentReply == RPLY_COMMENT ) {
				if(cflagAttachment) {
					formTabs = <div className="tabs-option single-row internal-msg">
									<EditorFootnotes {...p} />
									<Spellchecker
										id={"cke_"+p.editorId}
										preferredLang={p.preferredSpellcheckLang}
										langs={p.spellcheckLangs}
										editorId={p.editorId}
									/>
								</div>
				} else {
				formTabs = <div className="tabs-option single-row internal-msg">
									<div className="reply-label">{I("ATTACH FILE")}:</div>
									<EditorFootnotes {...p} />
									<Spellchecker
										id={"cke_"+p.editorId}
										preferredLang={p.preferredSpellcheckLang}
										langs={p.spellcheckLangs}
										editorId={p.editorId}
									/>
								</div>
				}
			}
		}
		return (
			<Measure
				bounds
				client={true}
				onResize={this.handleContentRect}
			>
			{({ measureRef }) => (
				<div className="reply-tabs" ref={measureRef}>
					{!p.chat || chatHasExternalCollaboration(p.currentReply) ?
						<div className="tabs-option-container">
							{extendingOptions}
							{!hideNav &&
							formTabs}
							{hideNav &&
							hideTabs}
						</div>
						: p.children
					}
				</div>
			)}
			</Measure>
		)
	}
}

const ReplyEditorToolbar = ({ hideToolbar, replyAs, ...p }) => (
	<div
		className={classNames(
			"c3-toolbar-wrapper",
			{ closed: !p.showReplyToolbar }
		)}
	>
		<ReplyToolbarCtnr
			className={p.className}
			collabOpts={p.collabOpts}
			enableAction={p.enableAction}
			hideToolbar={hideToolbar}
			onSelectAction={p.onSelectAction}
			options={p.options}
			replyAs={replyAs}
			show={p.show}
			showChannelOpt={p.showChannelOpt}
			showSpellcheck={p.showSpellcheck}
			toolbarID={p.toolbarID}
			editSubject={p.editSubject}
		/>
		<ToolbarButtonIcon
			active={!p.hideSubjectbar}
			hidden={p.isChat}
			onClick={p.onToggleEditorToolbar}
		/>
	</div>
)

const EditorWrapper = ({ use, wrapper, children }) =>
	use ? wrapper(children) : children;

const openErrandAttachedProps = props => {
	// NOTE: any extra fields added here must reflect on
	// openErrandExtraFieldsMemoize too.
	return {
		context: props.currentContext
		, mobile: props.ui.showMobileView
	};
};

export class ReplyForm extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			hideSubjectbar: true,
			replyBtnHeight: 0,
			footerCbsHeight: 0,
			replyTabOptHeight: 0,
			selectedText: '',
			showAgentAssistButton: false,
			agentAssistBtnTop: 0,
			agentAssistBtnLeft: 0,
		};
		this.handleAnswerSubject = this.handleAnswerSubject.bind(this);
		this.handleAttachmentDelete = this.handleAttachmentDelete.bind(this);
		this.handleCkeditorChange = this.handleCkeditorChange.bind(this);
		this.handleLibraryClick = this.handleLibraryClick.bind(this);
		this.handleDragnDropFile = this.handleDragnDropFile.bind(this);
		this.handleKeyDown = this.handleKeyDown.bind(this);
		this.handleSelectedViewPanel = this.handleSelectedViewPanel.bind(this);
		this.handleRecordEditorSize = this.handleRecordEditorSize.bind(this);
		this.handleResizeFooterBtns = this.handleResizeFooterBtns.bind(this);
		this.handleResizeReplyBtns = this.handleResizeReplyBtns.bind(this);
		this.handleTabOptResize =  this.handleTabOptResize.bind(this);
	}
	handleAnswerSubject(e) {
		this.props.onAnswerSubjectChange(e.target.value);
	}
	handleAttachmentDelete(type, id, index, e, fileName) {
		this.props.onAttachmentDelete(this.props.currentReply, type, id, fileName);
	}
	handleCkeditorChange(e, reformat) {
		const cke = e.editor;
		if(this.props.currentReply == RPLY_QUESTION){
			if(cke) {
				this.props.onQuestionChange(cke.getData(),
				cke.document.getBody().getText(), reformat);
			}
		} else {
			let p = this.props;
			let updateManualCall = false;
			if(p.sipMakeCallCurrentErrand) {
				updateManualCall = true;
			}
			// TODO: why Object.entries is used? It likely can impact this highly
			// performance sensentive callback event.
			if(Object.entries(cke.document.getBody()).length !== 0){
				let ckText = cke.document.getBody().getText();
				p.onAnswerChange(cke.getData(),
					ckText, reformat, updateManualCall);
				let twtChInfo = p.twtChannelInfo;
				if(twtChInfo.service === Workflow.Errand.SERVICE_TWITTER && !twtChInfo.isTwtPm){
					let tweetContent = `@${p.errandFrom} `;
					if(typeof ckText !== 'undefined') {
						tweetContent += this.getPreferences(p.preference.salContent);
						tweetContent += ckText;
						tweetContent += this.getPreferences(p.preference.sigContent);
						let totalLength = tweetContent.length;
						if(totalLength > TWITTERTWEETLIMIT) {
							let numofTweet = Math.ceil(totalLength / TWITTERTWEETLIMIT);
							p.showTweetWarning(true, numofTweet);
						}
					}
				}else {
					if(p.shouldShowTweetWarning)
						p.showTweetWarning(false, 0);
				}
				if (ckText === "/"){
					this.props.onToggleSearchShortcutDropdown(true);
				} else if (ckText.trim() === ""){
					this.props.onToggleSearchShortcutDropdown(false);
				}
			}
		}
	}
	getPreferences(pCnts) {
		let pc = "";
		if (typeof pCnts !== 'undefined' && pCnts.length > 0) {
			pc = pCnts[0].replace(/<[^>]+>/g, '');
			return pc;
		}
		return pc;
	}
	handleLibraryClick() {
	}
	handleSelectText = (text, position, invalid) => {
		const cleanedSelection = text.replace(/[\n\r]/g, '');
		if(cleanedSelection.length > 0){
			this.setState({
				selectedText: cleanedSelection,
				showAgentAssistButton: true,
				agentAssistBtnTop: position.top,
				agentAssistBtnLeft: position.left
			});
		} else {
			this.setState({
				selectedText: "",
				showAgentAssistButton: false
			});
		}
		if(invalid) {
			//no valid range, hide agent assist shortcut
			this.setState({
				showAgentAssistButton: false
			});
		}
	}
	handleEditorFocus = () => {
		this.setState({
			selectedText: "",
			showAgentAssistButton: false
		});
	}
	handleOpenAgentAssist = () => {
		if(this.props.onToggleRewritePanelAnswer) {
			const defaultAction = "proof-read";
			this.props.onToggleRewritePanelAnswer(true, this.state.selectedText,
				this.props.areaId, false, false, false, '', '',
				this.props.errandId, defaultAction);
		}
	}
	handleOpenAgentAssistSum = () => {
		if(this.props.onToggleRewritePanelAnswer) {
			const defaultAction = "summarize";
			this.props.onToggleRewritePanelAnswer(true, this.state.selectedText,
				this.props.areaId, false, false, false, '', '',
				this.props.errandId, defaultAction);
		}
	}
	handleRecordEditorSize(height) {
		this.props.onRecordEditorSizes({answer: height});
	}
	handleDragnDropFile(fileData, fileInfo) {
		if(this.props.currentReply !== RPLY_COMMENT){
			if (this.props.onDragnDropFile) {
				return this.props.onDragnDropFile(
					this.props.currentReply
					, this.props.chat
					, fileData
					, fileInfo
				);
			}
		}
	}
	handleKeyDown() {
	}
	handleSelectedViewPanel(ids) {
		const p = this.props;

		if (ids.includes(VIEW_REPLY_PANEL.KnowledgeBase.id)){
			p.onToggleReplyAssist(!p.showReplyAssist);
		}

		if (ids.includes(VIEW_REPLY_PANEL.Recipient.id)){
			p.onToggleRecipients(!p.showRecipients);
		}

		if (ids.includes(VIEW_REPLY_PANEL.Toolbar.id)){
			p.onToggleReplyToolbar(!p.showReplyToolbar);
		}

		if (ids.includes(VIEW_REPLY_PANEL.Subject.id)){
			p.onToggleSubject(!p.showSubject)
		}
	}
	handleSelectAction = (action, channel) =>{
		this.props.onSelectAction(this.props.errandId, action, channel);
	}
	handleMediaRating = () =>{
		this.props.socialMediaUI(this.props.showRatingPopup, 'ratings');
	}
	handleMediaReaction = () =>{
		this.props.socialMediaUI(this.props.showReactionPopup, 'reactions');
	}
	handleToggle = () =>{
		this.props.socialMediaUI(this.props.showSendPMPopup, 'sendpm');
	}
	handleToggleAns = () =>{
		this.props.socialMediaUI(this.props.showUpdateAnswerPopup, 'updateans');
	}
	handleToggleEditorToolbar = () => {
		this.props.onToggleReplyToolbar(!this.props.showReplyToolbar);
	}
	handleSend = (type) =>{
		this.props.handleSendMessage(this.props.errandId, type, this.props.channels[0]);
	}
	handleOnChangeMsg = (id, msg) =>{
		this.props.handleOnChangeMsg(id, msg, this.props.channels[0]);
	}
	handleToggleTempl = () =>{
		this.props.handleWATemplToggle(this.props.showWATemplatePopup);
	}
	handleFormSubmit = (e) => {
		 e.preventDefault();
	}
	handleMobileOpen = () => {
		const p = this.props;
		p.onToggleErrandOptionMobile(true);
		//to always show Assist Options on mobile to avoid blank Options panel
		if(p.showReplyAssist === false) {
			p.onToggleReplyAssist(!p.showReplyAssist);
		}
	}
	handleMobileClose = () => {
		this.props.onToggleErrandOptionMobile(false);
	}
	handleToggleRecipients = () => {
		const p = this.props;
		if(p.showRecipients === false) {
			p.onToggleRecipients(!p.showRecipients);
		}
		this.setState({hideSubjectbar: !this.state.hideSubjectbar});
	}
	handleResizeFooterBtns(height) {
		this.setState({ footerCbsHeight: height });
	}
	handleResizeReplyBtns(height) {
		this.setState({ replyBtnHeight: height });
	}
	handleTabOptResize(height) {
		this.setState({ replyTabOptHeight: height });
	}
	handleSearchButton = (e) => {
		if(this.props.disableActiveSearch == true){
			this.props.handleSearchButton(e, this.props.searchText);
		}
	}
	handleKeyUpSearchBox = (e) => {
		if(this.props.disableActiveSearch == false ||
			(this.props.disableActiveSearch == true && e.key == "Enter")){
			this.props.handleKeyUpSearchBox(e, this.props.disableActiveSearch);
		}
	}
	handleErrandClick = (id, isChat, cipherKey) => {
		this.props.onCloseErrandChatSidePanel(false);
		this.props.onHideReplySearch(false);
		if (this.props.currentContext === CTX_REVIEW) {
			return this.props.onClickReview(id, cipherKey);
		}
		this.props.onSetCurrentErrand(
			id
			, isChat
			, openErrandAttachedProps(this.props)
			, this.props.isSearch
		);
	}
	handleSelectOneErrand = (id, select) => {
		this.props.onSelectErrandFromList(id, select);
	}
	handleHideReplySearch = () => {
		let p = this.props;
		if(p.openSearchShortcut){
			p.onHideReplySearch(!p.openSearchShortcut);
		}else if(p.openSearchInput){
			p.onHideReplySearch(!p.openSearchInput);
		}
	}
	handleInsert = (code, templCont) =>{
		const editor = CKEDITOR.instances["chatEditor"];
		if( editor && this.props.chat ){
			console.log("dbg: whatsapp template = " + code);
			editor.setData(templCont);
			editor.setReadOnly(true);
			let strCode = JSON.stringify(code);
			this.props.saveWaTemplateCode( strCode );
		}else{
			this.props.appendTemplate(templCont);
		}
	}
	handleOnInsertVar = () =>{}
	render() {
		const {
			hideFooterCheckboxes
			, insertionText
			, onResetInsertionText
			, mobileView
			, showUploadAttachmentBox
			, onAttachmentDelete
			, onReplyButtonsWidthChange
			, dynamicHeight
			, dynamicWidth
			, wfs
			, editSubject
			, answer
			, plainAnswer
			, availableReplyPanel
			, selectedReplyPanel
			, ...p
		} = this.props
			, toolbarID = 'ckeditorTop'
			, chID = p.currentChannel + '-reply'
			, ckConf = p.ckEditorBox
			;
		// TODO: does internal collaboration make sense in chat? As chat has group
		// chat internal collaboration.
		const hideInternalCollaborators = isChatAndCollaboration(
			p.chat,
			p.currentReply
		)
		let id = replyOptions[p.currentReply].id, fontFamily = ckConf.fontFamily, fontSize = ckConf.fontSize, langs = ckConf.languageSrc,
			areaArchive = p.archiveImgs, replyFormClass, isErrand, isQuestion,
			isExtFwd, isCollaborate, isVoice = false,
			isComment, hide, hideSubjectbar = p.hideSubjectbar, isChatSummary = false,
			subjectDOM = null, ckeClass, bgColor, txtColor = "", hideSalPreview = true, hideSigPreview = true;

		let height = DEFAULT_CKEDITOR_HEIGHT;
		if(this.props.preferredAnswerBoxHeight){
			height = this.props.preferredAnswerBoxHeight;
		}
		switch(p.currentReply) {
			case RPLY_ERRAND:
				isErrand = true;
				break;
			case RPLY_QUESTION:
				isQuestion = true;
				break;
			case RPLY_EXT_FWD:
				isExtFwd = true;
				break;
			case RPLY_COLLABORATE:
				isCollaborate = true;
				break;
			case RPLY_CHAT_SUMMARY:
				isChatSummary = true;
			default:
				isComment = true;
		}

		//fixme @sue. use constants
		if(isCollaborate) {
			ckeClass = 'collaboration-text-area';
			bgColor = CKEDITOR_BG_COLLAB;
		}
		if(isComment) {
			ckeClass = 'internal-comment-text-area';
			bgColor = CKEDITOR_BG_COMMENT;
		}
		if(isChatSummary) {
			ckeClass = 'internal-comment-text-area';
		}
		//For dark theme
		if(localStorage.getItem('theme')=="true"){
			bgColor = CKEDITOR_DARK_BG;
			txtColor = CKEDITOR_DARK_TXT_COLOR;
			if(isCollaborate) {
				bgColor = CKEDITOR_DARK_BG_COLLAB;
				txtColor = CKEDITOR_DARK_TXT_COLLAB;
			} else if(isComment) {
				bgColor = CKEDITOR_DARK_BG_COMMENT;
				txtColor = CKEDITOR_DARK_TXT_COMMENT;
			}
		}
		if(p.show) {
			replyFormClass = 'reply-form opened';
		} else {
			replyFormClass = 'reply-form closed';
		}
		if (!mobileView) {
			//lock reply form on vertical view
			if(p.verticalView && !p.chat) {
				replyFormClass = 'reply-form opened';
			}
		}
		let hideForSms = false
			, hideAttachmentLink
			;
		if (p.currentChannel == RC_SMS || isQuestion) {
			hideForSms = true;
			hideAttachmentLink = true;
		}
		if(p.currentChannel === 'voice'){
			isVoice = true;
		}
		const isEmailCh = isErrand &&
			(p.currentChannel === 'email' || p.currentChannel === 'sms');
		const notChatOrChatCollaborating = notChatOrChatInCollborate(
			p.chat,
			p.currentReply
		);
		const emailType = notChatOrChatCollaborating &&
			(isErrand || isQuestion || isExtFwd || isCollaborate || isComment);
		const simple = (!isEmailCh && !isQuestion && !isExtFwd && !isCollaborate && !isComment) || hideForSms;
		let errandSubject = removePM(p.subject);

		if (mobileView) {
			hideSubjectbar = this.state.hideSubjectbar;
		}
		let showSubjectDOM = false, showToggleReplyOptOnly = false;
		let currentChannel = p.currentChannel;
		if (notChatOrChatCollaborating &&
			p.show &&
			(isEmailCh || isExtFwd || isCollaborate || isVoice)) {
			showSubjectDOM = true;
			if (isCollaborate){
				currentChannel = p.selectedCollabChannel;
			}
			if (currentChannel === 'sms') {
				showToggleReplyOptOnly = true;
			}
		} else {
			showToggleReplyOptOnly = true;
		}
		let showRecipientPanel = false;
		if(p.showRecipients && !isVoice){
			showRecipientPanel = true;
		}else{
			if(isVoice) {
				showToggleReplyOptOnly = true;
				if(isCollaborate) {
					showRecipientPanel = p.showRecipients;
					showToggleReplyOptOnly = false;
				}else if(isComment || isQuestion || isChatSummary){
					hideSubjectbar = true;
				}
			}
		}
		if (showSubjectDOM) {
			subjectDOM = <SimpleSubjectBar
				showSubject={true}
				verticalView={p.verticalView}
				onSubjectChange={this.handleAnswerSubject}
				subject={errandSubject}
				editSubject={editSubject}
			/>
		}

		if(this.props.selectedSig > 0){
			hideSigPreview = false;
		}
		if(this.props.selectedSal > 0){
			hideSalPreview = false;
		}

		let showReplyAssist = (isErrand || isExtFwd || isCollaborate) && p.showReplyAssist
		let isOwner, joined;
		let isChatAndCollaborate;
		if (p.chat) {
			if (chatHasExternalCollaboration(p.currentReply)) {
				isChatAndCollaborate = true;
			} else {
				showReplyAssist = false;
			}
			isOwner = p.chat.errand.data.agentId == initialChatData.agentID;
			joined = p.chat.Role == CHAT_crGuest;
			replyFormClass += ' chat';
		}
		let defaultContent = answer;
		if(isQuestion){
			defaultContent = p.question;
		}
		if(isComment) {
			defaultContent = p.comment;
		}
		let channelInfo = p.twtChannelInfo;
		if (typeof channelInfo !== 'undefined'
			&& (channelInfo.service === Workflow.Errand.SERVICE_YOUTUBE || channelInfo.service === Workflow.Errand.SERVICE_GOOGLEPLAY)) {
			if (!hideAttachmentLink) {
				hideAttachmentLink = true;
			}
		}
		let showRecipientBtn = true;
		switch(p.currentChecked) {
			case RPLY_QUESTION:
			case RPLY_COMMENT:
				showRecipientBtn = false;
				break;
		}
		let showChannelOpts = true;
		if(p.currentChannel === RC_EMAIL || p.currentChannel === RC_SLACK){
			showChannelOpts = false;
		} else {
			if(simple) {
				showChannelOpts = true;
			}else {
				showChannelOpts = false;
			}
		}
		const editorId = p.chat ? CHAT_CKEDITOR_ID : "ckeditorv5"
		let newReplyBox = <ReplyFormOptions
				availableReplyPanel={replyFormTabOptions}
				hideReplyPanelText={replyFormTabOptionsHideLabel}
				currentChannel={currentChannel}
				emailRecipients={p.emailRecipients}
				hideInternalCollaborators={hideInternalCollaborators}
				showAssist={showReplyAssist}
				showRecipients={showRecipientPanel}
				onAttachmentDelete={this.handleAttachmentDelete}
				selectedTab={p.selectedReplyTab}
				editorId={editorId}
				mobileView={mobileView}
				currentRoot={p.currentReply}
				showUploadAttachmentBox={true}
				onShowArchiveAttach={p.showArchiveAttch}
				onResize={this.handleTabOptResize}
				answer={stripHTML(answer)}
				suggestedAnswers={p.suggestedAnswers}
				appendSuggestedAnswer={p.appendSuggestedAnswer}
				{...p}
				>
				{(p.chat && !p.chat.dead && (isOwner || joined)) &&
					<ChatOptionsCtnr
						libraryShortcuts={p.errandKnowledgeBaseShortcuts}
						openKnowledgeBase={p.openKnowledgeBase}
						onToggleKnowledgeBase={p.onToggleKnowledgeBase}
						onToggleKBPanel={p.onToggleKBPanel}
						templates={p.templates}
						quickReplies={p.quickReplies}
						showUploadAttachmentBox={true} //show true on new UI
						onAttachmentDelete={this.handleAttachmentDelete}
						isOwner={isOwner}
						joined={joined}
						{...p}
					/>
				}
				</ReplyFormOptions>;
			const panelPaddings = 60, editorPaddings = 20;
			let flexyHeight = {
				height: "unset"
			}
			let offsetHeight = dynamicHeight;
			if(dynamicHeight && dynamicHeight > 0) {
				offsetHeight = dynamicHeight-panelPaddings;
				flexyHeight = {
					height: offsetHeight+"px"
				}
			}
			let editorMaxHeight = (offsetHeight-(this.state.replyTabOptHeight+this.state.footerCbsHeight+this.state.replyBtnHeight));
			const editorWrapperStyle = {maxHeight: (editorMaxHeight-editorPaddings)+"px", overflow: "auto"};
		const showEmailReplyToolbar = !p.chat && emailType && p.showReplyToolbar

		let agentAssistShortCutBtnStyle = {
			top: this.state.agentAssistBtnTop + 100,
			left: this.state.agentAssistBtnLeft,
		}
		if (p.verticalView) {
			agentAssistShortCutBtnStyle = {
				top: this.state.agentAssistBtnTop + 210,
				left: this.state.agentAssistBtnLeft,
			}
		}

		return <div className="errand-box-reply-forms" style={flexyHeight}>
				<div className={replyFormClass} id={id}>
				{newReplyBox}
					<div className="reply-editors">
						<div className={"reply-editor active-editor" + (p.chat ? " chat" : "")} id={chID}>
							<form onSubmit={this.handleFormSubmit}>
								<EditorWrapper
									use={p.verticalView}
									wrapper={children => <div style={editorWrapperStyle}>{children}</div>}
								>
									{subjectDOM}
									<ButtonIcon
										className={classNames("btn toggle-recipient", {"active" : !hideSubjectbar})}
										hidden={!mobileView || !showRecipientBtn}
										icon={"fas fa-align-left"}
										onClick={this.handleToggleRecipients}
										text={I("Recipients")}
										overflow={-30}
										data-qa-id={"mobile-btn-toggleRecipient"}
									/>
									<ModalPopup
										viewAs="list"
										id="ratings"
										show={p.showRatingPopup}
										dataSrc={p.ratings}
										isHeader={true}
										headerText={I("Review rating for facebook page")}
										isFooter={false}
										onToggle={this.handleMediaRating}
									/>
									<ModalPopup
										viewAs="emotlist"
										id="reactions"
										show={p.showReactionPopup}
										dataSrc={p.reactions}
										isHeader={true}
										headerText={I("Facebook post reactions for the errand")}
										isFooter={false}
										onToggle={this.handleMediaReaction}
									/>
									<ModalPopup
										viewAs="form"
										id="sendpm"
										show={p.showSendPMPopup}
										dataSrc={p.pms_body}
										isHeader={true}
										headerText={I("Send private message to ") + p.currentChannel}
										isFooter={true}
										onToggle={this.handleToggle}
										onSend={this.handleSend}
										onChange={this.handleOnChangeMsg}
									/>
									<ModalPopup
										viewAs="form"
										id="updateAnswer"
										show={p.showUpdateAnswerPopup}
										dataSrc={p.pms_body}
										isHeader={true}
										headerText={I("Send update answer to ") + p.currentChannel}
										isFooter={true}
										onToggle={this.handleToggleAns}
										onSend={this.handleSend}
										onChange={this.handleOnChangeMsg}
									/>
									<ModalPopup
										viewAs="template"
										id="waTemplateAnswer"
										show={p.showWATemplatePopup}
										dataSrc={p.templContent}
										templId={p.waTemplId}
										isHeader={true}
										headerText={I("Custom variable for WhatsApp template")}
										isFooter={true}
										onToggle={this.handleToggleTempl}
										onSend={this.handleInsert}
										onChange={this.handleOnInsertVar}
									/>
									<ChatToolbar
										className="toolbar"
										toolbarID="chatToolbar"
										collabOpts={p.collabOpts}
										options={p.channelOpts}
										replyAs={p.currentReply}
										attachmentSize={p.attachmentSize}
										chat={p.chat}
										currentReply={p.currentReply}
										showUploadAttachmentBox={showUploadAttachmentBox}
										toggleUploadAttachment={p.toggleUploadAttachment}
										involved={p.show &&
											p.chat &&
											(!p.chat.dead || isComment || isChatSummary) &&
											(isOwner || joined) &&
											!isGroupChatCollaborate(p.currentReply)
										}
										showReplyToolbar={p.showReplyToolbar}
										onToggleEditorToolbar={this.handleToggleEditorToolbar}
									/>
									{!p.chat && <ReplyEditorToolbar
										className="toolbar"
										collabOpts={p.collabOpts}
										// showChannelOpt={showChannelOpts}
										enableAction={!isCollaborate}
										hideSubjectbar={hideSubjectbar}
										hideToolbar={isChatAndCollaborate}
										isChat={p.chat}
										options={p.channelOpts}
										replyAs={p.currentReply}
										show={showEmailReplyToolbar}
										showChannelOpt={false}
										showSpellcheck={false}
										showReplyToolbar={p.showReplyToolbar}
										onSelectAction={this.handleSelectAction}
										toolbarID={toolbarID}
										onToggleEditorToolbar={this.handleToggleEditorToolbar}
										editSubject={editSubject}
									/>
									}
									<div
										dangerouslySetInnerHTML={{__html: p.preference.salContent}}
										className="signature-preview"
										hidden={isComment || isCollaborate || p.isChat || hideSalPreview || isChatSummary}
									/>
									{p.chat
									? <ChatInputCtnr
										currentReply={p.currentReply}
										onReplyButtonsWidthChange={onReplyButtonsWidthChange}
										toolbarID="chatToolbar"
										hidden={this.props.chat == null}
										hideSpellchecker={true}
										sendMessage={sendMessage}
										showReplyPanel={p.show}
									/>
									:
									<div>
										<HideableReplySearch
											onClick={this.handleErrandClick}
											onSelectOne={this.handleSelectOneErrand}
											onGetCollaborationInfo={p.onGetCollaborationInfo}
											collaborationInfo={p.collaborationInfo}
											canOffCollaborationLight={p.canOffCollaborationLight}
											onToggleLight={p.onToggleLight}
											tzOffset={wfs.agentTimezoneOffset}
											previewActivated={p.previewActivated}
											onLoadPreview={p.onLoadPreview}
											previewData={p.errandPreview}
											onChange={p.handleChangeSearchInput}
											toggleSearchInput={p.onToggleSearchInput}
											showShortcut={p.openSearchShortcut}
											showSearch={p.openSearchInput}
											searchResults={p.searchResults}
											searchData={p.searchList.list}
											totalSearchResult={p.totalSearchFiltered}
											searchOffset={p.searchOffset}
											searchText={p.searchText}
											showReplyResultBox={p.showReplyResultBox}
											isSearching={p.isSearching}
											onSearchButton={this.handleSearchButton}
											onKeyDown={p.handleKeyDownSearchBox}
											onKeyUp={this.handleKeyUpSearchBox}
											term={plainAnswer}
											clientAvatar={p.clientAvatar}
											currentOpened={p.currentOpened}
											showExactDayAndTime={p.showExactDayAndTime}
											onHide={this.handleHideReplySearch}
											height={p.verticalView ? editorMaxHeight : height}
										/>
									  <Ckeditor
										id='ckeditorv5'
										data-qa-id='QA_replyEditor'
										control={true}
										bgColor={bgColor}
										txtColor={txtColor}
										myClass={ckeClass}
										hide={p.chat ? true : !emailType}
										insertionText={insertionText}
										// TODO: may need to remove (comment out
										// following line) this if give agent
										// unpleasant typing blocking experience
										block={this.props.block}
										defaultFontFamily={fontFamily}
										toolbarID={toolbarID}
										defaultFontSize={fontSize}
										defaultContent={defaultContent}
										spellLanguages={langs}
										hideSpellchecker={true}
										tableTools={features['table-toolbar']}
										showResize={p.verticalView ? false : true}
										recordSize={true}
										resizeMinHeight={50}
										onRecordEditorSize={this.handleRecordEditorSize}
										onResetInsertionText={onResetInsertionText}
										height={p.verticalView ? editorMaxHeight : height}
										verticalView={p.verticalView}
										editorMaxHeight={editorMaxHeight}
										simpleToolbar={simple}
										fileArchiveImages={areaArchive}
										onKeydown={this.handleKeyDown}
										onChange={this.handleCkeditorChange}
										onDragnDropFiles={this.handleDragnDropFile}
										onLibraryClick={this.handleLibraryClick}
										onSelectText={this.handleSelectText}
										onFocus={this.handleEditorFocus}
										>
										{features["openai-rewrite-answer"] && this.state.showAgentAssistButton && (
											<ButtonsGroup
											customStyle={agentAssistShortCutBtnStyle}
											onClickPr={this.handleOpenAgentAssist}
											onClickSum={this.handleOpenAgentAssistSum}
										  />
										)}
										</Ckeditor>
									</div>
									}
									<div
										dangerouslySetInnerHTML={{__html: p.preference.sigContent}}
										className="signature-preview"
										hidden={isComment || isChatSummary ||
											!notChatOrChatCollaborating ||
											!p.show ||
											hideSigPreview
										}
									/>
								</EditorWrapper>
								{(p.chat && !p.chat.dead && p.currentReply === RPLY_ERRAND) ?
									<ChatAttachment
										dispatch={p.dispatch}
										chat={p.chat}
										currentReply={p.currentReply}
										showReplyPanel={p.show}
									/>
									: null
								}
								{isChatAndCollaborateInternalChat(p.chat, p.currentReply) &&
									(isOwner || joined) &&
									<AddAgentCtnr // when on chat collab reply
										error={p.chat.addAgent.error}
										list={p.chat.addAgent.list}
										sessionId={p.chat.sessionId}
										agentIds={p.chat.AgentIds ? p.chat.AgentIds.concat(p.chat.InvitedAgentIds) : []}
										ownerId={p.chat.errand.data.agentId}
										agentPresence={this.props.agentStore}
									/>
								}
								{notChatOrChatCollaborating &&
									p.show &&
										<FooterCheckboxes
											attachmentSize={p.attachmentSize}
											chat={p.chat}
											checkboxes={p.replyCheckboxes}
											checkedStates={p.replyCheckboxStates}
											currentRoot={p.currentReply}
											onCheckedStateChange={p.onCheckedStateChange}
											onClick={p.onReplyCheckboxesClick}
											showUploadAttachmentBox={showUploadAttachmentBox}
											hideCheckboxes={hideFooterCheckboxes}
											hideAttachmentLink={!!hideAttachmentLink}
											toggleUploadAttachment={p.toggleUploadAttachment}
											canTranslate={p.canTranslate}
											toLang={p.toLang}
											onSelectedToLang={p.onSelectedToLang}
											availableReplyPanel={availableReplyPanel}
											selectedReplyPanel={selectedReplyPanel}
											onSelectedViewPanel={this.handleSelectedViewPanel}
											showSpellcheck={true}
											editorId={editorId}
											preferredSpellcheckLang={p.preferredSpellcheckLang}
											spellcheckLangs={p.spellcheckLangs}
											check={p.check}
											showAttachment={false}
											verticalView={p.verticalView}
											offsetBottom={this.props.navTabsHeight+this.state.replyBtnHeight+10} //10 for extra padding
											onResize={this.handleResizeFooterBtns}
										/>
								}
								{p.chat ? null :
								<ReplyButtons
									fixedWidth={'35%'}
									onButtonClick={p.onButtonClick}
									timezone={p.timezone}
									savedTimestamp={p.currentReply == RPLY_ERRAND ? p.lastSavedTimestamp : p.lastSavedTimestampEE}
									condition={p.buttonCondition}
									currentReply={p.currentReply}
									showTweetWarn={p.shouldShowTweetWarning}
									numberOfTweet={p.numberOfTweet}
									agentWorking={p.agentWorking}
									dynamicWidth={dynamicWidth}
									verticalView={p.verticalView}
									navTabsHeight={p.navTabsHeight}
									onResize={this.handleResizeReplyBtns}
									currentChannel={this.props.currentChannel}
									answer={removeHTMLTags(answer)}
									shortUCS2={wfs.sms_ucs2_use_67_bytes}
								/>
								}
							</form>
						</div>
					</div>
					{/*errand mobile options trigger button*/}
					{mobileView &&
						<ButtonIcon
							className="btn-grey toggle-errandbox-mobile-options"
							data-qa-id={"mobile-btn-toggleErrandOptions"}
							onClick={this.handleMobileOpen}
							icon="icon-spread"
							text={I("Print errand")}
							overflow={25}
							noTooltip={true}
						/>
					}
				</div>
			</div>;
	}
};

class MeasurableInfoTags extends React.Component {
	state = {
		dimensions: {
			width: -1,
			height: -1
		}
	}

	render() {
		const { width, height } = this.state.dimensions;
		const { items } = this.props;

		let tagWidth = 60; //add to constant
		let containerCapacity = (width - SHOW_MORE_TAGS_WIDTH); // minus additional one to allocate space for "show more"
		let fittedTagsCount = 0;
		let fittedTags = [];
		let remainingTags = Array.from(items); //avoid imutability

		$.each(items, (i,v) =>{
			containerCapacity -= tagWidth;
			if(containerCapacity > 0) {
				fittedTagsCount ++;
				fittedTags.push(v);
				remainingTags.shift();
			}
		});

		let remainingTagsCount = items.length - fittedTagsCount;

		return (
			<Measure
				bounds
				onResize={contentRect => {
				this.setState({ dimensions: contentRect.bounds })
				}}
			>
			{({ measureRef }) => (
				<div ref={measureRef} className={"errand-tags"}>
					{fittedTags}
					<MoreDisplayIDs tags={remainingTags} />
				</div>
			)}
			</Measure>
		)
	}
}

const AssociatedErrandsInfoBase = ({
	className,
	'data-qa-id': dataQAId,
	ids,
	items
}) => (
	<li title={ids.join(', ')} className={className} data-qa-id={dataQAId}>
		<MeasurableInfoTags items={items} />
	</li>
)

const showAddMoreOthersWhenMinimizedReply = false

const AssociatedErrandsInfo = composeWithDisplayName(
	'AssociatedErrandsInfo',
	withUnmountWhenHidden,
	withAssociatedErrandItems
)(AssociatedErrandsInfoBase)

const ReplyNavInfoBase = ({
	className,
	id,
	info,
	showReplyPanel,
	type,
	onClick,
	onOpenAcquiredErrand
}) => {
	switch (typeof info) {
		case 'object':
			return (
				<AssociatedErrandsInfo
					associatedErrands={info}
					className={className}
					hidden={showAddMoreOthersWhenMinimizedReply ||
						showReplyPanel
					}
					onClick={onClick}
					onOpen={onOpenAcquiredErrand}
				/>
			)
		default:
			return (
				<li className={className} data-qa-id={id}>
					<EllipsisText text={info} />
				</li>
			)
	}
}

const ReplyNavInfo = memo(ReplyNavInfoBase)

const ReplyNavWrapperBase = ({
	children,
	dynamicWidth,
	measureRef,
	showReplyPanel
}) => (
	<div
		className={classNames("block-reply-nav", { showReply: showReplyPanel })}
		style={useMemo(() => {
			if (typeof dynamicWidth === 'number') {
				if (dynamicWidth && dynamicWidth > 0) {
					return { width: dynamicWidth + "px" }
				} else if (dynamicWidth < 0) {
					return { width: `calc(100% - ${dynamicWidth*-1}px)` }
				}
			}
			return { width: '100%' }
		}, [dynamicWidth])}
		ref={measureRef}
	>
		{children}
	</div>
)

const ReplyNavWrapper = withUnmountWhenHidden(ReplyNavWrapperBase)

export class ReplyNav extends React.PureComponent {
	constructor (props) {
		super(props);
		this.handleResize = this.handleResize.bind(this);
	}
	handleResize(height) {
		if(this.props.onResize) {
			this.props.onResize(height);
		}
	}
	render() {
		let {
			state
			, currentReply
			, info
			, onClickInfo
			, onSelectReply
			, onDefaultReply
			, onOpenAcquiredErrand
			, chat
			, showReplyPanel
			, dynamicWidth
			, mobileView
			, onToggleChatNavDD
			, showChatReplyNavDD
		} = this.props;
		let nav = [], options = this.props.options, show = this.props.show;
		let selectedNav, navBottom = [], navUpper = [];
		let showChatToggleNav = show;
		if (chat) {
			// on new chat v5 UI,do not show collab in reply Nav
			options = options.filter(obj => !isGroupChatCollaborate(obj.key));
			show = true;
		}
		each(options, v => {
			const k = v.key
			const busy = !!v.busy
			const name = v.name
			let disabled = !!v.disabled
			v = replyOptions[k];
			if (name) {
				v = update(v, { name: { $set: name} })
			}
			if (state === ST_ANSWERED) {
				disabled = disabledAnsweredOptions[k];
			} else if (chat) {
				if (chat.dead) {
					disabled = deadChatReplyOptions[k];
				}
			}
			if (k === currentReply) {
				selectedNav = v.name;
				navBottom.push(
					<OneReplyNav
					key={k}
					me={k}
					myClass={classNames("jq-radio", {checked: k === currentReply})}
					name={v.name}
					hotkey={v.hotkey}
					id={"QA_tab_"+v.id}
					onSelectReply={onSelectReply}
					onDefaultReply={onDefaultReply}
					disabled={disabled}
					busy={busy}
				/>
				)
			} else {
				navUpper.push(
					<OneReplyNav
					key={k}
					me={k}
					myClass={classNames("jq-radio", {checked: k === currentReply})}
					name={v.name}
					hotkey={v.hotkey}
					id={"QA_tab_"+v.id}
					onSelectReply={onSelectReply}
					onDefaultReply={onDefaultReply}
					disabled={disabled}
					busy={busy}
				/>
				)
			};
			nav.push(
				<OneReplyNav
					key={k}
					me={k}
					myClass={classNames("jq-radio", {checked: k === currentReply})}
					name={v.name}
					hotkey={v.hotkey}
					id={"QA_tab_"+v.id}
					onSelectReply={onSelectReply}
					onDefaultReply={onDefaultReply}
					disabled={disabled}
					busy={busy}
				/>
			);
		});
		let isNewChat = chat;
		let newReplyNavInfo = null;
		// TODO: this component underlaying is li, making li standalone with ul
		// seem bad html coding to me.
		newReplyNavInfo = <ReplyNavInfo
							key="info"
							className={classNames("nav-info", {chat: !!chat})}
							type={currentReply}
							info={info}
							onOpenAcquiredErrand={onOpenAcquiredErrand}
							onClick={onClickInfo}
							showReplyPanel={showReplyPanel}
							id={"QA_query_info"}
						/>
		// TODO: maybe should optimize this as chatReplyNav will always be populate
		// with value even though it is non-chat errand.
		let chatReplyNav = <div className="block-reply-nav v5 chat">
							<div className="current-nav" hidden={showChatReplyNavDD}>
								<span className="label selected">{selectedNav}</span>
								<div className="dropdown-trigger">
									<i className="icon-chevron-up" onClick={() => onToggleChatNavDD(true)}></i>
								</div>
							</div>
							<ul className="row errand-box-reply-nav mx-0" hidden={!showChatReplyNavDD}>
								{navBottom}
								{navUpper}
								<div className="dropdown-trigger">
									<i className="icon-chevron-down" onClick={() => onToggleChatNavDD(false)}></i>
								</div>
							</ul>
						</div>

		let flexyWidth = {
			width: "100%"
		}
		if (dynamicWidth && dynamicWidth > 0) {
			flexyWidth = {
				width: dynamicWidth+"px"
			}
		}
		return (
			isNewChat && !showChatToggleNav ? chatReplyNav
			:
			<Measure
				bounds
				client={true}
				onResize={contentRect => {
					this.handleResize(contentRect.bounds.height)
				}}
			>
			{({ measureRef }) => (
				<ReplyNavWrapper
					dynamicWidth={dynamicWidth}
					hidden={!show}
					measureRef={measureRef}
					showReplyPanel={showReplyPanel}
				>
					<ul className="row errand-box-reply-nav mx-0">{nav}</ul>
					{newReplyNavInfo}
				</ReplyNavWrapper>
			)}
			</Measure>
		)
	}
};

const useHeightWidthState = (defaultHeight, defaultWidth) => {
	const [height, setHeight] = useState(defaultHeight)
	const [width, setWidth] = useState(defaultWidth)
	const setHeightWidth = useCallback(({ bounds: { height, width } }) => {
		setHeight(height)
		setWidth(width)
	}, [setHeight, setWidth])
	return [height, width, setHeightWidth]
}

const withMeasureReplyBox = Component => props => {
	const [height, weight, onResize] = useHeightWidthState(0, 0)
	const [navTabsHeight, setNavTabsHeight] = useState(0)
	return (
		<Measure bounds client={true} onResize={onResize}>
			{({ measureRef }) => (
				<Component
					dynamicHeight={height}
					dynamicWidth={weight}
					measureRef={measureRef}
					navTabsHeight={navTabsHeight}
					onResize={setNavTabsHeight}
					{...props}
				/>
			)}
		</Measure>
	)
}

const withChatReplyWidth = Component => ({ chat, ...props }) => {
	const [width, setWidth] = useState(0)
	const isChat = !!chat
	const onReplyButtonsWidthChange = useMemo(
		() => {
			if (isChat) {
				return width => setWidth(width*-1)
			}
		},
		[isChat]
	)
	return (
		<Component
			chat={chat}
			dynamicWidth={isChat ? width : 0}
			onReplyButtonsWidthChange={onReplyButtonsWidthChange}
			{...props}
		/>
	)
}

const PositionableDiv = styled.div`
	position: relative;
	${({ chatMinimized }) => chatMinimized && showAddMoreOthersWhenMinimizedReply ? 'left: -100%;' : ''}
	span.add-more-others {
		position: absolute;
		bottom: ${({ bottom }) => bottom};
		.aquired-errands-dropdown {
			right: unset;
			top: unset;
		}
	}
`
const TopLeftReplyBox = withProps(({ chatMinimized, isChat }) => {
	let bottom = 0
	if (chatMinimized) {
		bottom = 0
	} else if (isChat) {
		bottom = cssUnitsInt(chatReplyTopPadding)
	} else {
		bottom = cssUnitsInt(errandReplyTopPadding)
	}
	bottom = (bottom + 5) + 'px'
	return { bottom }
})(PositionableDiv)

const StyledAcquireErrands = styled(AcquireErrands)`
	position: unset;
	right: unset;
	top: unset;
	z-index: unset;
`
const AddMoreOthers = composeWithDisplayName(
	'AddMoreOthers',
	withUnmountWhenHidden,
	memo,
	withWrapTag,
	withAssociatedErrands,
	withDisplayIdAndChannelId,
	withCurrentAndAddMoreOthers,
	withAcquireErrands
)(StyledAcquireErrands)

const useWrapperProps = (chatMinimized, chat) => {
	const isChat = !!chat
	return useMemo(() => ({ chatMinimized, isChat }), [chatMinimized, isChat])
}

const chatMinimizedFlexStyle = `
	align-items: flex-end;
	display: flex;
	flex-direction: row-reverse;
`
const chatMinimizedStyle = `
	${chatMinimizedFlexStyle}
	height: ${chatReplyPanelMinimizedHeight};
`
const StyledWrapperDiv = styled.div`
	${({ chatMinimized }) => chatMinimized ? chatMinimizedStyle : ''}
`
const StyledFlexReplyDiv = styled.div`
	&.show-options {
		${({ chatMinimized }) => chatMinimized ? chatMinimizedFlexStyle : ''}
		${({ chatMinimized }) => chatMinimized && showAddMoreOthersWhenMinimizedReply ? 'flex-direction: column;' : ''}
		${StyledWrapperDiv} {
			${() => showAddMoreOthersWhenMinimizedReply ? 'width: 100%;' : ''}
		}
	}
	${({ rightPanelOpen }) => rightPanelOpen ? 'width: calc(100% - 340px);' : '100%'}
`
const ReplyBoxInnerContainer = composeWithDisplayName(
	'ReplyBoxInnerContainer',
	memo,
	branch(
		({ chat, verticalView }) => verticalView && !chat,
		withMeasureReplyBox,
		withChatReplyWidth
	)
)(({
	chat,
	chatMinimized,
	currentReply,
	dynamicHeight,
	dynamicWidth,
	hideReply,
	measureRef,
	mobile,
	mobileView,
	navTabsHeight,
	onResize,
	onReplyButtonsWidthChange,
	onToggleErrandOptionMobile,
	onToggleExpand,
	replyBoxCls,
	showReplyPanel,
	state,
	style,
	verticalView,
	editSubject,
	onMakeCall,
	sipCallStatus,
	sipCallBackInfo,
	isClosed,
	isCallMinimized,
	showAcquire,
	showReplyboxErrandsMenu,
	onToggleReplyBoxErrandMenu,
	hasOtherErrands,
	hasOpenErrand,
	rightSidePanelOpen,
	historyIds,
	onJumpHistory,
	jumpID,
	sortHistoryAscending,
	tags
}) => (
	
	<StyledFlexReplyDiv
		chatMinimized={chatMinimized}
		className={classNames(
			replyBoxCls,
			"v5-minimal",
			{ "chat-minimized": chatMinimized },
			{ "vertical": verticalView && !mobileView }
		)}
		rightPanelOpen={rightSidePanelOpen}
		hidden={hideReply}
		ref={measureRef}
		style={style}
	>
		<AddMoreOthers
			hidden={(!showAddMoreOthersWhenMinimizedReply && !showReplyPanel) ||
				verticalView
			}
			tag={TopLeftReplyBox}
			wrapperProps={useWrapperProps(chatMinimized, chat)}
			onMakeCall={onMakeCall}
			sipCallStatus={sipCallStatus}
			sipCallBackInfo={sipCallBackInfo}
			isClosed={isClosed}
			isCallMinimized={isCallMinimized}
			showAcquire={showAcquire}
			hasOtherErrands={hasOtherErrands}
			hasOpenErrand={hasOpenErrand}
			showReplyboxErrandsMenu={showReplyboxErrandsMenu}
			onToggleReplyBoxErrandMenu={onToggleReplyBoxErrandMenu}
			historyIds={historyIds}
			onJumpHistory={onJumpHistory}
			jumpID={jumpID}
			sortHistoryAscending={sortHistoryAscending}
			verticalView={verticalView}
		/>
		<Wrappable
			chatMinimized={chatMinimized}
			tag={StyledWrapperDiv}
			wrap={showAddMoreOthersWhenMinimizedReply}
		>
			<button
				className="errand-box-reply-switcher"
				data-qa-id="reply-switcher"
				onClick={onToggleExpand}
			>
				<i className={getChevronIcon(!showReplyPanel)} />
			</button>
			<ReplyFormCtnr
				currentChecked={currentReply}
				dynamicHeight={dynamicHeight}
				dynamicWidth={dynamicWidth}
				mobile={mobile}
				mobileView={mobileView}
				navTabsHeight={navTabsHeight}
				onReplyButtonsWidthChange={onReplyButtonsWidthChange}
				onToggleErrandOptionMobile={onToggleErrandOptionMobile}
				show={showReplyPanel}
				editSubject={editSubject}
				tags={tags}
			/>
			<ReplyNavCtnr
				dynamicWidth={dynamicWidth}
				mobileView={mobileView}
				onResize={onResize}
				show={chat ? showReplyPanel : true}
				// showReplyPanel={verticalView && !chat ? showReplyPanel : undefined} // TODO: why showReplyPanel not pass?
				showReplyPanel={showReplyPanel}
				state={state}
			/>
		</Wrappable>
	</StyledFlexReplyDiv>
))

export const ErrandBoxReply = ({
	currentReply
	, hideReply
	, onToggleExpand
	, expandReply
	, state
	, chat
	, mobileView
	, onToggleErrandOptionMobile
	, mobile
	, verticalView
	, editSubject
	, onMakeCall
	, sipCallStatus
	, isClosed
	, isCallMinimized
	, showAcquire
	, hasOtherErrands
	, hasOpenErrand
	, showReplyboxErrandsMenu
	, onToggleReplyBoxErrandMenu
	, rightSidePanelOpen
	, historyIds
	, onJumpHistory
	, jumpID
	, sortHistoryAscending
	, tags
}) => {
	let style
	, chatNotice
	, showReplyPanel = expandReply
	, newChatReplyStyle =  {
		position: "absolute",
		bottom: "0",
		right: "0",
		left: "0",
		width: rightSidePanelOpen ? "calc(100% - 340px)" : "calc(100% - 5px)",
		margin: "0"
	}
	, sipCallBackInfo = {enable: false, number: ""}
	;
	if (chat) {
		style = newChatReplyStyle;
		if (chat.dead) {
			if (chat.notice) {
				chatNotice = chat.notice;
			} else if (chat.Role == CHAT_crInvited) {
				chatNotice = I("This chat has ended. You were invited.")
			} else {
				chatNotice = I("This chat has ended.")
			}
		} else if (chat.Role == CHAT_crInvited) {
			chatNotice = I("You are invited to join this chat.")
		}
		if(chat.enableCallbackRequest) {
			sipCallBackInfo.enable = true;
			sipCallBackInfo.number = chat.callbackNumber;
		}
	}
	let replyBoxCls = "errand-box-reply"
	let toggleTxt = ""
	if(chat){
		replyBoxCls = "errand-box-reply show-options";
		toggleTxt = I("SHOW OPTIONS");
		if(expandReply) {
			replyBoxCls += " open";
			style = newChatReplyStyle;
			toggleTxt = I("HIDE OPTIONS");
		}
	}else {
		if (state === ST_ANSWERED) {
			showReplyPanel = false;
		}
	}
	const chatMinimized = chat && !showReplyPanel
	return (
		//new replyBox container
		<ReplyBoxInnerContainer
			replyBoxCls={replyBoxCls}
			chat={chat}
			chatMinimized={chatMinimized}
			currentReply={currentReply}
			hideReply={hideReply}
			mobile={mobile}
			mobileView={mobileView}
			onToggleExpand={onToggleExpand}
			onToggleErrandOptionMobile={onToggleErrandOptionMobile}
			showReplyPanel={showReplyPanel}
			state={state}
			style={style}
			toggleTxt={toggleTxt}
			verticalView={verticalView}
			rightSidePanelOpen={rightSidePanelOpen}
			editSubject={editSubject}
			onMakeCall={onMakeCall}
			sipCallStatus={sipCallStatus}
			sipCallBackInfo={sipCallBackInfo}
			isClosed={isClosed}
			isCallMinimized={isCallMinimized}
			showAcquire={showAcquire}
			hasOtherErrands={hasOtherErrands}
			hasOpenErrand={hasOpenErrand}
			showReplyboxErrandsMenu={showReplyboxErrandsMenu}
			onToggleReplyBoxErrandMenu={onToggleReplyBoxErrandMenu}
			historyIds={historyIds}
			onJumpHistory={onJumpHistory}
			jumpID={jumpID}
			sortHistoryAscending={sortHistoryAscending}
			tags={tags}
		/>
	);
};
export const Popup = ({ showPopup, message, onClose }) => {
	if (!showPopup) return null;
  
	return (
	  <>
		<div
		  style={{
			position: "fixed",
			top: 0,
			left: 0,
			width: "100%",
			height: "100%",
			backgroundColor: "rgba(0, 0, 0, 0.5)",
			zIndex: 999,
		  }}
		></div>
  

		<div
		  style={{
			position: "absolute",
			top: "40%",
			left: "50%",
			transform: "translate(-50%, -50%)",
			backgroundColor: "#fff",
			padding: "50px",
			border: "1px solid #ccc",
			borderRadius: "8px",
			boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)",
			zIndex: 1000,
		  }}
		>
		  <div
			style={{
			  textAlign: "center",
			  fontFamily: "Arial, sans-serif",
			  fontSize: "16px",
			  color: "#333",
			  lineHeight: "1.5",
			  fontWeight: "bold",
			}}
		  >
			<p>{message}</p>
			<button
			  style={{
				width: "100px", 
				height: "30px",
				marginTop: "25px",
				backgroundColor: "#007bff",
				color: "#fff",
				border: "none",
				borderRadius: "4px",
				cursor: "pointer",
				textAlign: "center",
				fontSize: "13px",
			  }}
			  onClick={onClose}
			>
			  OK
			</button>
		  </div>
		</div>
	  </>
	);
  };
  
export const ErrandBox = ({ children, className, hidden, cQ, cN, ...props }) => {
	const dispatch = useDispatch();
	const [showPopup, setShowPopup] = useState(false);
	const [message, setMessage] = useState("You can no longer reply to this errand because an agent moved it back to All errands.");
	const handlePopupClose = () => {
		setShowPopup(false);
		if (children && Array.isArray(children) && children[1]?.props?.onReturnToInbox) {
		  children[1].props.onReturnToInbox(children[1].props.eid);
		}
	  };
	const eid = children?.[1]?.props?.eid;
	const eidString = String(eid);
	

	useEffect(() => {
		if (
		Array.isArray(cQ) &&
		cQ.length === 2 &&
		cQ[0].id === eidString &&
		cQ[0].qType == "agent" &&
		cQ[0].direction == "out" &&
		cQ[1].id === eidString &&
		cQ[1].qType == "area" &&
		cQ[1].direction == "in"
		) {
			dispatch(addNewPopupNotification({
				text: "MsgMovedByAnotherAgent"
				, errand: cQ[0].id
				, agent: "an agent"
			}));
		setTimeout(() => {
			setShowPopup(true);
		}, 1000);
		
		}else if(
		Array.isArray(cQ) &&
		cQ.length === 2 &&
		cQ[0].id != eid &&
		cQ[0].qType == "agent" &&
		cQ[0].direction == "out" &&
		cQ[1].id != eid &&
		cQ[1].qType == "area" &&
		cQ[1].direction == "in"
		){
			dispatch(addNewPopupNotification({
				text: "MsgMovedByAnotherAgent"
				, errand: cQ[0].id
				, agent: "an agent"
			}));
			getNotificationIcon("MsgMovedByAnotherAgent")

			
		} else {
		setShowPopup(false);
		}
	}, [cQ]);


  return (
    <div
      {...props}
      className={classNames("errand-box", className)}
      hidden={hidden}
    >
 	  <Popup showPopup={showPopup} message={message} onClose={handlePopupClose} />
      {children}
    </div>
  );
};

class ErrandBoxOptionMobile extends React.Component {
	close() {
		this.props.onClose();
	}
	render() {
		return (
			<div className="errand-box-options-mobile" style={{display: (this.props.visible ? "block" : "none")}} >
					<div className="app-header">
						<button className="options-done" onClick={() => {this.close()}}>
							<i className="icon-chevron-left"></i>
						</button>
						<h3>Options</h3>
						<button className="options-done" onClick={() => {this.close()}}>
							DONE
						</button>
					</div>
					<div className="options">
						{this.props.children}
					</div>
				</div>
		);
	}
}

export class VisibleContentBorder extends React.PureComponent {
	constructor(props) {
		super(props);
	}
	render() {
		let innerChild = null
			, outerChild = null
			, children = this.props.children
			, hideReply = this.props.hideReply
			, chat = this.props.chat
			;
		if (!hideReply) {
			innerChild = children;
		} else {
			outerChild = children;
		}
		const defaultClassName = "app-inner-content errand-page";
		const launchpadClassName = "errand-launchpad";
		return (
			<WorkflowInnerContainerCtnr className={classNames(defaultClassName, launchpadClassName)} hidden={this.props.hidden}>
				{outerChild}
				<ErrandBox hidden={hideReply} cQ={this.props.cQ} cN={this.props.currentNote} >{innerChild}</ErrandBox>
			</WorkflowInnerContainerCtnr>
		);
	}
}

// NOTE: dummy component to trigger errand page opened. Do not include chat atm.
export class ErrandOpened extends React.PureComponent {
	componentDidMount() {
		const qType = getExtQueueType();
		if (qType.length == 0 || qType != PUZZEL) {
			if (this.props.needAutoSave) {
				this.props.onErrandOpened();
				$(window).on(
					"beforeunload.autoSave"
					, e => this.props.onWindowBeforeUnload(
						this.props.needFullSave
						, e
					)
				);
			}
		}
	}
	componentWillUnmount() {
		this.props.onErrandWillClose(this.props.currentWorkingErrands);
		$(window).off("beforeunload.autoSave");
	}
	render() {
		return null;
	}
}

const ErrandClosedBase = ({ currentContext, data }) => {
	let closedDate = "", text = I("Errand Closed ");
	if (data.my.errand) {
		const { pendingReview } = data.my.errand;
		closedDate = data.my.errand.answeredDate;
		if (pendingReview && currentContext !== CTX_REVIEW) {
			text = TXT_ERRAND_PENDING_REVIEW;
		} else if (!pendingReview && currentContext === CTX_REVIEW) {
			text = TXT_ERRAND_NOT_REVIEW + " " + text;
		}
	}
	return (
		<div className="errand-box-closed-msg-container">
			<div className="errand-closed-msg" title={I("Click to see errand")}>
				<div className="errand-closed-text">
					<span>{text}</span> {closedDate}
				</div>
			</div>
		</div>
	);
};

const ErrandClosed = withUnmountWhenHidden(ErrandClosedBase);

function withMobile(Component) {
	return class extends React.PureComponent {
		constructor(props){
			super(props);
			this.handleShowReplyOptions = this.handleShowReplyOptions.bind(this);
			this.handleMobileClose = this.handleMobileClose.bind(this);
		}
		handleShowReplyOptions(){
			this.props.onToggleErrandOptionMobile(true);
		}
		handleMobileClose() {
			this.props.onToggleErrandOptionMobile(false);
		}
		renderMobileReply() {
			// TODO: Convert to proper component similar to ReplyForm but very
			// minimal
			const p = this.props
				, { currentReply, showReply } = p
				;
			return (
				<div className="errand-box-reply-mobile">
					<div className="errand-box-reply-forms">
						<div className={currentReply == "reply" ? "reply-form opened" : "reply-form"} id="reply">
							<div className="conversation-type">
								<i className="icon-facebook"></i>
							</div>
							<div className="form">
								<textarea className="reply-area" placeholder={I("Write a reply...")}></textarea>
							</div>
							<div className="right-button">
								<button className="reply-send">
									<i className="icon-arrow-up"></i>
								</button>
								<button className="reply-options" onClick={() => {this.handleShowReplyOptions()}}>
									<i className="icon-spread"></i>
								</button>
							</div>
						</div>
						<div className={currentReply == "comment" ? "reply-form opened" : "reply-form"} id="internal-comment">
							<div className="form">
								<textarea className="reply-area" placeholder={I("Write an internal comment...")}></textarea>
							</div>
							<div className="right-button">
								<button className="reply-send">
									<i className="icon-arrow-up"></i>
								</button>
								<button className="reply-options" onClick={() => {this.handleShowReplyOptions()}}>
									<i className="icon-spread"></i>
								</button>
							</div>
						</div>
						<div className={currentReply == "collaborate" ? "reply-form opened" : "reply-form"} id="collaborate">
							<div className="form">
								<textarea className="reply-area" placeholder={I("Collaborate message...")}></textarea>
								<div className="form-field">
									<input type="text" id="to" name="reply_to" placeholder={I("TO")} />
									<i className="icon-member"></i>
								</div>
							</div>
							<div className="right-button">
								<button className="reply-send">
									<i className="icon-arrow-up"></i>
								</button>
								<button className="reply-options" onClick={() => {this.handleShowReplyOptions()}}>
									<i className="icon-spread"></i>
								</button>
							</div>
						</div>
						<div className={currentReply == RPLY_EXT_FWD ? "reply-form opened" : "reply-form"} id="forward-to-external">
							<div className="form">
								<textarea className="reply-area" placeholder={I("Forward message...")}></textarea>
								<div className="form-field">
									<input type="text" id="to" name="reply_to" placeholder={I("TO")} />
									<i className="icon-member"></i>
								</div>
							</div>
							<div className="right-button">
								<button className="reply-send">
									<i className="icon-arrow-up"></i>
								</button>
								<button className="reply-options" onClick={() => {this.handleShowReplyOptions()}}>
									<i className="icon-spread"></i>
								</button>
							</div>
						</div>
					</div>
					<ReplyNav currentChecked={currentReply}
						onSelectReply={p.onSelectReply} />
					</div>
			);
		}
		render() {
			const { children, mobile, ...props } = this.props
				;
			let mobileReply = null
				, mobileOption = null
				;
			if (mobile) {
				if (mobile.viewErrand) {
					mobileReply = this.renderMobileReply();
				}
				mobileOption = (
					<ErrandBoxOptionMobile
						visible={mobile.showErrandOption}
						onClose={this.handleMobileClose}
					/>
				);
			}
			return (
				<Component {...props} mobile={mobile}>
					{mobileReply}
					{/* {mobileOption} - TODO- remove this once mobile option is fixed*/}
					{children}
				</Component>
			);
		}
	}
}

const SidePanelsBase = ({
	eid,
	onClickCollaborationTrigger,
	onClickInternalCommentTrigger,
	onCloseCollaborationPanel,
	onCloseInternalCommentPanel,
	showCollaborationPanel,
	showCollaborationTrigger,
	showInternalCommentPanel,
	showInternalCommentTrigger,
	onExpandCollaboration,
	showCRMIntegrationPanel,
	showCRMIntegrationTrigger,
	onCloseCRMIntegrationPanel,
	onClickCRMIntegrationTrigger,
	onExpandCRM,
	crmDataSource,
	showAIAnswerPanel,
	onExpandAIAnswer,
	onMaximizePanel,
	showKBLibraryPanel,
	onCloseKBLibraryPanel,
	onCloseAIAnswerPanel,
	onExpandKBLibrary,
	expandKnowledgeBasePanel,
	showBackToButton,
	onBackToMainKBList,
	kbList,
	pinnedShowAIAnswerPanel,
	onClickAgentAssistTrigger,
	onClickPinAgentAssist
}) => (
	<Fragment>
		<div className="sidepanel-trigger-container">
			<InternalCommentTriggerButton
				hidden={!showInternalCommentTrigger}
				onClick={onClickInternalCommentTrigger}
			/>
			<CollaborationTriggerButton
				hidden={!showCollaborationTrigger}
				onClick={onClickCollaborationTrigger}
			/>
			<CRMIntegrationTriggerButton
				hidden={!showCRMIntegrationTrigger}
				onClick={onClickCRMIntegrationTrigger}
			/>
			{
				features["openai-rewrite-answer"] &&
				<AgentAssistTriggerButton
					isShow={showAIAnswerPanel}
					pinned={pinnedShowAIAnswerPanel}
					onClick={onClickAgentAssistTrigger}
					onPinned={onClickPinAgentAssist}
				/>
			}
		</div>
		<RightSidepanel
			className="internal-message"
			onClosePanel={onCloseInternalCommentPanel}
			headingTitle={I('Internal Comment')}
			show={showInternalCommentPanel}
		>
			<InternalCommentContentsCtnr eid={eid} />
		</RightSidepanel>
		<CollaborationRightSidepanel
			onClosePanel={onCloseCollaborationPanel}
			show={showCollaborationPanel}
			onExpandCollaboration={onExpandCollaboration}
		>
			<CollabContentsCtnr eid={eid} />
		</CollaborationRightSidepanel>
		<CRMIntegrationRightSidepanel
			onClosePanel={onCloseCRMIntegrationPanel}
			show={showCRMIntegrationPanel}
			onExpandCRM={onExpandCRM}
		>
			<CRMContentsCtnr eid={eid} crmDataSource={crmDataSource}/>
		</CRMIntegrationRightSidepanel>
		<AIAnswerRightSidepanel
			show={showAIAnswerPanel}
			onExpandAIAnswer={onExpandAIAnswer}
			onMaximizePanel={onMaximizePanel}
			onClosePanel={onCloseAIAnswerPanel}
			>
				<RewriteAnswerBoxContentCtnr eid={eid} />
		</AIAnswerRightSidepanel>
		<KBLibraryRightSidepanel
			show={showKBLibraryPanel}
			onClosePanel={onCloseKBLibraryPanel}
			onExpandKBLibrary={onExpandKBLibrary}
			showBackToButton={showBackToButton}
			onBackToMainKBList={onBackToMainKBList}
			isExpanded={expandKnowledgeBasePanel}
		>
			<KnowledgeBaseCtnr forErrand={true} errandLibraryList={kbList} expandKnowledgeBasePanel={expandKnowledgeBasePanel} />
		</KBLibraryRightSidepanel>
	</Fragment>
)

const SidePanels = withUnmountWhenHidden(SidePanelsBase)

class ErrandMessages extends React.PureComponent {
	constructor(props) {
		super(props);
		this.state = {
			showBackToList: false,
		}
		this.handleMessageAction = this.handleMessageAction.bind(this);
	}
	handleMessageAction(action, id, value, isAgent) {
		if (this.props.onMessageAction) {
			this.props.onMessageAction(action, id, value, isAgent);
		}
	}
	handleToggleICSidepanel = () => {
		const p = this.props;
		p.onToggleShow('note', p.eid, !p.showIC);
	}
	handleToggleCollabSidepanel = () => {
		const p = this.props;
		p.onToggleShow('collaboration', p.eid, !p.showCollab);
	}
	handleToggleCRMSidepanel = () => {
		const p = this.props;
		p.onToggleShow('crmEmailTrigger', p.eid, !p.showCRM);
	}
	handleToggleAgentAssistSidePanel = () => {
		const p = this.props;
		p.onToggleShow('AIAnswerPanel', p.eid, !p.showAgentAssist);
	}
	handlePinAgentAssistPanel = (toggle) => {
		this.props.onPinAgentAssistPanel(toggle);
	}
	handleCloseRightPanel = () => {
		//todo this never used? should be removed
		this.props.onToggleRightsidepanel(false);
	}
	handleExpandCollaboration = () =>{
		var element = document.getElementById("expand-collaboration");
		var content = element.parentElement.parentElement;
		if (element.classList.contains('icon-chevron-left')){
			element.classList.remove('icon-chevron-left');
			element.classList.add('icon-chevron-right');
			content.classList.add(("active"));
		} else {
			element.classList.add('icon-chevron-left');
			element.classList.remove('icon-chevron-right');
			content.classList.remove(("active"));
		}
	}
	handleExpandCRM = () =>{
		var element = document.getElementById("expand-crmEmailTrigger");
		var content = element.parentElement.parentElement;
		if (element.classList.contains('icon-chevron-left')){
			element.classList.remove('icon-chevron-left');
			element.classList.add('icon-chevron-right');
			content.classList.add(("active"));
		} else {
			element.classList.add('icon-chevron-left');
			element.classList.remove('icon-chevron-right');
			content.classList.remove(("active"));
		}
	}
	handleExpandAIAnswer = () =>{
		//not used , will be removed
	}
	handleCloseAIAnswer = () =>{
		this.props.onToggleShow('AIAnswerPanel', this.props.eid, false);
		this.props.onToggleRightsidepanel(false);
	}
	handleCloseKBLibraryPanel = () => {
		this.props.onCloseKBLibraryPanel(this.props.eid);
	}
	handleBackToKBList = () => {
		if(this.props.onBackToMainKBList) {
			this.props.onBackToMainKBList(this.props.selectedKBLibrary, this.props.selectedKBCategory);
		}
	}
	render() {
		const {
			activateClosedErrandView
			, currentContext
			, children
			, data
			, onFetchHistory
			, onPopAlert
			, ...p
			} = this.props
			, eid = p.eid
			, e = p.errand
			, state = p.state.state
			;
		let isClosed = false
		let isClosedNoAnswer = false
			;
		if (data) {
			if (data.my.errand) {
				isClosed = data.my.errand.closed;
				if (currentContext === CTX_REVIEW) {
					isClosed = !data.my.errand.pendingReview;
				} else {
					isClosed = isClosed || data.my.errand.pendingReview;
				}
			}
			if (typeof data.my.history !== 'undefined' &&
				data.my.history != null) {
				let history = data.my.history;
				if(typeof history.actions !== 'undefined' &&
					history.actions != null) {
					for(let i = 0; i < history.actions.length; i++) {
						if (history.actions[i].type ==
							Workflow.Action.CLOSE_NO_ANSWER) {
							isClosed = true;
							isClosedNoAnswer = true
							break;
						}
					}
				}
			}
		}
		const showClosed = isClosed && !activateClosedErrandView;
		return (
			<div className="content-wrap">
				{(e.isChat && p.chat != null)
				? <ChatErrandCtnr />
				: <ErrandBoxMessages
					eid={eid}
					data={data}
					sumHistory={p.sumHistory}
					area={p?.area}
					filterPopup={p.filterPopup}
					extraClass={classNames({overlay: showClosed})}
					associatedErrands={p.associatedErrands}
					historyReady={p.historyReady}
					constants={p.constants}
					myErrandReady={p.myErrandReady}
					truncateErrandMessageByDefault={p.truncateErrandMessageByDefault}
					isClosed={isClosed}
					isClosedNoAnswer={isClosedNoAnswer}
					onActivateView={this.props.onActivateView}
					onActionClick={this.handleMessageAction}
					onCancelScrollToQuestion={this.props.onCancelScrollToQuestion}
					onFetchHistory={onFetchHistory}
					onPopAlert={onPopAlert}
					scrollToQuestion={this.props.scrollToQuestion}
					forward={p.currentReply === RPLY_EXT_FWD || p.currentReply === RPLY_COLLABORATE || (p.currentReply === RPLY_ERRAND)}
					forwardSelection={p.forwardSelection}
					onToggleAttachmentPanel={p.onToggleAttachmentPanel}
					onSelectForward={p.onSelectForward}
					agentCanDeleteFeature={p.agentCanDeleteFeature}
					agentCanEditQuestionFeature={p.agentCanEditQuestionFeature}
					onSetErrandMessageTruncatePref={p.onSetErrandMessageTruncatePref}
					onClickAgentAssistTrigger={p.onClickAgentAssistTrigger}
					onSend={p.onSend}
					interfaceLang={p.interfaceLang}
					canTranslate={p.canTranslate}
					onTranslation={p.onTranslation}
					displayId={data.my.errand.displayId}
					showReactionPopup={p.showReactionPopup}
					socialMediaUI={p.socialMediaUI}
					showExactDayAndTime={p.showExactDayAndTime}
					onAutoScrollCollabInternal={p.onAutoScrollCollabInternal}
					noteList={p.noteList}
					historyIds={p.historyIds}
					jumpID={p.jumpID}
					sortHistoryAscending={p.sortHistoryAscending}
					currentSuggestedAnswerUsed={p.currentSuggestedAnswerUsed}
				/>
				}
				<SidePanels
					eid={p.eid}
					area={data.my.errand.area}
					hidden={e.isChat}
					onClickCollaborationTrigger={this.handleToggleCollabSidepanel}
					onClickInternalCommentTrigger={this.handleToggleICSidepanel}
					onCloseCollaborationPanel={this.handleToggleCollabSidepanel}
					onCloseInternalCommentPanel={this.handleToggleICSidepanel}
					showCollaborationPanel={p.hasCollab && p.showCollab}
					showCollaborationTrigger={p.hasCollab && !p.showCollab}
					showInternalCommentPanel={p.noteList && p.showIC}
					showInternalCommentTrigger={p.noteList && !p.showIC}
					onExpandCollaboration={this.handleExpandCollaboration}
					showCRMIntegrationPanel={p.hasCRM && p.showCRM}
					showCRMIntegrationTrigger={p.hasCRM && !p.showCRM}
					crmDataSource={p.crmData}
					onCloseCRMIntegrationPanel={this.handleToggleCRMSidepanel}
					onClickCRMIntegrationTrigger={this.handleToggleCRMSidepanel}
					onExpandCRM={this.handleExpandCRM}
					showAIAnswerPanel={p.showAIAnswerPanel}
					onExpandAIAnswer={this.handleExpandAIAnswer}
					onMaximizePanel={() => p.onMaximizeAIPanel(p.currentQues)}
					onCloseAIAnswerPanel={this.handleCloseAIAnswer}
					showKBLibraryPanel={p.showKnowledgeBasePanel}
					onCloseKBLibraryPanel={this.handleCloseKBLibraryPanel}
					showBackToButton={p.libraryAnswerShown}
					onBackToMainKBList={this.handleBackToKBList}
					onExpandKBLibrary={p.onExpandKBLibrary}
					expandKnowledgeBasePanel={p.expandKnowledgeBasePanel}
					kbList={p.kbList}
					pinnedShowAIAnswerPanel={p.pinnedShowAIAnswerPanel}
					onClickAgentAssistTrigger={this.handleToggleAgentAssistSidePanel}
					onClickPinAgentAssist={this.handlePinAgentAssistPanel}
				/>
				<ErrandClosed
					currentContext={currentContext}
					data={data}
					hidden={!showClosed || p.showReply}
				/>
				{!e.isChat ? <ErrandOpenedCtnr /> : null}
			</div>
		);
	}
}

const BottomLeftVerticalMessage = withProps({ bottom: '5px' })(PositionableDiv)

function withChildAsSibling(Component) {
	return class extends React.PureComponent {
		constructor(props){
			super(props);
			this.onSwitchErrandPanelView = this.onSwitchErrandPanelView.bind(this);
			this.scrollDownIfChat = this.scrollDownIfChat.bind(this);
			this.checkAndExecuteChatInputScroll = this.checkAndExecuteChatInputScroll.bind(this);
		}

		componentDidMount(){
			this.checkAndExecuteChatInputScroll();
		}

		componentDidUpdate(){
			this.checkAndExecuteChatInputScroll();
		}

		checkAndExecuteChatInputScroll(){
			if(this.props && this.props.errand.isChat){
				if(this.chatInputRef){
					let elem = this.chatInputRef;
					if(elem){
						elem.scrollTop = elem.scrollHeight - elem.clientHeight;
					}
				}
			}
		}

		onSwitchErrandPanelView(){
			if(this.props.onChangeReplyView) {
				this.props.onChangeReplyView(!this.props.verticalView);
			}
		}

		scrollDownIfChat(ref) {
			this.chatInputRef = ref;
		}

		render () {

			const {
				children,
				showReply,
				onMakeCall,
				sipCallStatus,
				sipCallBackInfo,
				isClosed,
				isCallMinimized,
				showAcquire,
				hasOtherErrands,
				hasOpenErrand,
				...p
			} = this.props,
			e = p.errand

			const {verticalView} = this.props;
			let iconSwitchPane = "icon-new-layout-horizontal", layoutStyle = "v5";
			let showReplyClass = false;
			let switchTitle = I("Click to switch to horizontal view");
			if(!verticalView){
				iconSwitchPane = "icon-new-layout-vertical";
				switchTitle = I("Click to switch to vertical view");
				if(showReply) {
					showReplyClass = true;
				}
			}

			let defaultLayout = <div className="errand-box-body default-view v5" ref={this.scrollDownIfChat}>
									<Component {...p} />
								</div>
			return (
				<div className={"errand-box-wrapper " + layoutStyle}>
					{e.isChat ? defaultLayout
					:<div className={classNames("errand-box-body" ,{"horizontal": !verticalView || p.mobile },{"vertical": verticalView && !p.mobile},{"show-reply": showReplyClass})}>
						<div onClick={this.onSwitchErrandPanelView}
							className={
								classNames("toggle-switch-panel-view",
								{"rightpanelOpen": (p.rightSidePanelOpen || p.showCollab || p.showIC || p.showCRM )},
								{"vertical": verticalView},
								)}
							title={switchTitle}>
								<i style={{fontSize: "16px"}} className={iconSwitchPane}></i>
						</div>
						<SplitPane split="vertical" defaultSize="50%" maxSize={-20}>
							<div className="panel-one">
								<Component {...p} />
								<AddMoreOthers
									hidden={!verticalView}
									tag={BottomLeftVerticalMessage}
									onMakeCall={onMakeCall}
									sipCallStatus={sipCallStatus}
									sipCallBackInfo={sipCallBackInfo}
									isClosed={isClosed}
									isCallMinimized={isCallMinimized}
									showAcquire={showAcquire}
									hasOtherErrands={hasOtherErrands}
									hasOpenErrand={hasOpenErrand}
									historyIds={p.historyIds}
									onJumpHistory={p.onJumpHistory}
									jumpID={p.jumpID}
									verticalView={verticalView}
								/>
							</div>
							<div className="panel-two">{children}</div>
						</SplitPane>
					</div>}
				</div>
			);
		}
	}
}

const ErrandMessagesWithMobile = compose(
	withMobile
	, withChildAsSibling
	, withErrandMessages
)(ErrandMessages);

class ErrandContent extends React.PureComponent {
	render() {
		const p = this.props
			, e = p.errand
			, state = p.state.state
			;
		let eid;
		if (e.isChat) {
			eid = e.id
		} else {
			eid = p.eid
		}
		let isClosed = false;
		if(p.data.my.errand.closed) {
			isClosed = true;
		}
		const chooseString = I('Here is your event for the day');
		if(errandViewOnly){
			chooseString="";
		}
		if (state === ST_JUST_LOGIN || state === ST_UNSELECT) {
			let name;
			if(state === ST_JUST_LOGIN) {
				name = <h1>{I('Welcome back, {AGENT_NAME}')
					.replace('{AGENT_NAME}', p.name)}.</h1>;
			} else {
				name = <h1>{p.name}</h1>;
			}
			return (
				<ContentBorder hideReply={true} hidden={p.hidden} innerHeight={p.innerHeight}>
					<LaunchpadCntr
						name={name}
						chooseString={chooseString}
					/>
				</ContentBorder>
			);
		} else if (!e.isChat && state === ST_ACQUIRING) {
			return (
				<ContentBorder hideReply={true} hidden={p.hidden} innerHeight={p.innerHeight}>
					<div className="errand-ready-text">
						<h1 className="book">
							{I('Retrieving errand data...')}
						</h1>
						<Progress value={p.state.progress} />
					</div>
				</ContentBorder>
			);
		}
		var tags = this.props.area.normal_tags;
		return (
			<ContentBorder hidden={p.hidden} innerHeight={p.innerHeight}>
				<ErrandBoxHeaderCtnr mobile={p.mobile} eid={eid} //this the child Visible content border
					showAttachmentPanel={p.showAttachmentPanel}
					onReturnToInbox={p.onReturnToInbox}
					onToggleAttachmentPanel={p.onToggleAttachmentPanel}
					onTogglePopupHistoryElem={p.onTogglePopupHistoryElem}
				/>
				<ErrandMessagesWithMobile {...this.props} eid={eid}>
					{this.props.chat
						? <ChatInputCtnr
							currentReply={p.currentReply}
							toolbarID="chatToolbar"
							hidden={this.props.chat == null}
							sendMessage={sendMessage}
							editSubject={p.agentCanEditQuestionFeature}
						/>
						// chat not using this here but in diff location, refer
						// ChatErrand comp.
						// TODO: this commit https://github.com/cention/cention-applications/commit/0b46589e5d299315d13b537bab73bc3145b21f84#diff-bd829c25068649d15e2f008d5593a360
						// and https://github.com/cention/cention-applications/commit/fd0b503258ed2d61d9e97791e6fffadd7c6b4878#diff-bd829c25068649d15e2f008d5593a360
						// break original intention of the code to retain
						// Ckeditor instance without unmounting it - which is
						// not true anymore now. But since no one complain about
						// Ckeditor mounting error (occasionally previously) for
						// about 4 months, no change to this yet.
						: <ErrandBoxReply
							expandReply={p.showReply}
							onToggleExpand={p.onToggleReply}
							currentReply={p.currentReply}
							state={state}
							mobileView={p.mobileView}
							onToggleErrandOptionMobile={p.onToggleErrandOptionMobile}
							mobile={p.mobile}
							verticalView={p.verticalView}
							toolbarID={"chatToolbar"}
							hidden={this.props.chat == null ? true : p.hidden}
							sendMessage={sendMessage}
							editSubject={p.agentCanEditQuestionFeature}
							onMakeCall={this.props.onMakeCall}
							sipCallStatus={this.props.sipCallStatus}
							isClosed={isClosed}
							isCallMinimized={this.props.isCallMinimized}
							showAcquire={p.showAcquire}
							hasOtherErrands={p.hasOtherErrands}
							hasOpenErrand={p.hasOpenErrand}
							showReplyboxErrandsMenu={p.showReplyboxErrandsMenu}
							onToggleReplyBoxErrandMenu={p.onToggleReplyBoxErrandMenu}
							showIC={p.showIC}
							showCollab={p.showCollab}
							showCRM={p.showCRM}
							showAgentAssist={p.showAgentAssist}
							rightSidePanelOpen={p.rightSidePanelOpen}
							historyIds={p.historyIds}
							onJumpHistory={p.onJumpHistory}
							jumpID={p.jumpID}
							sortHistoryAscending={p.sortHistoryAscending}
							tags={tags}
						/>
					}
				</ErrandMessagesWithMobile>
				{
					p.showContactCard && <ContactCardModalCntr />
				}
			</ContentBorder>
		);
	}
}

const baseClassNameErrandContent = "app-content";
const baseClassNameAppWorkflowAction = "app-workflow-actions";

export class Errand extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			showAttachmentPanel: false,
			jumpID: ""
		};
		this.handleShowSelection = this.handleShowSelection.bind(this);
		this.handleToggleAttachmentPanel = this.handleToggleAttachmentPanel.bind(this);
		this.triggerMessageAction = this.triggerMessageAction.bind(this);
		this.handleSwitchToConvView = this.handleSwitchToConvView.bind(this);
		this.handleMakeCall = this.handleMakeCall.bind(this);
		this.handleMinimizeKnowledgePopup = this.handleMinimizeKnowledgePopup.bind(this);
		this.handleCloseKnowledgePopup = this.handleCloseKnowledgePopup.bind(this);
		this.handleJumpHistory = this.handleJumpHistory.bind(this);
	}
	handleShowSelection() {
		this.setState({showAttachmentPanel: false});
	}
	handleToggleAttachmentPanel(wantShow, permanentShowOrInstantHide) {
		const show = this.state.showAttachmentPanel;
		if(wantShow) {
			if(this.attachmentPanelHideTimeout) {
				clearTimeout(this.attachmentPanelHideTimeout);
				this.attachmentPanelHideTimeout = null;
			}
			if(!show) {
				this.setState({showAttachmentPanel: true});
			}
			if(!permanentShowOrInstantHide) {
				this.attachmentPanelHideTimeout = setTimeout(function() {
					this.setState({showAttachmentPanel: false});
				}.bind(this), attachmentPanelHideDelay)
			}
		} else if(show) {
			if(this.attachmentPanelHideTimeout) {
				clearTimeout(this.attachmentPanelHideTimeout);
				this.attachmentPanelHideTimeout = null;
			}
			if(permanentShowOrInstantHide) {
				this.setState({showAttachmentPanel: false});
			} else {
				this.attachmentPanelHideTimeout = setTimeout(function() {
					this.setState({showAttachmentPanel: false});
				}.bind(this), attachmentPanelHideDelay)
			}
		}
	}
	closeErrandView(){
		const qType = getExtQueueType();
		if (qType.length > 0) {
			if (qType != SOLIDUS) {
				try {
					window.close();
				} catch (e) {
					console.log("dbg: unable to close browser window: ", e);
				}
			}
		}
		window.location.href = "about:blank";
	}
	triggerMessageAction(action, id, value, isAgent){
		const p = this.props;
		switch(action){
			case 'print':
				p.handleActionPrint(true, id);
			break;
			case 'delete':
				p.deleteErrand(p.eid);
			break;
			case 'clone':
				//copying/append message into current answer box
				let strAnswer = getAppendAnswer(p.errandInputs.update_answer, value);
				p.handleCopyErrandMessage(strAnswer,  stripHTML(strAnswer));
			break;
			case 'edit':
				if(typeof isAgent !== "undefined" && isAgent == false){
					p.handleEditErrandQuestion();
				} else {
					p.handleEditErrandAnswer();
				}
			break;
		}
	}
	handleSwitchToConvView() {
		this.props.onSetErrandView(PREF_CONVERSATION_VIEW);
	}
	handleMakeCall() {
		this.props.onMakeSIPCall(this.props.data.my.errand.fromAddress);
	}
	handleMinimizeKnowledgePopup() {
		this.props.onCloseKnowledgeBasePopup(true);
	}
	handleCloseKnowledgePopup() {
		this.props.onCloseKnowledgeBasePopup(false);
	}
	handleJumpHistory(id) {
		if(id) {
			this.setState({jumpID: id});
		}
	}
	render() {
		let isExternal = (typeof externalqueue !== "undefined" && externalqueue.isExternal == true);
		const {
				allowAgentForwardToAgent
				, allowForwardErrandsToAgent
				, closeErrand
				, currentContext
				, deleteErrand
				, errand: e
				, errandAreaData
				, forwardErrandsAgents
				, forwardToAgent
				, forwardToArea
				, fullView
				, handleActionPrint
				, handleActionShowForwardTo
				, handleErrandPriority
				, handleForwardToAgent
				, handleForwardToArea
				, handleLockToMe
				, handleMoveToFolder
				, handlePintop
				, handleQueueToMe
				, handleReopen
				, handleReturnBackToInbox
				, hasPendingReviewErrand
				, isLoadingFwdErrandsAgents
				, listType
				, mobile
				, onFetchHistory
				, onOpenForwardToAgent
				, onPopAlert
				, onSelectForwardToAgent
				, printContent
				, shouldPopPrint
				, showMultipleActions
				, showPostponeErrand
				, sideBarShown
				, collapsedSideBar
				, onCollapseSideBar
				, onToggleSideBar
				, ui
				, visible
				, wfSettings
				, truncateErrandMessage
				, defaultFolderId
				, mobileView
				, acquireErrand
				, viewSingleErrandOnly
				, innerHeight
				, sipCallStatus
				, isClosed
				, isCallMinimized
				, showContactCard
				, ...p
			} = this.props
			, { data: wfData } = wfSettings
			, myClass = classNames(
				baseClassNameErrandContent
				, {mobile: mobile}
				, {"only-conversation": !sideBarShown && listType === PREF_CONVERSATION_VIEW}
				, {"fullView": fullView || viewSingleErrandOnly}
				, {"action-panel": showMultipleActions && mobile}
				, {"collapsed" : collapsedSideBar}
				, {"closed-sidebar" : !sideBarShown}
			)
			, actionsClass = classNames(
				baseClassNameAppWorkflowAction
				,{"mobile": mobile}
				,{"sidepanel": showMultipleActions && mobile}
			)
			, agentCanPutBackErrands = wfData.agentCanPutBackErrands
			;
		let hide
			, hideErrandContent = false
			, hideErrandActions = false
			, closeErrandViewFunc = p.onCloseErrandHeaderView;
			;
		if (!visible) {
			hide = true;
		} else {
			if (mobile) {
				// if (!mobile.viewErrand) {
				// 	hide = true; //comment to avoid blank page for now (device under 768px)
				// }
				if (p.totalSelected == 0) {
					hide = true;
				}
			}
		}
		if (errandViewOnly == true) {
			closeErrandViewFunc = this.closeErrandView;
		}
		if (showMultipleActions && p.eid === 0) {
			hideErrandContent = true;
		}
		if (p.totalSelected == 0) {
			hideErrandActions = true;
		}
		if(mobile) {
			if (this.props.currentOpened || p.totalSelected != 0){
				hide = false;
			}
		}
		const wfActionHeight = {
			height: window.innerHeight+"px"
		}
		return (
			<div className={myClass} hidden={hide}>
				<ErrandHeaderCtnr
					showForwardToArea={ui.showForwardToArea}
					showMoveToFolder={ui.showMoveToFolder}
					showForwardToAgent={ui.showForwardToAgent}
					currentContext={currentContext}
					hideActionButtons={hideErrandContent ? true : false}
					wfSettings={wfData}
					forwardToArea={forwardToArea}
					forwardToAgent={forwardToAgent}
					errand={e}
					state={p.currentState.state}
					listType={listType}
					mobile={mobile}
					data={p.data}
					errandId={p.eid}
					cipherKey={p.basic && p.basic.data ? p.basic.data.cipherKey : ""}
					handleActionShowForwardTo={handleActionShowForwardTo}
					handleMoveToFolder={handleMoveToFolder}
					handleForwardToArea={handleForwardToArea}
					handleForwardToAgent={handleForwardToAgent}
					handlePintopErrand={handlePintop}
					shouldPopPrint={shouldPopPrint}
					notMine={p.notMine}
					fullView={fullView}
					onCloseView={closeErrandViewFunc}
					printContent={printContent}
					handleActionPrint={handleActionPrint}
					deleteErrand={deleteErrand}
					closeErrand={closeErrand}
					errandAreaData={errandAreaData}
					update_lock={p.errandInputs.update_lock}
					update_priority={p.errandInputs.update_priority}
					update_pintop={p.errandInputs.update_pintop}
					showBackToList={!!fullView} // TODO: can be removed as same props as fullView above
					handleLockToMe={handleLockToMe}
					handleReturnBackToInbox={handleReturnBackToInbox}
					handleQueueToMe={handleQueueToMe}
					handleReopen={handleReopen}
					handleErrandPriority={handleErrandPriority}
					showPostponeErrand={showPostponeErrand}
					onToggleMobileErrandHeader={p.onToggleErrandHeaderMobile}
					onSwitchToListView={this.handleSwitchToConvView}
					onBackToListView={p.handleBackToList}
					mobileView={mobileView}
					acquireErrand={acquireErrand}
					handleAcquireFromOtherAgentErrand={p.handleAcquireFromOtherAgentErrand}
					sideBarShown={sideBarShown}
					collapsedSideBar={collapsedSideBar}
					onCollapseSideBar={onCollapseSideBar}
					onToggleSideBar={onToggleSideBar}
					handleCloseChatWithSummary={p.handleCloseChatWithSummary}
				/>
				<HideableDiv
					hidden={mobileView ? !showMultipleActions : (listType !== PREF_CONVERSATION_VIEW || !showMultipleActions)} //on mobile show on both Preferences
					className={actionsClass} style={wfActionHeight}
				>
					<WorkflowActions
						allowAgentForwardToAgent={allowAgentForwardToAgent}
						allowForwardErrandsToAgent={allowForwardErrandsToAgent}
						hidden={hideErrandActions}
						currentOpened={p.eid}
						currentContext={currentContext}
						forwardErrandsAgents={forwardErrandsAgents}
						isLoadingFwdErrandsAgents={isLoadingFwdErrandsAgents}
						onOpenForwardToAgent={onOpenForwardToAgent}
						onSelectForwardToAgent={onSelectForwardToAgent}
						totalSelected={p.totalSelected}
						selectedErrands={p.selectedErrands}
						onHandleMultipleErrandAction={p.onHandleMultipleErrandAction}
						onHandleSetPriority={p.onHandleSetPriority}
						onHandlePrintErrands={p.onHandlePrintErrands}
						shouldPopPrint={shouldPopPrint}
						printContent={printContent}
						onHandleClose={p.onHandleClose}
						onHandleDelete={p.onHandleDelete}
						userFolders={p.userFolders}
						showMoveToFolder={p.showMoveToFolder}
						onHandleMoveToFolder={p.onHandleMoveToFolder}
						forwardToAreas={p.forwardToAreas}
						showForwardAreaOptions={p.showForwardAreaOptions}
						onHandleForwardToArea={p.onHandleForwardToArea}
						onHandleSetDueDate={p.onHandleSetDueDate}
						onHandleReturnToInbox={p.onHandleReturnToInbox}
						onHandleSendingBulkErrand={p.onHandleSendingBulkErrand}
						onHandleLinkErrand={p.onHandleLinkErrand}
						agentCanPutBackErrands={agentCanPutBackErrands}
						agentCanMoveFromInboxFeature={p.agentCanMoveFromInboxFeature}
						agentCanForwardToAreaFeature={p.agentCanForwardToAreaFeature}
						agentCanForwardToAreaInOrganizationFeature={p.agentCanForwardToAreaInOrganizationFeature}
						agentCanDeleteErrandsFeature={p.agentCanDeleteErrandsFeature}
						showHighPriorityFeature={p.showHighPriorityFeature}
						hasChatErrand={p.chatErrandSelected}
						allowDueDate={this.props.allowDueDate}
						hasDueDateSelected={p.hasDueDateSelected}
						dueDateFeature={wfData.doneDateFeature}
						hasClosedErrand={p.hasClosedErrand}
						hasPendingReviewErrand={hasPendingReviewErrand}
						allowCloseErrand={wfData["close-errand-without-answering"]}
						myErrandsOnly={p.myErrandsOnly}
						defaultFolderId={defaultFolderId}
						selectedAgent={p.selectedAgent}
						areas={wfData.areas}
						handleAcquireFromOtherAgentErrand={p.handleAcquireFromOtherAgentErrand}
						agentWorking={p.agentWorking}
					/>
				</HideableDiv>
				<ErrandContent
					innerHeight={innerHeight}
					state={p.currentState}
					eid={p.eid}
					errand={e}
					chat={p.chat}
					currentContext={currentContext}
					associatedErrands={p.associatedErrands}
					hidden={hideErrandContent}
					truncateErrandMessageByDefault={truncateErrandMessage}
					name={wfData.activeUserName}
					activateClosedErrandView={ui.reply.activateClosedErrandView}
					showReply={ui.reply.show}
					currentReply={ui.reply.current}
					verticalView={wfData.verticalView}
					mobile={mobile}
					data={p.data}
					sumHistory={p.sumHistory}
					area={p?.area}
					constants={p.constants}
					forwardSelection={p.forwardSelection}
					historyReady={p.historyReady}
					myErrandReady={p.myErrandReady}
					scrollToQuestion={this.props.scrollToQuestion}
					showAttachmentPanel={this.state.showAttachmentPanel}
					onActivateView={this.props.onActivateView}
					onCancelScrollToQuestion={this.props.onCancelScrollToQuestion}
					onFetchHistory={onFetchHistory}
					onPopAlert={onPopAlert}
					onToggleAttachmentPanel={this.handleToggleAttachmentPanel}
					onSelectForward={p.onSelectForward}
					onToggleReply={p.onToggleReply}
					onSelectReply={p.onSelectReply}
					onReturnToInbox={handleReturnBackToInbox}
					onToggleErrandOptionMobile={p.onToggleErrandOptionMobile}
					onMessageAction={this.triggerMessageAction}
					agentCanDeleteFeature={wfData["agent-delete-errands.admin-edit"]}
					agentCanEditQuestionFeature={wfData["edit-question"]}
					canTranslate={wfData.translation}
					interfaceLang={wfData.interface}
					onTranslation={p.onTranslation}
					filterPopup={p.filterPopup}
					onTogglePopupHistoryElem={p.onTogglePopupHistoryElem}
					onSetErrandMessageTruncatePref={p.onSetErrandMessageTruncatePref}
					onClickAgentAssistTrigger={p.onClickAgentAssistTrigger}
					onSend={p.onSend}
					onChangeReplyView={p.onChangeReplyView}
					showReactionPopup={ui.reply.showReactionPopup}
					socialMediaUI={p.socialMediaUI}
					showExactDayAndTime={wfData["show-exact-day-and-time"]}
					onAutoScrollCollabInternal={wfData.onAutoScrollCollabInternal}
					mobileView={mobileView}
					noteList={p.noteList}
					onMakeCall={this.handleMakeCall}
					sipCallStatus={sipCallStatus}
					isClosed={isClosed}
					isCallMinimized={isCallMinimized}
					crmData={p.crmData}
					showAcquire={p.showAcquire}
					hasOtherErrands={p.hasOtherErrands}
					hasOpenErrand={p.hasOpenErrand}
					showReplyboxErrandsMenu={ui.showReplyboxErrandsMenu}
					onToggleReplyBoxErrandMenu={p.onToggleReplyBoxErrandMenu}
					showContactCard={showContactCard}
					showIC={ui.showComment}
					showCollab={ui.collaboration.show}
					showCRM={ui.showCRM}
					showAgentAssist={ui.showAIAnswerPanel}
					rightSidePanelOpen={ui.rightSidePanelOpen}
					expandKnowledgeBasePanel={ui.expandKnowledgeBasePanel}
					onMinimizeKnowledgePopup={this.handleMinimizeKnowledgePopup}
					onCloseKnowledgeBasePopup={this.handleCloseKnowledgePopup}
					historyIds={p.historyIds}
					jumpID={this.state.jumpID}
					onJumpHistory={this.handleJumpHistory}
					sortHistoryAscending={wfData.sortHistoryAscending}
					currentSuggestedAnswerUsed={ui.suggestedAnswerUsed}
				/>
			</div>
		);
	}
}
